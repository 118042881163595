<template>
  <div>
    <img class="shipment-search-icon" src="../../assets/img/search.png">
    <vue-input
      alternative
      placeholder="Search quotes"
      type="text"
      class="search-all-quote"
      v-model="searchQuote"
      @input="(event) => searchAllQuotesByClient(event)"
    ></vue-input>
  </div>
</template>

<script>

export default {
  name: "light-table",
  components: {
  },
  props: {
    searchData: {
      type: Array,
    },
  },
  data() {
    return {
      searchQuote: "", // Stores the search query
    };
  },
  methods: {
     /**
     * Searches quotes based on the client name.
     * @param {String} searchValue - The search query entered by the user.
     */
    searchAllQuotesByClient(searchValue) {
      let searchItem = this.searchData.map((filterItem) => {
        if (filterItem.clientName.toLowerCase().indexOf(searchValue.toLowerCase()) > -1) {
          return filterItem;
        }
      }).filter(function(element) {
        return element !== undefined;
      });
      
      // Emit an event with the filtered search results
      this.$emit("search-quotes", searchItem);
    },
  },
};
</script>

<style></style>
