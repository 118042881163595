<template>
  <div class="wrapper">
    <div class="body-container row">
      <left-panel :activeMenuName="activeMenuName"></left-panel>
      <div class="col-lg-10 right-panel-scroll plr-30 mt-4">

        <div class="row right-panel-header">
          <div class="col-md-4">
            <search-bar
            />
          </div>
          <div class="col-md-4">
          </div>
          <div class="col-md-4">
            <profile-header/>
          </div>
        </div>

        <page-heading-card
            title="Account information"
            subTitle=""
            imageName="user.png"
        />

        <div class="mt-5 mb-5 section-b">
          <div class="headline-30 mb-4">
            Personal Information
          </div>

          <div class="row">
            <div class="col-md-6">
              <base-input
                  name="name"
                  type="text"
                  class="mb-0 input-v1"
                  label="Name"
                  v-model="name"
                  @input="(event) => changeName(event)"
              ></base-input>

              <span v-if="invalidName" class="validate-error">
                {{ nameErrorMsg }}
              </span>
            </div>

            <div class="col-md-6">
              <base-input
                  name="email"
                  type="text"
                  class="mb-0 input-v1"
                  label="Email ID"
                  :disabled="readOnlyEmail"
                  v-model="email"
              ></base-input>
            </div>
          </div>

          <div class="mt-3 mb-3">
            <a
                class="reset-password-link"
                @click="showModal"
            >
              Click here to reset your password
            </a>
          </div>

          <collapse-wrapper title="Organizational Info" v-model="organizationalInfoCollapse">
            <div class="row mt-4">
              <div class="col-md-4">
                <base-input
                    name="email"
                    type="text"
                    class="mb-0 input-v1"
                    label="Organization/team"
                    :disabled="true"
                    v-model="organizationName"
                ></base-input>
              </div>
              <div class="col-md-4">
                <base-input
                    name="email"
                    type="text"
                    class="mb-0 input-v1"
                    label="Role"
                    :disabled="true"
                    v-model="userType"
                ></base-input>
              </div>
              <div class="col-md-4">
                <base-input
                    name="email"
                    type="text"
                    class="mb-0 input-v1"
                    label="Plan"
                    :disabled="true"
                ></base-input>
              </div>
            </div>
          </collapse-wrapper>

          <collapse-wrapper title="Authenticate Dimensioner" v-model="dimensionerInfoCollapse">
            <div class="row mt-4" style="height:200px">
              <div class="col-md-4">
                <label class="mb-0">Dimensioner</label>
                <choices-single
                    :options="dimensionerList"
                    :value="dimensionerKey"
                    v-model="dimensionerKey"
                >
                  <option value="0">Select Dimensioner</option>
                </choices-single>
              </div>
              <div class="col-md-4">
                <base-input
                    name="username"
                    type="text"
                    class="mb-0 input-v1"
                    label="Username"
                    v-model="username"
                ></base-input>
              </div>
              <div class="col-md-4">
                <base-input
                    name="Password"
                    type="text"
                    class="mb-0 input-v1"
                    label="API Password"
                    v-model="password"
                ></base-input>
              </div>
            </div>
          </collapse-wrapper>

          <div class="text-right">
            <base-button
                class="btn-theme-083C2F"
                @click="updateProfile"
            >
              Save
            </base-button>
          </div>
        </div>
      </div>

      <change-password-modal
          ref="changePasswordModal"
          :email="email"
      />

    </div>
  </div>
</template>

<script>
import LeftPanel from '@/layout/LeftPanel';
import SearchBar from '@/views/components/SearchBar';
import ProfileHeader from '@/layout/ProfileHeader';
import PageHeadingCard from '@/views/components/PageHeadingCard';
import CollapseWrapper from '@/views/components/CollapseWrapper.vue';
import ChangePasswordModal from '@/views/components/ChangePasswordModal.vue';
import {API, masterAPI} from '@/api/API';
import ChoicesSingle from '@/components/SingleSelect.vue';

export default {
  components: {
    ChoicesSingle,
    LeftPanel,
    SearchBar,
    ProfileHeader,
    PageHeadingCard,
    CollapseWrapper,
    ChangePasswordModal,
  },
  data() {
    return {
      activeMenuName: 'AccountSetting',
      name: '',
      email: '',
      readOnlyEmail: true,
      organizationName: '',
      userType: '',
      nameErrorMsg: 'Name field is required',
      invalidName: false,
      organizationalInfoCollapse: true,
      dimensionerList: [
        {
          id: 'cargoSpectre',
          text: 'cargoSpectre',
        },
        {
          id: 'freightSnap',
          text: 'freightSnap',
        },
      ],
      dimensionerKey: '',
      username: '',
      password: '',
      dimensionerInfoCollapse: true,
    };
  },
  methods: {
    /**
     * Updates the user profile with the provided name.
     */
    async updateProfile() {
      if (this.name == '') {
        this.invalidName = true;
      } else {
        let firstName = '';
        let lastName = '';
        let fullName = this.name;

        // Split the full name into first and last name if it contains a space
        if (fullName.includes(' ')) {
          let splitName = fullName.split(/(?<=^\S+)\s/);
          firstName = splitName[0];
          lastName = splitName[1];
        } else {
          firstName = fullName;
        }

        let bodyPayload = JSON.stringify({
          firstName: firstName,
          lastName: lastName,
        });

        let response = await masterAPI(
            API.API_ENDPOINT.user,
            API.API_METHOD.put,
            undefined,
            bodyPayload,
        );

        if (response.status == 200) {
          this.$toast.success('Name updated succesfully', {timeout: 1000});
          this.getUser();
          this.invalidName = false;
        } else {
          let error = response.data.message;
          this.$toast.error(error.replaceAll('"', ''), {timeout: 2100});
        }
      }

      if (this.username !== '' && this.password !== '') {
        let bodyPayload = JSON.stringify({
          key: this.dimensionerKey,
          auth: {
            username: this.username,
            password: this.password,
          },
        });

        let dimensionerResp = await masterAPI(
            API.API_ENDPOINT.dimensioner,
            API.API_METHOD.put,
            undefined,
            bodyPayload,
        );

        if (dimensionerResp.status == 200) {
          this.setDimensionerDetails(dimensionerResp.data);
        }
      }

    },
    /**
     * Fetches the user information from the API and updates the relevant data properties.
     */
    async getUser() {
      let response = await masterAPI(
          API.API_ENDPOINT.user,
          API.API_METHOD.get,
          undefined,
          undefined,
      );

      if (response.status == 200) {
        let user = response.data;
        this.email = user.email;
        this.name = user.name;
        this.organizationName = user.organizationName;
        this.userType = user.type;
        this.$store.commit(
            'setUser',
            Object.assign(this.$store.state, {user}),
        );
      }
    },
    /**
     * Fetches the dimensioner information from the API and updates the component's data properties.
    */
    async getDimensioner() {
      let dimensionerResp = await masterAPI(
          API.API_ENDPOINT.dimensioner,
          API.API_METHOD.get,
          undefined,
          undefined,
      );

      if (dimensionerResp.status == 200) {
        this.setDimensionerDetails(dimensionerResp.data);
      }
    },
    /**
     * Sets the dimensioner details if the response contains a valid key.
    */
    setDimensionerDetails(dimensioner) {
      if (dimensioner.key !== null) {
        this.dimensionerKey = dimensioner.key;
        this.username = dimensioner.auth.username;
        this.password = '*************';
      }
    },
    /**
     * Checks if the Name empty or not
     */
    changeName(newName) {
      if (newName === '') {
        this.invalidName = true;
      } else {
        this.invalidName = false;
      }
    },
    /**
     * Opens the change password modal.
     */
    showModal() {
      this.$refs.changePasswordModal.openModal();
    },
  },
  created() {
    this.getUser();
    this.getDimensioner();
  },
};
</script>

<style>
.reset-password-link {
  font-weight: 600;
  font-size: 13px;
  text-decoration-line: underline !important;
  color: #000000 !important;
  cursor: pointer;
}

.reset-password-link:hover {
  color: #000000 !important;
}
</style>
