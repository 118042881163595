import Vue from "vue";
import Vuex from 'vuex';
import { API, masterAPI } from "@/api/API";
import { userModes } from "@/helpers/utility";

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
      user: {},
      client: [],
      allTeammatesUsers:{},
    },
    mutations: {
      setUser (state, user) {
        state = user
      },
      setClient (state, client) {
        state = client
      },
      setTeammatesUsers (state, allTeammatesUsers) {
        state = allTeammatesUsers
      },
    },
    actions: {
      async getProfileData({ commit, state }) {
        let response = await masterAPI(
            API.API_ENDPOINT.user,
            API.API_METHOD.get,
            undefined,
            undefined
          );
          if(response.status == 200) {
            let user = response.data;
            commit('setUser', Object.assign(state,{user}));
            
            if (user.userMode == userModes.BROKER) {
              store.dispatch('getClientData');
            }
          }
      },
      async getClientData({ commit, state }) {
        let response = await masterAPI(
          API.API_ENDPOINT.client,
          API.API_METHOD.get,
          undefined,
          undefined
        );
        if(response.status == 200) {
          let client = response.data;
          commit('setClient', Object.assign(state,{client}));
        }
      },
      async getAllTeammatesUsers({ commit, state }) {
        let response = await masterAPI(
            API.API_ENDPOINT.allTeammatesUsers,
            API.API_METHOD.get,
            undefined,
            undefined
          );
          if(response.status == 200) {
            let allTeammatesUsers = response.data;
            commit('setTeammatesUsers', Object.assign(state,{allTeammatesUsers}));
          }
      },
    },
    getters: {
      userId: (state) => state.user.id,
      userType: (state) => state.user.userType,
      userName: (state) => state.user.name,
      userFirstName: (state) => state.user.firstName,
      userLastName: (state) => state.user.lastName,
      userEmail: (state) => state.user.email,
      userPhoneNumber: (state) => state.user.phoneNumber,
      userOrganizationName: (state) => state.user.organizationName,
      userDetail: (state) => state.user,
      client: (state) => state.client,
      allTeammatesUsers: (state) => state.allTeammatesUsers,
      userIsBroker: (state) => state.user.userMode == userModes.BROKER,
    },
});

export default store;
