<template>
  <div>
    <div>
      <div class="mb-3">
        <span class="v-data-table-title">Client Contacts</span>
      </div>
      <div>
        <div
          v-for="(contact, index) in contactData"
          :key="index"
          class="row"
        >
          <!-- Contact Name -->
          <div class="col-md-3">
            <base-input
              v-if="contact.isEdit"
              type="text"
              class="input-v1"
              name="Contact Name"
              label="Contact Name"
              rules="required"
              placeholder="Contact Name"
              v-model="contact.name"
            ></base-input>
            <span v-else>{{ contact.name }}</span>
          </div>
          <!-- Contact Email -->
          <div class="col-md-3">
            <base-input
              v-if="contact.isEdit"
              type="email"
              class="input-v1"
              name="Contact Email"
              label="Contact Email"
              rules="required"
              placeholder="Contact Email"
              v-model="contact.email"
            ></base-input>
            <span v-else>{{ contact.email }}</span>
          </div>
          <!-- Contact Phone Number -->
          <div class="col-md-2">
            <base-input
              v-if="contact.isEdit"
              type="text"
              class="input-v1"
              name="Phone Number"
              label="Phone Number"
              placeholder="(234) 567-8910"
              rules="phoneNumber"
              v-model="contact.phoneNumber"
              @input="acceptNumber(index, contact.phoneNumber)"
            ></base-input>
            <span v-else>{{ contact.phoneNumber }}</span>
          </div>
          <!-- Notes -->
          <div class="col-md-3">
            <base-input
              v-if="contact.isEdit"
              type="text"
              class="input-v1"
              name="Notes"
              label="Notes"
              rules="required"
              placeholder="Notes"
              v-model="contact.note"
            ></base-input>
            <span v-else>{{ contact.note }}</span>
          </div>
          <div class="col-md-1 ipad-pl">
            <base-button
              type="primary"
              class="btn-theme-EFEBEB action-round"
              @click="removeContact(index)"
            >
              <img src="../../assets/img/home/trash.png"/>
            </base-button>
          </div>
        </div>
      </div>
    </div>
    <base-button
      type="primary"
      class="btn-theme-24876E mb-4"
      @click="addContact"
    >
      <img src="../../assets/img/home/plus-round.png"/>
      Add addtional contact
    </base-button>
  </div>
</template>
<script>

import BaseInput from "@/components/BaseInput";
import BaseButton from "@/components/BaseButton";

export default {
  components: {
    BaseInput,
    BaseButton,
  },
  props: {
    contactData: Array,
    selected: Array,
  },
  computed: {
    isAddClientRoute() {
      // Check if the current route is "newclient"
      return this.$route.name === "newclient";
    },
    headers() {
      return [
        { text: "Contact Name", value: "name", sortable: false },
        { text: "Contact Email", value: "email", sortable: false },
        { text: "Contact Phone Number", value: "phoneNumber", sortable: false },
        { text: "Notes", value: "note", sortable: false },
        {
          text: "Action",
          value: this.isAddClientRoute ? "remove" : "edit",
          class: "custom-th-class",
          sortable: false,
        },
      ];
    },
  },
  data() {
    return {
      singleSelect: false,
    };
  },
  methods: {
    /**
     * Adds a new contact to the contactData array.
     */
    addContact() {
      // Create a new contact object with default values
      let index = this.contactData.length;
      let contactObj = {
        name: "",
        email: "",
        phoneNumber: "",
        note: "",
        remove: true,
        isEdit: true,
        id: index + 1,
        alreadySave: false,
        select: false,
      };
      // Add the contact object to the contactData array
      this.contactData.push(contactObj);
    },

    /**
     * Accepts a phone number and formats it based on a specific pattern.
     * @param {number} i - The index of the contact in the contactData array.
     * @param {string} phone - The phone number to be formatted.
     */
    acceptNumber(i, phone) {
      // Apply formatting to the phone number based on a specific pattern
      const formatNumber = phone
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.contactData[i].phoneNumber = !formatNumber[2]
        ? formatNumber[1]
        : `(${formatNumber[1]}) ${formatNumber[2]}${
            formatNumber[3] ? "-" + formatNumber[3] : ""
          }`;
    },

    /**
     * Removes a contact from the contactData array at the specified index.
     * @param {number} index - The index of the contact to be removed.
     */
    removeContact(index) {
      // Check if there is at least one contact
      if (this.contactData.length > 1) {
        this.contactData.splice(index, 1);
      } else {
        this.$toast.error("At least one contact is required");
      }
    },
    /**
     * Hides edit contact form for a specific contact.
     * @param {number} i - Index of the contact in contactData array.
     */
    hideEditContact(i) {
      this.contactData[i].isEdit = false;
      if (!this.contactData[i].alreadySave) {
        this.removeContact(i);
      }
    },

    /**
     * Shows edit contact form for a specific contact.
     * @param {number} i - Index of the contact in contactData array.
     */
    showEditContact(i) {
      this.contactData[i].isEdit = true;
    },
  },
};
</script>
