<template>
  <div class="wrapper">
    <div class="body-container row">
      <left-panel :activeMenuName="activeMenuName"></left-panel>
      <div class="col-lg-10 right-panel-scroll plr-30 mt-4">
        <div class="row right-panel-header">
          <div class="col-md-4">
            <search-bar
            />
          </div>
          <div class="col-md-4">
          </div>
          <div class="col-md-4">
            <profile-header/>
          </div>
        </div>

        <page-heading-card
          title="All Carriers"
          subTitle=""
          buttonName="Add carrier"
          imageName="carriers.png"
          addRecordScreenName="newcarriers"
        />

        <div class="mt-5 mb-4 section-b">
          <div class="headline-30 mb-4">
            All Carrier
          </div>
          <div class="row">
            <div class="col-md-4">
              <div class="autocomplete-search-div">
                <img class="autocomplete-search-icon" src="../assets/img/search.png">
                <vue-basic-autocomplete
                  trackby="name"
                  input-class="form-control autocomplete-search-input"
                  placeholder="Search carrier"
                  :clear-btn="false"
                  none-find="Carrier not found"
                  :options="tableData"
                  v-model="searchCarriers"
                  @selected="(event) => editCarriers(event.id)"
                />
              </div>
            </div>
            <div class="col-md-4">
            </div>
            <div class="col-md-3">
              <choices-single
                :options="[]"
              >
                <option value="0">All carrier</option>
              </choices-single>
            </div>
            <div
              v-if="showDelete"
              class="col-md-1 pl-0"
            >
              <base-button
                type="primary"
                class="btn-theme-EFEBEB action-delete"
                @click="confirmCarrierModal = true"
              >
                <img src="../assets/img/home/delete.png"/>
              </base-button>
            </div>
          </div>

          <custom-table
            :headers="headers"
            :tableData="tableData"
            sortByKey="name"
            @edit-item="editCarriers"
            @selected-items="selectedItems"
            :viewAllRecord="true"
            :isTeammates="false"
          />

        </div>
      </div>

      <modal
        :show.sync="confirmCarrierModal"
        modal-classes="modal-dialog-centered confrim-quote-modal"
      >
        <h5
          slot="header"
          class="modal-title confrim-quote-header"
          id="modal-title-default"
        >
          Delete
        </h5>

        <div>
          Are you sure you want to delete <b>{{selectedItemName}}</b>. This action is
          not reversible. All carriers deleted will no longer be available in
          Pallet until they are added again
        </div>

        <template slot="footer">
          <base-button
            class="btn-theme-083C2F"
            @click="deleteCarrier"
          >
            Confirm
          </base-button>
          <base-button
            class="btn-theme-EFEBEB"
            @click="confirmCarrierModal = false"
            >Cancel
          </base-button>
        </template>
      </modal>
    </div>
  </div>
</template>

<script>
import LeftPanel from "../layout/LeftPanel";
import SearchBar from "@/views/components/SearchBar";
import ProfileHeader from "@/layout/ProfileHeader";
import PageHeadingCard from "@/views/components/PageHeadingCard";
import ChoicesSingle from "@/components/SingleSelect";
import CustomTable from "./components/Tables/CustomTable.vue";
import Modal from "@/components/Modal.vue";
import { API, masterAPI } from "@/api/API";

export default {
  bodyClass: "carriers",
  components: {
    LeftPanel,
    SearchBar,
    ProfileHeader,
    PageHeadingCard,
    ChoicesSingle,
    CustomTable,
    Modal,
  },
  data: function() {
    return {
      activeMenuName: "Carriers",
      tableData: [],
      headers: [
        { text: "Select", value: "select", sortable: false },
        { text: "Carrier name", value: "name" },
        { text: "Last updated", value: "updatedAt" },
        { text: "Contract expiry", value: "contractExpiry" },
        { text: "Contact", value: "contact"},
        { text: "Actions", value: "action", sortable: false, width: "150px"},
      ],
      showDelete: false,
      confirmCarrierModal: false,
      selectedItemName: '',
      checkedRecord: [],
      searchCarriers: "",
    };
  },
  methods: {
    async getAllCarriers () {
      let response = await masterAPI(
        API.API_ENDPOINT.carrier,
        API.API_METHOD.get,
        undefined,
        undefined
      );

      if (response.status == 200) {
        this.tableData = response.data;
      }
    },
    /**
     * Redirects the user to the edit carriers page with the specified record ID.
     * @param {string} recordId - The ID of the carriers record to be edited.
     */
    editCarriers(recordId) {
      this.$router.push({
        name: 'editCarriers',
        params: { id: recordId },
      })
    },
    /**
     * Updates component state based on selected items
     * @param {Array} selectedRecords - Array of selected records
    */
    selectedItems(selectedRecords) {
      this.showDelete = selectedRecords.length > 0;

      if (this.showDelete) {
        this.selectedItemName = selectedRecords.map(({ name }) => name).join(", ");
        this.checkedRecord = selectedRecords;
      }
    },
    async deleteCarrier() {
      let selectedCarrier = this.checkedRecord;
      let apiCallIndex = 0;

      for (let i = 0; i < selectedCarrier.length; i++) {
        let response = await masterAPI(
          API.API_ENDPOINT.carrier,
          API.API_METHOD.delete,
          selectedCarrier[i].id,
          undefined
        );

        if (response.status == 200) {
          apiCallIndex = apiCallIndex + 1;
        }
      }
      if (apiCallIndex == selectedCarrier.length) {
        await this.getAllCarrier();
        this.confirmCarrierModal = false;
        this.$toast.success("Carrier delete successful", {
          timeout: 1000,
        });
        this.showDelete = false;
      }
    },
  },
  created() {
    this.getAllCarriers();
  },
};
</script>

<style></style>
