import Vue from "vue";
import Router from "vue-router";
import AppHeader from "./layout/AppHeader";
import SignInHeader from "./layout/SignInHeader";
import AppFooter from "./layout/AppFooter";
import Register from "./views/Register.vue";
import Reset from "./views/Reset.vue";
import Home from "./views/Home.vue";
import CreateNewQuote from "./views/quotes/CreateNewQuote.vue";
import Quotes from "./views/quotes/Quotes.vue";
import Carriers from "./views/Carriers.vue";
import ViewAllCarriers from "./views/ViewAllCarriers.vue";
import EditCarriers from "./views/EditCarriers.vue";
import NewCarriers from "./views/NewCarriers.vue";
import AccountSettings from "./views/AccountSettings.vue";
import Billing from "./views/Billing.vue";
import PlanUpgrade from "./views/PlanUpgrade.vue";
import QuoteResult from "./views/quotes/QuoteResult.vue";
import Client from "./views/client/Client.vue";
import Teammates from "./views/Teammates.vue";
import AddClient from "./views/client/AddClient.vue";
import EditClient from "./views/client/EditClient.vue";
import ViewAllQuotesByUser from "./views/quotes/ViewAllQuotesByUser.vue";
import store from '/src/store';
Vue.use(Router);

export const routesList = [
  {
    path: "/",
    name: "register",
    components: {
      default: Register,
    }
  },
  {
    path: "/companyName=:organizationName/organizationType=:organizationType",
    name: "userinvitelinkregister",
    components: {
      default: Register,
    }
  },
  {
    path: "/companyName=:organizationName/email=:email/organizationType=:organizationType",
    name: "userinviteemailregister",
    components: {
      default: Register,
    }
  },
  {
    path: "/invite/tariffUserEmail=:tariffUserEmail/email=:email",
    name: "customerInviteEmailRegister",
    components: {
      default: Register,
    }
  },
  {
    path: "/reset",
    name: "reset",
    components: {
      header: SignInHeader,
      default: Reset,
      footer: AppFooter
    }
  },
  {
    path: "/home",
    name: "home",
    components: {
      default: Home
    },
  },
  {
    path: "/newquote",
    name: "newquote",
    components: {
      default: CreateNewQuote
    },
  },
  {
    path: "/quote-result",
    name: "quote-result",
    components: {
      default: QuoteResult
    },
  },
  {
    path: "/quotes",
    name: "quotes",
    components: {
      default: Quotes
    },
  },
  {
    path: "/viewallquotes/user/:id",
    name: "viewallquotesbyuser",
    components: {
      default: ViewAllQuotesByUser
    },
  },
  {
    path: "/carriers",
    name: "carriers",
    components: {
      default: Carriers
    },
  },
  {
    path: "/viewallcarriers",
    name: "viewallcarriers",
    components: {
      header: AppHeader,
      default: ViewAllCarriers
    },
    props: {
      header: { navbarType: "default" }
    }
  },
  {
    path: "/editcarrier/:id",
    name: "editCarriers",
    components: {
      default: EditCarriers
    },
  },
  {
    path: "/newcarriers",
    name: "newcarriers",
    components: {
      default: NewCarriers
    },
  },
  {
    path: "/client",
    name: "client",
    components: {
      default: Client
    },
  },
  {
    path: "/newclient",
    name: "newclient",
    components: {
      default: AddClient
    },
  },
  {
    path: "/editclient/:id",
    name: "editClient",
    components: {
      default: EditClient
    },
  },
  {
    path: "/teammates",
    name: "teammates",
    components: {
      default: Teammates
    },
  },
  {
    path: "/account-setting",
    name: "accountsetting",
    components: {
      default: AccountSettings,
    },
  },
  {
    path: "/billing",
    name: "billing",
    components: {
      default: Billing,
    },
  },
  {
    path: "/plan-upgrade",
    name: "planupgrade",
    components: {
      default: PlanUpgrade,
    },
  }
];

const router = new Router({
  routes: routesList,
  linkActiveClass: "active",
  scrollBehavior: to => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  }
});

router.beforeEach((to, from, next) => {
  const session = localStorage.getItem("id_token");
  next();
  if (session != null && session != 'undefined' && session != undefined) {
    store.dispatch('getProfileData');
    store.dispatch('getAllTeammatesUsers');
    
    if (to.path === "/") {
      router.push({ path: "/home", name: "home" }).catch(() => {});
    } else {
      router.push({ path: to.path, name: to.name }).catch(() => {});
    }
  } else {
    if (to.path === "/reset" || to.path.includes('companyName') || to.path.includes('invite')) {
      router.push({ path: to.path, name: to.name }).catch(() => {});
    } else {
      router.push({ path: '/', name: "register" }).catch(() => {});
    }
  }
});

export default router;
