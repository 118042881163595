<template>
  <div class="wrapper">
    <div class="body-container row">
      <left-panel :activeMenuName="activeMenuName"></left-panel>
      <div class="col-lg-10 right-panel-scroll plr-30 mt-4">
        <div class="row right-panel-header">
          <div class="col-md-4">
            <search-bar
            />
          </div>
          <div class="col-md-4">
          </div>
          <div class="col-md-4">
            <profile-header/>
          </div>
        </div>

        <page-heading-card
          title="Payment Detail"
          subTitle=""
          imageName="billing.png"
        />

        <div class="mt-5 mb-5 section-b">
          <div class="headline-30 mb-4">
            Payment method
          </div>
          
          <collapse-wrapper title="Card details" v-model="cardDetailsCollapse">
            <div class="card-body collapsed-shipment">
              <div class="row">
                <div class="col-md-4 payment-card-img">
                  <img src="../assets/img/card-detail.png" height="180px"/>
                </div>
                <div class="col-md-8">
                  <div class="table-responsive">
                    <table class="table align-items-center">
                      <thead>
                        <tr>
                          <th scope="col">Card Type</th>
                          <th scope="col">Card Number</th>
                          <th scope="col">Payment Method</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(cardItem, index) in cardList" :key="index">
                          <th scope="row">
                            <img :src="getImgUrl(cardItem.cardIcon)" class="avatar payment-card-image" />
                          </th>
                          <td>
                            <span class="d-block">{{ cardItem.cardNumber }}</span>
                            <small class="text-muted"
                              >Exp: {{ cardItem.cardExpiry }}</small
                            >
                          </td>
                          <td class="text-center">
                            <base-switch 
                              type="success"
                              offText="" 
                              onText=""
                            >
                            </base-switch>
                          </td>
                          <td>
                            <base-button
                              type="primary"
                              class="btn-theme-EFEBEB remove-card-action"
                              @click="removeCard(index)"
                            >
                              <img src="../assets/img/home/trash.png"/>
                            </base-button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <base-button
                    type="primary"
                    class="btn-theme-24876E btn-width-100"
                    @click="showAddCardModal"
                  >
                    <img src="../assets/img/home/plus-round.png"/>
                    Add new card
                  </base-button>
                </div>
              </div>
            </div>
          </collapse-wrapper>

          <collapse-wrapper title="Email address" v-model="emailAddressCollapse">
            <div class="card-body collapsed-shipment">
              <div v-for="(item, index) in invoiceEmails" :key="index" class="row">
                <div class="col-7 col-md-9">
                  <base-input
                    name="email"
                    type="email"
                    label="Invoices will be sent to this email"
                    class="input-v1"
                    v-model="item.email"
                  ></base-input>
                </div>
                <div class="col-5 col-md-3 add-delete-btn text-right">
                  <base-button
                    type="primary"
                    class="btn-theme-EFEBEB billing-action-left"
                    @click="deleteEmail(index)"
                  >
                    <img src="../assets/img/home/trash.png"/>
                  </base-button>
                  <base-button
                    type="primary"
                    class="btn-theme-EFEBEB billing-action-right"
                    @click="addEmail"
                  >
                    <img src="../assets/img/plus.png"/>
                  </base-button>
                </div>
              </div>
            </div>
          </collapse-wrapper>

          <collapse-wrapper title="Billing address" v-model="billingAddressCollapse">
            <div class="card-body collapsed-shipment">
              <div class="row">
                <div class="col-md-6">
                  <base-input
                    name="address"
                    type="text"
                    label="Address number and street name"
                    class="input-v1"
                    v-model="address"
                  ></base-input>
                </div>
                <div class="col-md-6">
                  <base-input
                    name="city"
                    type="text"
                    v-model="city"
                    label="City name"
                    class="input-v1"
                  ></base-input>
                </div>
              </div>

              <div class="row">
                <div class="col-md-4">
                  <base-input
                    name="zipCode"
                    type="text"
                    v-model="zipCode"
                    label="POSTAL/ZIP Code"
                    class="input-v1"
                  ></base-input>
                </div>
                <div class="col-md-4 form-group has-label">
                  <label class="mb-0">Country</label>
                  <choices-single 
                    :options="countryList" 
                    v-model="selectedCountry"
                  >
                    <option value="0">Select</option>
                  </choices-single>
                </div>
                <div class="col-md-4 form-group has-label">
                  <label class="mb-0">Province/State</label>
                  <choices-single 
                    :options="stateList" 
                    v-model="selectedState"
                  >
                    <option value="0">Select</option>
                  </choices-single>
                </div>
              </div>
            </div>
          </collapse-wrapper>
        </div>

        <div class="mt-5 mb-5 section-b">
          <div class="headline-30 mb-4">
            Billing History
          </div>

          <div>
            <v-app id="inspire">
              <v-data-table
                v-model="selected"
                :headers="headers"
                :items="tableData"
                :single-expand="singleExpand"
                :expanded.sync="expanded"
                :single-select="singleSelect"
                :hide-default-footer="true"
                sort-by="id"
                select-enable
                class="elevation-1"
              >

              <template v-slot:item.status="{item}">
                <div v-if="item.status == 'Failed'">
                  <span class="badge mr-2 badge-danger failed-badge badge-pill">
                    {{item.status}}
                  </span>
                </div>
                <div v-if="item.status == 'Paid'">
                  <span class="badge mr-2 badge-success badge-pill">
                    {{item.status}}
                  </span>
                </div>
              </template>


              <template v-slot:item.amount="{item}">
                <div>
                  {{item.amount}}
                  <div>CAD</div>
                </div>
              </template>

              <template v-slot:item.actions="{}">
                <base-button
                  type="primary"
                  class="btn-theme-EFEBEB action-left"
                >
                  <img src="../assets/img/home/eye.png"/>
                </base-button>
                <base-button
                  type="primary"
                  class="btn-theme-EFEBEB action-right"
                >
                  <img src="../assets/img/home/download.png"/>
                </base-button>
              </template>
              </v-data-table>
            </v-app>
          </div>
        </div>
      </div>
      
    </div>
    <modal
      :show.sync="addCardModal"
      modal-classes="modal-dialog-centered add-card-modal"
    >
      <h5
        slot="header"
        class="modal-title"
        id="modal-title-default"
      >
        Add Card
      </h5>

      <div>
        <template>
          <VCreditCard
            :noCard="true"
            :yearDigits="4"
            :trans="translations"
            @change="creditInfoChanged"
            @cardChanged="cardChanged"
            className="custom-vue-credit-card account-vue-credit-card"
          />
          <div 
            v-if="cardError != ''" 
            class="validate-error text-center mt-4"
          >
           <b>{{cardError}}</b> 
          </div>
        </template>
      </div>

      <template slot="footer">
        <base-button
          type="secondary"
          class="btn-theme-EFEBEB"
          @click="addCardModal = false"
          >Cancel
        </base-button>
        <base-button type="primary" class="btn-theme-24876E" @click="addNewCard">
          Save
        </base-button>
      </template>
    </modal>
  </div>
</template>

<script>
import LeftPanel from "@/layout/LeftPanel";
import SearchBar from "@/views/components/SearchBar";
import ProfileHeader from "@/layout/ProfileHeader";
import PageHeadingCard from "@/views/components/PageHeadingCard";
import CollapseWrapper from "@/views/components/CollapseWrapper.vue";
import ChoicesSingle from "@/components/SingleSelect";
//import BaseDropdown from "@/components/BaseDropdown";
import Modal from "@/components/Modal.vue";
import VCreditCard from "v-credit-card";
import "v-credit-card/dist/VCreditCard.css";

export default {
  components: {
    LeftPanel,
    SearchBar,
    ProfileHeader,
    PageHeadingCard,
    CollapseWrapper,
    ChoicesSingle,
    // BaseDropdown,
    Modal,
    VCreditCard,
  },
  data() {
    return {
      activeMenuName: "Billing",
      cardDetailsCollapse: true,
      emailAddressCollapse: true,
      billingAddressCollapse: true,
      name: "",
      cardNumber: "",
      expiration: "",
      security: "",
      cardType: null,
      cardError: "",
      ///
      address: "",
      city: "",
      zipCode: "",
      stateList: [],
      selectedState: null,
      countryList: [],
      selectedCountry: null,
      invoiceEmails: [
        {
          id: "",
          email: "",
        },
      ],
      translations: {
        name: {
          label: "Name on card",
          placeholder: "Name on card",
        },
        card: {
          label: "Card Number",
          placeholder: "XXXX-XXXX-XXXX-XXXX",
        },
        expiration: {
          label: "Expiry",
          placeholder: "01/2023",
        },
        security: {
          label: "CVV",
          placeholder: "***",
        },
      },
      addCardModal: false,
      cardList: [
        {
          cardIcon: "Visa",
          cardNumber: "xxxxxxxxxx9999",
          cardExpiry: "06/2026",
          activeCard: false,
        },
      ],
      singleSelect: false,
      selected: [],
      expanded: [],
      singleExpand: false,
      headers: [
        // Table headers configuration
        { text: "Invoice", value: "invoice", sortable: false },
        { text: "Date", value: "date", sortable: false },
        { text: "Status", value: "status", sortable: false },
        { text: "Amount", value: "amount", sortable: true },
        { text: "Actions", value: "actions", sortable: true }
      ],
      tableData: [
        {
          invoice:"#0136945",
          date:"01 Mar 2024",
          status:"Paid",
          amount:"$199.00",
        },
        {
          invoice:"#0116989",
          date:"16 Apr 2023",
          status:"Failed",
          amount:"$398.00",
        },
      ]
    };
  },
  methods: {
    addEmail() {
      let emails = {
        id: "",
        email: "",
      };
      this.invoiceEmails.push(emails);
    },
    deleteEmail(index) {
      if (this.invoiceEmails.length > 1) {
        this.invoiceEmails.splice(index, 1);
      } else {
        this.$toast.error('Atleast one email require')
      }
    },
    creditInfoChanged(values) {
      for (const key in values) {
        this[key] = values[key];
      }
      this.cardError = "";
    },
    cardChanged(cardType) {
      this.cardType = cardType;
    },
    getImgUrl(iconName) {
      var images = require.context("../assets/img/card/", false, /\.svg$/);
      return images("./" + iconName + ".svg");
    },
    cardValidation() {
      let error = "";
      if (this.name == "") {
        error = "The Card Name field is required";
      } else if (this.cardNumber == "") {
        error = "The Card Number field is required";
      } else if (this.expiration == "") {
        error = "The Expiry field is required";
      } else if (this.security == "") {
        error = "The CVV field is required";
      }
      this.cardError = error;
      return error == "" ? true : false;
    },
    showAddCardModal() {
      this.name = "";
      this.cardNumber = "";
      this.expiration = "";
      this.security = "";
      this.cardType = null;
      this.addCardModal = true;
    },
    addNewCard() {
      if (this.cardValidation()) {
        let newCardNumber = this.cardNumber.replace(/.(?=.{4})/g, "•");
        let newCard = {
          cardIcon: this.cardType,
          cardNumber: newCardNumber,
          cardExpiry: this.expiration,
          activeCard: false,
        };
        this.cardList.push(newCard);
        this.addCardModal = false;
      }
    },
    removeCard(i) {
      this.cardList.splice(i, 1);
    },
  }
};
</script>

<style></style>
