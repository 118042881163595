<template>
  <modal
      :show.sync="isOpen"
      modal-classes="modal-dialog-centered change-password-modal"
    >
      <h5
        slot="header"
        class="modal-title"
        id="modal-title-default"
      >
        Change Password 
      </h5>

      <div>
        <base-input
          name="password"
          type="password"
          class="mb-0 input-v1"
          label="New Password"
          autocomplete="off"
          v-model="newPassword"
          @input="(event) => changePasswordValue(event)"
        >
        </base-input>

        <span 
          v-if="invalidNewPassword"
          class="validate-error" 
        >
          {{ newPasswordErrorMsg }}
        </span>

        <base-input
          name="password"
          type="password"
          class="mb-0 mt-2 input-v1"
          label="Confirm Password"
          v-model="confirmNewPassword"
          @input="(event) => changeConfirmPasswordValue(event)"
        >
        </base-input>
        
        <span 
          v-if="invalidConfirmPassword"
          class="validate-error" 
        >
          {{ passwordErrorMsg }}
        </span>
      </div>

      <template slot="footer">

        <base-button
          class="btn-theme-EFEBEB"
          @click="isOpen = false"
        >
          Cancel
        </base-button>

        <base-button 
          type="primary" 
          class="btn-theme-24876E" 
          @click="updatePassword"
        >
          Save
        </base-button>

      </template>
    </modal>
</template>

<script>

import Modal from "@/components/Modal.vue";
import { API, masterAPI } from "@/api/API";
import { setPassword } from '@/helpers/utility';

export default {
  components: {
    Modal,
  },
  data: function() {
    return {
      isOpen: false,
      newPassword:"",
      confirmNewPassword: "",
      invalidNewPassword: false,
      invalidConfirmPassword: false,
      newPasswordErrorMsg:"New password field is required",
      passwordErrorMsg:"Your new password and confirmation password do not match.",
    };
  },
  props: {
    email: {
      type: String,
      required: true,
    },
  },
  methods: {
    /**
     * Checks if the new password empty or not
    */
    changePasswordValue(password) {
      if (password === '') {
        this.invalidNewPassword = true;
      } else {
        this.invalidNewPassword = false;
      }
    },
    /**
     * Checks if the password confirmation matches the new password.
     * If they match, sets invalidConfirmPassword to false.
    */
    changeConfirmPasswordValue(passwordValue) {
      if (this.newPassword == passwordValue) {
        this.invalidConfirmPassword = false;
      }
    },
    /**
     * Handles the password update process.
     * Checks if the new password and confirm password match.
     * If they do, sends a request to update the password.
     * Displays a success or error message based on the response.
    */
    async updatePassword() {
      if (this.newPassword == '') {
        this.invalidNewPassword = true;
      } else if (this.newPassword != this.confirmNewPassword) {
        this.invalidConfirmPassword = true;
      } else {
        this.invalidNewPassword = false;
        this.invalidConfirmPassword = false;

        let bodyPayload = JSON.stringify({
          email: this.email,
          newPassword: this.newPassword,
        });

        let response = await masterAPI(
          API.API_ENDPOINT.resetPassword,
          API.API_METHOD.post,
          undefined,
          bodyPayload
        );

        if (response.status == 200) {
          this.isOpen = false;
          this.$toast.success("Password updated successfully",{timeout: 1000});
          setPassword(this.newPassword);
        } else {
          let error = response.data.message;
          this.$toast.error(error.replaceAll('"', ""));
        }
      }
    },
    /**
     * Resets the form fields and validation status.
    */
    resetForm() {
      this.newPassword = "";
      this.confirmNewPassword = "";
      this.invalidNewPassword = false;
      this.invalidConfirmPassword = false;
    },
    /**
     * Opens the modal and resets the form.
    */
    openModal() {
      this.resetForm();
      this.isOpen = true;
    },
  },
};
</script>

<style>
.change-password-modal .modal-footer,
.change-password-modal .modal-body {
  padding: 1rem;
}

.change-password-modal .modal-header {
  align-items: center;
}
</style>
