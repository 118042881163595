<template>
  <div class="wrapper">
    <div class="body-container row">
      <left-panel :activeMenuName="activeMenuName"></left-panel>
      <loader :isLoader="showLoader"></loader>

      <div class="col-lg-10 right-panel-scroll plr-30 mt-4">
        <div class="row right-panel-header">
          <div class="col-md-4">
            <search-bar
            />
          </div>
          <div class="col-md-4">
          </div>
          <div class="col-md-4">
            <profile-header/>
          </div>
        </div>

        <page-heading-card
          title="All Quote"
          subTitle=""
          buttonName="Create Quote"
          imageName="quote.png"
          addRecordScreenName="newquote"
        />

        <div class="mt-5 mb-4 section-b">
          <div class="row d-flex align-items-center justify-content-center">
            <div class="col-md-3 mt-5">
              <search-quote
                :searchData="userDbQuotesList"
                @search-quotes="setSearchQuotes"
              />
            </div>

            <div class="col-md-9">
              <shipment-detail-filters
                :filterData="userQuotesList"
                :showCarrierDropdown="showCarrierDropdown"
                :showBuyRateDropdown="showBuyRateDropdown"
                :showMarginDropdown="showMarginDropdown"
                :isSearchQuote="false"
              />
            </div>
          </div>

          <div class="row accepted-quotes">
            <div class="col-md-12">
              <quotes-table
                tableName="Reverse Chronological View"
                :pagination="true"
                :tableData="userQuotesList"
                @get-all-quote="getAllQuote"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LeftPanel from "@/layout/LeftPanel";
import Loader from "@/layout/Loader";
import SearchBar from "@/views/components/SearchBar";
import ProfileHeader from "@/layout/ProfileHeader";
import PageHeadingCard from "@/views/components/PageHeadingCard";
import SearchQuote from "@/views/quotes/SearchQuote.vue";
import ShipmentDetailFilters from "@/views/quotes/ShipmentDetailFilters.vue";
import QuotesTable from "@/views/quotes/QuotesTable.vue";
import moment from "moment";
import { mapGetters } from "vuex";
import { API, masterAPI } from "@/api/API";
import {
  checkEmptyValue,
  getCreatedQuoteUserName,
  getQuoteNumber,
} from "@/helpers/utility";

export default {
  bodyClass: "quotes-page",
  components: {
    LeftPanel,
    Loader,
    SearchBar,
    ProfileHeader,
    PageHeadingCard,
    SearchQuote,
    ShipmentDetailFilters,
    QuotesTable,
  },
  data: function() {
    return {
      activeMenuName: "Quotes",
      showLoader: false,
      userQuotesList: [],
      userDbQuotesList: [],
      showCarrierDropdown: true,
      showBuyRateDropdown: true,
      showMarginDropdown: true,
    };
  },
  computed: {
    // Component computed properties
    ...mapGetters(["client", "allTeammatesUsers"]),
    userId() {
      return +this.$route.params.id;
    },
  },
  methods: {
    /**
     * Fetch all quotes for the user.
     */
    async getAllQuote() {
      this.showLoader = true;
      this.userQuotesList = [];
      this.userDbQuotesList = [];

      let endPoint =
        API.API_ENDPOINT.quote +
        "/" +
        this.userId +
        "/" +
        API.API_ENDPOINT.users;

      let response = await masterAPI(
        endPoint,
        API.API_METHOD.get,
        undefined,
        undefined
      );

      this.showLoader = false;

      if (response.status == 200) {
        if (response.data) {
          let quoteList = response.data;

          quoteList.map((item) => {
            let createdAt = moment(item.createdAt).format("MMM Do, YYYY");
            let quoteDetail = item.details;
            let rates = quoteDetail.rates[0];
            let carrierName = rates.lane.carrier.name;
            let carrierRate = rates.totalCostBeforeMargin;
            let margin = rates.marginCost.value;

            let clientName = this.client
              .map((items) => {
                if (items.id == item.clientId) {
                  return items;
                }
              })
              .filter(function(element) {
                return element !== undefined;
              });

            let quoteItem = {
              select: false,
              quotenumber: getQuoteNumber(item.id),
              clientName: clientName.length != 0 ? clientName[0].name : "",
              origin: rates.lane.origin,
              destination: rates.lane.destination,
              createdBy: getCreatedQuoteUserName(
                this.allTeammatesUsers,
                item.userId
              ),
              createdAt: createdAt,
              shipmendetails: "Volumetric Weight: " + quoteDetail.totalVolumetricWeight,
              status: item.quoteStatus,
              createTemplate: "Create",
              edit: item.id,
              details: quoteDetail,
              createTemplateBtn: true,
              clientId: item.clientId,
              buyRate: rates.totalCostBeforeMargin,
              margin: quoteDetail.margin ? quoteDetail.margin.value : '',
              carrierName: rates.lane.carrier.name,
              rates: rates,
              total: checkEmptyValue(rates.totalCost),
              baseRate: rates.baseRate,
              quoteItem: item,
              currencyType:
                clientName.length != 0 ? clientName[0].currencyType : "",
            };
            // Push data to user quotes list
            this.userQuotesList.push(quoteItem);
            this.userDbQuotesList.push(quoteItem);

            // Update dropdown visibility flags based on quote information
            if (!carrierName) {
              this.showCarrierDropdown = false;
            }

            if (!carrierRate) {
              this.showBuyRateDropdown = false;
            }

            if (rates.marginCost && !margin) {
              if (margin != 0) {
                this.showMarginDropdown = false;
              }
            }
          });
        }
      }
    },
    /**
     * Update the user quotes list with the provided search item.
     * @param {Array} item - Quotes data array
     */
    setSearchQuotes(item) {
      this.userQuotesList = item;
    },
  },
  created() {
    this.getAllQuote();
  },
};
</script>
<style scope></style>
