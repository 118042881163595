<template>
  <div>
    <div class="row v-table-row expanded-row">
      <div class="col-md-3">
        <p class="accessorials-title">
          Quote Number
        </p>
        <div class="best-quotes-item">
          <a :href="`${item.rates.quoteLink}`" target="_blank">{{
              item.rates.quoteNumber
            }}</a>
        </div>
      </div>
      <div class="col-md-3">
        <p class="accessorials-title">Line Item</p>
        <div class="best-quotes-item">
          Chargeable Rate
        </div>
      </div>
      <div class="col-md-2">
        <p class="accessorials-title">Weight</p>
        <div class="best-quotes-item">
          {{
            item.rates.deficitChargeableWeight ||
            item.rates.totalChargeableWeight
          }}
          <span v-if="item.rates.deficitChargeableWeight && (item.rates.deficitChargeableWeight !== item.rates.totalChargeableWeight)" >
            (deficit)
          </span>
        </div>
      </div>
      <div class="col-md-2">
        <p class="accessorials-title">Base Rate</p>
        <div class="best-quotes-item">
          {{ displayCurrencyValue(item.baseRate, item.currencyType) }}
        </div>
      </div>
      <div class="col-md-2">
        <p class="accessorials-title">Rate</p>
        <div class="best-quotes-item">
          {{ displayCurrencyValue(item.rates.baseCost, item.currencyType) }}
        </div>
      </div>
    </div>
    <div
      v-if="item.rates.deficitChargeableWeight && (item.rates.deficitChargeableWeight !== item.rates.totalChargeableWeight)"
      class="row v-table-row"
    >
      <div class="col-md-3">
      </div>
      <div class="col-md-3">
        <div class="best-quotes-item">
          Actual Chargeable Weight
        </div>
      </div>
      <div class="col-md-2">
        <div class="best-quotes-item">
          {{ item.rates.totalChargeableWeight }}
        </div>
      </div>
    </div>

    <!-- Accessorials -->
    <div class="expanded-row">
      <div class="row v-table-row mb-2">
        <div class="col-md-3">
          <p class="accessorials-title">
            Accessorials:
          </p>
        </div>
        <div class="col-md-3">
          <div
            v-for="(accessorial, index) in item.rates.accessorialsCost
              .accessorials"
            :key="index"
          >
            <div class="best-quotes-item">
              {{ accessorial.name }}
            </div>
          </div>
        </div>
        <div class="col-md-2"></div>
        <div class="col-md-2"></div>
        <div class="col-md-2">
          <div
            v-for="(accessorial, index) in item.rates.accessorialsCost
              .accessorials"
            :key="index"
          >
            <div class="best-quotes-item">
              {{ displayCurrencyValue(accessorial.cost, item.currencyType) }}
            </div>
          </div>
        </div>
      </div>
      <!-- Fuel surchage -->
      <div
        class="row v-table-row mb-2"
        v-for="(result, index) in item.rates.additionalsCost.additionals"
        :key="index"
      >
        <div class="col-md-3"></div>
        <div class="col-md-3">
          {{ result.name }}
        </div>
        <div class="col-md-2"></div>
        <div class="col-md-2"></div>
        <div class="col-md-2">
          {{ displayCurrencyValue(result.cost, item.currencyType) }}
        </div>
      </div>
    </div>

    <!-- Subtotal -->
    <div v-if="userIsBroker" class="row v-table-row">
      <div class="col-md-3"></div>
      <div class="col-md-3">
        <p class="accessorials-title">Subtotal</p>
      </div>
      <div class="col-md-2"></div>
      <div class="col-md-2"></div>
      <div class="col-md-2">
        <p class="accessorials-title">
          {{
            displayCurrencyValue(
              item.rates.totalCostBeforeMargin,
              item.currencyType
            )
          }}
        </p>
      </div>
    </div>
    <!-- Broker margin -->
    <div v-if="userIsBroker" class="row v-table-row mb-3">
      <div class="col-md-3"></div>
      <div class="col-md-3">
        Margin
      </div>
      <div class="col-md-2"></div>
      <div class="col-md-2"></div>
      <div class="col-md-2">
        {{
          displayCurrencyValue(item.rates.marginCost.cost, item.currencyType)
        }}
      </div>
    </div>
    <!-- Taxes -->
    <div v-if="item.rates.taxesCost">
      <div
        class="row v-table-row mb-2"
        v-for="(result, index) in item.rates.taxesCost.taxes"
        :key="index"
      >
        <div class="col-md-3"></div>
        <div class="col-md-3">
          {{ result.name }}
        </div>
        <div class="col-md-2"></div>
        <div class="col-md-2"></div>
        <div class="col-md-2">
          {{ displayCurrencyValue(result.cost, item.currencyType) }}
        </div>
      </div>
    </div>
    <div class="row v-table-row">
      <div class="col-md-3"></div>
      <div class="col-md-3">
        <p class="accessorials-title">Total</p>
      </div>
      <div class="col-md-2"></div>
      <div class="col-md-2"></div>
      <div class="col-md-2">
        <p class="accessorials-title">
          {{ displayCurrencyValue(item.total, item.currencyType) }}
        </p>
      </div>
    </div>
<!--    <div class="row v-table-row">-->
<!--      <div class="col-md-3"></div>-->
<!--      <div class="col-md-3">-->
<!--        <p class="accessorials-title">Excel Cost</p>-->
<!--      </div>-->
<!--      <div class="col-md-2"></div>-->
<!--      <div class="col-md-2"></div>-->
<!--      <div class="col-md-2">-->
<!--        <p class="accessorials-title">-->
<!--          {{ displayCurrencyValue(item.rates.excelCost, item.currencyType) }}-->
<!--        </p>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { clearCurrencyValue } from "@/helpers/utility";

export default {
  name: "light-table",
  components: {},
  props: {
    item: {
      type: Object
    }
  },
  data () {
    return {};
  },
  computed: {
    ...mapGetters(["userIsBroker"]),
  },
  methods: {
    /**
     * Displays the currency value based on the specified currency type.
     * @param {number} currencyValue - The value of the currency.
     * @param {string} currencyType - The type of the currency (e.g., USD, CDN).
     * @returns {number} - The cleared currency value.
     */
    displayCurrencyValue (currencyValue, currencyType) {
      return clearCurrencyValue(currencyValue, currencyType);
    }
  }
};
</script>

<style></style>
