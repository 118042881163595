<template>
  <div class="profile-header">
    <base-button class="btn-theme-083C2F" @click="createNewQuote">
      <img src="../assets/img/home/plus.png" />
      Create Quote
    </base-button>
    <!--    <img src="../assets/img/home/notification.png" />-->
    <!--    <a-->
    <!--      href="javascript:;"-->
    <!--      class="avatar avatar-lg rounded-circle"-->
    <!--      data-toggle="tooltip"-->
    <!--      data-original-title="Michael Lewis"-->
    <!--    >-->
    <!--      <img-->
    <!--        alt="Image placeholder"-->
    <!--        src="../assets/img/home/avatar.png"-->
    <!--        class="rounded-circle"-->
    <!--      />-->
    <!--    </a>-->
  </div>
</template>
<script>


export default {
  components: {},
  props: {},
  // computed: {
  //   ...mapGetters([
  //     'userName',
  //     'userOrganizationName',
  //   ])
  // },
  methods: {
    createNewQuote () {
      this.$router.push({ path: "/", name: "newquote" }).catch(() => {});
    }
  }
};
</script>
<style></style>
