<template>
  <div
    tabindex="0"
    class="vl-overlay vl-active vl-full-page"
    aria-busy="false"
    aria-label="Loading"
    v-if="isLoader"
  >
    <div class="vl-background" style="background: rgb(255, 255, 255);"></div>
    <div class="vl-icon">
      <svg
        viewBox="0 0 38 38"
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        stroke="#004225"
      >
        <g fill="none" fill-rule="evenodd">
          <g transform="translate(1 1)" stroke-width="2">
            <circle stroke-opacity=".25" cx="18" cy="18" r="18"></circle>
            <path d="M36 18c0-9.94-8.06-18-18-18">
              <animateTransform
                attributeName="transform"
                type="rotate"
                from="0 18 18"
                to="360 18 18"
                dur="0.8s"
                repeatCount="indefinite"
              ></animateTransform>
            </path>
          </g>
        </g>
      </svg>
    </div>
  </div>
</template>
<script>
export default {
  name: "loader",
  props: {
    isLoader: {
      type: Boolean,
    },
  },
};
</script>
<style></style>
