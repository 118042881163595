<template>
  <div class="row shipment-filter mt-4">
    <div v-if="isSearchQuote" class="col-md-3 mt-4 pl-0 pr-0">
      <div>
        <img class="shipment-search-icon" src="../../assets/img/search.png">
        <vue-input
          alternative
          placeholder="Search quotes"
          type="text"
          class="search-all-quote"
          v-model="searchQuote"
          @input="(event) => searchAllQuotes(event)"
        ></vue-input>
      </div>
    </div>
    
    <div class="col-6 col-md-3 shipment-filter-space" v-if="showCarrierDropdown">
      <choices-single
        ref="carrierDropdown"
        :label="carrierLabelText"
        :options="carrierList"
        v-model="selectedCarrier"
        @input="filterQuote(carrierLabelText)"
      >
        <option value="0">Select</option>
      </choices-single>
    </div>

    <div
      class="col-6 col-md-3 shipment-filter-space"
      v-if="showServiceLevelDropdown"
    >
      <choices-single
        :label="serviceLevelLabelText"
        :options="serviceLevelList"
        v-model="selectedServiceLevel"
        @input="filterQuote(serviceLevelLabelText)"
      >
        <option value="0">Select</option>
      </choices-single>
    </div>
    <div
      class="col-6 col-md-3 shipment-filter-space"
      v-if="showTransitTimeDropdown"
    >
      <choices-single
        :label="transitTimeLabelText"
        :options="transitTimeList"
        v-model="selectedTransitTime"
        @input="filterQuote(transitTimeLabelText)"
      >
        <option value="0">Select</option>
      </choices-single>
    </div>
    <div class="col-6 col-md-3 shipment-filter-space" v-if="showBuyRateDropdown">
      <choices-single
        ref="buyRateDropdown"
        :label="buyrateLabelText"
        :options="buyRateList"
        v-model="selectedBuyRate"
        @input="filterQuote(buyrateLabelText)"
      >
        <option value="0">Select</option>
      </choices-single>
    </div>

    <div class="col-6 col-md-2 shipment-filter-space" v-if="showMarginDropdown && userIsBroker">
      <choices-single
         ref="marginDropdown"
        :label="marginLabelText"
        :options="marginList"
        v-model="selectedMargin"
        @input="filterQuote(marginLabelText)"
      >
        <option value="0">Select</option>
      </choices-single>
    </div>

    <base-button
      v-if="showFilterIcon()"
      type="primary"
      class="btn-theme-EFEBEB delete-filter"
      @click="clearFilter"
    >
      <i class="fa fa-times" aria-hidden="true"></i>
    </base-button>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ChoicesSingle from "@/components/SingleSelect";

export default {
  name: "light-table",
  components: {
    ChoicesSingle,
  },
  props: {
    // Component props
    filterData: {
      type: Array,
    },
    showCarrierDropdown: {
      type: Boolean,
    },
    showBuyRateDropdown: {
      type: Boolean,
    },
    showMarginDropdown: {
      type: Boolean,
    },
    isSearchQuote: {
      type: Boolean,
    },
    dbFilterData: {
      type: Array,
    },
  },
  data() {
    return {
      // Component's data properties
      carrierLabelText: "Carrier",
      serviceLevelLabelText: "Service Level",
      transitTimeLabelText: "Transit Time",
      buyrateLabelText: "Buy Rate",
      marginLabelText: "Margin",
      carrierList: [
        {
          id: 1,
          text: "A to Z",
        },
        {
          id: 2,
          text: "Z to A",
        },
      ],
      serviceLevelList: [],
      transitTimeList: [],
      buyRateList: [
        {
          id: 1,
          text: "Low to High",
        },
        {
          id: 2,
          text: "High to Low",
        },
      ],
      marginList: [
        {
          id: 1,
          text: "Low to High",
        },
        {
          id: 2,
          text: "High to Low",
        },
      ],
      selectedCarrier: null,
      selectedServiceLevel: null,
      selectedTransitTime: null,
      selectedBuyRate: null,
      selectedMargin: null,
      showServiceLevelDropdown: false,
      showTransitTimeDropdown: false,
      searchQuote: "",
    };
  },
  computed: {
    ...mapGetters(["userIsBroker"]),
  },
  methods: {
    /**
     * Filter quotes based on the selected filter.
     * @param {string} isFilter - Filter label text
     */
    async filterQuote(isFilter) {
      let quotesFilteredData = [];
      let quotesItem = this.filterData;
      
      if (isFilter == this.carrierLabelText && this.selectedCarrier) {
        quotesFilteredData = await this.filterCarrier(quotesItem);
      }

      if (isFilter == this.buyrateLabelText && this.selectedBuyRate) {
        quotesFilteredData = await this.filterByRate(quotesItem);
      }

      if (isFilter == this.marginLabelText && this.selectedMargin) {
        quotesFilteredData = await this.filterMargin(quotesItem);
      }

      this.filterData = quotesFilteredData;

      if (this.selectedCarrier == "Select" && this.selectedBuyRate == "Select" && this.selectedMargin == "Select") {
        quotesFilteredData = quotesItem.sort((a, b) =>
          a.carrierName.toString().localeCompare(b.carrierName.toString())
        );

        this.filterData = quotesFilteredData;
      }

      this.$emit("filter-quote", quotesFilteredData);
    },
    /**
     * Filter quotes by carrier name.
     * @param {Array} item - Quotes data array
     * @returns {Array} - Filtered quotes by carrier
     */
    filterCarrier(item) {
      let filteredCarrierData = [];
      if (this.selectedCarrier == "A to Z") {
        filteredCarrierData = item.sort((a, b) =>
          a.carrierName.toString().localeCompare(b.carrierName.toString())
        );
      } else {
        filteredCarrierData = item.sort((a, b) =>
          b.carrierName.toString().localeCompare(a.carrierName.toString())
        );
      }
      return filteredCarrierData;
    },
      /**
     * Filter quotes by buy rate.
     * @param {Array} item - Quotes data array
     * @returns {Array} - Filtered quotes by buy rate
     */
    filterByRate(item) {
      let filteredBuyRateData = [];
      if (this.selectedBuyRate == "Low to High") {
        filteredBuyRateData = item.sort((a, b) => {
          return a.buyRate - b.buyRate;
        });
      } else {
        filteredBuyRateData = item.sort((a, b) => {
          return b.buyRate - a.buyRate;
        });
      }
      return filteredBuyRateData;
    },
    /**
     * Filter quotes by margin.
     * @param {Array} item - Quotes data array
     * @returns {Array} - Filtered quotes by margin
     */
    filterMargin(item) {
      let filteredMarginData = [];
      if (this.selectedMargin == "Low to High") {
        filteredMarginData = item.sort((a, b) => {
          return this.marginClearValue(a.margin.toString()) - this.marginClearValue(b.margin.toString());
        });
      } else {
        filteredMarginData = item.sort((a, b) => {
          return this.marginClearValue(b.margin.toString()) - this.marginClearValue(a.margin.toString());
        });
      }
      return filteredMarginData;
    },
     /**
     * Clear the value of the margin.
     * @param {string} marginData - Margin data
     * @returns {string|number} - Cleared margin value
     */
    marginClearValue(marginData) {
      let currentMargin;
      if (marginData.includes("$")) {
        let splitMargin = marginData.split("$");
        currentMargin = splitMargin[1];
      } else if (marginData.includes("%")) {
        let splitMargin = marginData.split("%");
        currentMargin = splitMargin[0];
      } else {
        currentMargin = marginData;
      }
      return currentMargin;
    },
    /**
     * Search all quotes based on a search value.
     * @param {string} searchValue - The value to search for.
     * @returns {void}
     */
    searchAllQuotes(searchValue) {
      let searchItem = this.dbFilterData.map((filterItem) => {
        if (filterItem.carrier.toLowerCase().indexOf(searchValue.toLowerCase()) > -1) {
          return filterItem;
        }
      }).filter(function(element) {
        return element !== undefined;
      });

      this.$emit("search-quote", searchItem);
    },
    /**
     * Clears all the filters applied to the search.
     * Resets all input fields and dropdowns to their default states.
    */
    clearFilter() {      
      this.searchQuote = "";
      this.selectedCarrier = null;
      this.selectedBuyRate = null;
      this.selectedMargin = null;
      this.filterData = this.dbFilterData;
      this.$refs.carrierDropdown.resetDropdown();
      this.$refs.buyRateDropdown.resetDropdown();
      this.$refs.marginDropdown.resetDropdown();      
    },
    /**
     * Determines whether the filter icon should be displayed.
     * The icon is shown if there is an active search or any filter is selected.
     * @returns {boolean} True if the filter icon should be displayed, otherwise false.
    */
    showFilterIcon() {
      let isValidCarrier = this.carrierList.some(carrier => carrier.text === this.selectedCarrier);
      let isValidBuyRate = this.buyRateList.some(carrier => carrier.text === this.selectedBuyRate);
      let isValidMargin = this.marginList.some(carrier => carrier.text === this.selectedMargin);

      return this.isSearchQuote && (this.searchQuote != '' || isValidCarrier || isValidBuyRate || isValidMargin);
    }
  },
};
</script>

<style></style>
