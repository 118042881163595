<template>
  <div class="wrapper">
    <div class="body-container row">
      <left-panel :activeMenuName="activeMenuName"></left-panel>
      <div class="col-lg-10 right-panel-scroll plr-30 plan-right-panel">
        <div class="row pt-4 plan-upgrade-header right-panel-header">
          <div class="col-md-4">
            <search-bar
            />
          </div>
          <div class="col-md-4">
          </div>
          <div class="col-md-4">
            <profile-header/>
          </div>
        </div>

        <page-heading-card
          title="Upgrade Plan"
          subTitle=""
          buttonName="Check Detailed Comparison"
          imageName="upgrade-plan.png"
        />

        <div class="mt-5 mb-5">
          <ul
            role="tablist"
            class="custom-tabs nav nav-pills-info nav-pills btn-group plan-tab"
          >
            <li
              v-for="(tab, index) in tabs"
              :key="index"
              data-toggle="tab"
              role="tablist"
              :aria-expanded="selectedMonthlyTab === tab.isSelected ? 'true' : 'false'"
              class="nav-item"
            >
              <a
                data-toggle="tab"
                role="tablist"
                class="nav-link"
                :aria-expanded="selectedMonthlyTab === tab.isSelected ? 'true' : 'false'"
                :class="{ active: selectedMonthlyTab === tab.isSelected }"
                @click.prevent="changeTab(tab.isSelected)"
              >
                <div>{{ tab.text }}</div>
              </a>
            </li>
          </ul>
          
          <div class="row mt-3 plan-upgrade">
            <div class="col-lg-4 col-md-6 plan-card-1">
              <card class="card-profile pro-plan-card-radius" data-image="profile-image">
                <template slot="body">
                  <h4 class="plan-title tc-141718 mb-0">{{ plans.pro.title }}</h4>
                  <p class="plan-subtitle tc-141718">{{ plans.pro.subtitle }}</p>
                  <h4 class="mb-0">
                    <span class="plan-price tc-141718">{{ plans.pro.price }}</span>
                    <span class="plan-month">{{ plans.pro.priceUnit }}</span>
                  </h4>
                  <p class="plan-month-subtitle tc-6C7275">{{ plans.pro.monthlySubtitle }}</p>

                  <div class="table-responsive">
                    <ul class="list-unstyled">
                      <li v-for="(feature, index) in plans.pro.features" :key="index" class="py-1">
                        <div class="d-flex align-items-center">
                          <div>
                            <div class="badge badge-circle badge-plan-grey mr-3">
                              <i class="ni ni-check-bold text-white"></i>
                            </div>
                          </div>
                          <div>
                            <h6 class="mb-1 plan-description">{{ feature }}</h6>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <base-button type="primary" class="plan-btn btn-width-100">
                    Current plan
                  </base-button>
                </template>
              </card>
            </div>
            <div class="col-lg-4 col-md-6 plan-card-2">
              <card class="card-profile" data-image="profile-image">
                <template slot="body">
                  <h4 class="plan-title tc-141718 mb-0">{{ plans.team.title }}</h4>
                  <p class="plan-subtitle tc-141718">{{ plans.team.subtitle }}</p>
                  <h4 class="mb-0">
                    <span class="plan-price tc-141718">{{ plans.team.price }}</span>
                    <span class="plan-month">{{ plans.team.priceUnit }}</span>
                  </h4>
                  <p class="plan-month-subtitle tc-6C7275">{{ plans.team.monthlySubtitle }}</p>

                  <div class="table-responsive">
                    <ul class="list-unstyled">
                      <li v-for="(feature, index) in plans.team.features" :key="index" class="py-1">
                        <div class="d-flex align-items-center">
                          <div>
                            <div class="badge badge-circle badge-plan-grey mr-3">
                              <i class="ni ni-check-bold text-white"></i>
                            </div>
                          </div>
                          <div>
                            <h6 class="mb-1 plan-description">{{ feature }}</h6>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <base-button type="primary" class="plan-btn btn-width-100">
                    Upgrade
                  </base-button>
                </template>
              </card>
            </div>
            <div class="col-lg-4 col-md-6 plan-card-3">
              <card class="card-profile plan-highlight-card" data-image="profile-image">
                <template slot="body">
                  <div class="enterprise-container">
                    <h4 class="plan-title tc-FFFFFF mb-0">{{ plans.enterprise.title }}</h4>
                    <span class="popular-badge">Popular</span>
                  </div>

                  <p class="plan-subtitle tc-FFFFFF">{{ plans.enterprise.subtitle }}</p>
                  <h4 class="mb-0">
                    <span class="plan-price tc-FFFFFF">{{ plans.enterprise.price }}</span>
                  </h4>
                  <p class="plan-month-subtitle tc-FFFFFF">{{ plans.enterprise.monthlySubtitle }}</p>

                  <div class="table-responsive">
                    <ul class="list-unstyled">
                      <li v-for="(feature, index) in plans.enterprise.features" :key="index" class="py-1">
                        <div class="d-flex align-items-center">
                          <div>
                            <div class="badge badge-circle badge-plan-highlight mr-3">
                              <i class="ni ni-check-bold text-white"></i>
                            </div>
                          </div>
                          <div>
                            <h6 class="mb-1 plan-description tc-FFFFFF">{{ feature }}</h6>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <base-button type="primary" class="btn-theme-FFD75E btn-width-100 support-team-btn">
                    Upgrade with support team
                    <img src="../assets/img/home/arrow-circle-right.png">
                  </base-button>
                </template>
              </card>
            </div>
          </div>        
          
          <div class="mt-4">
            <div class="headline-30 mb-4">
              Compare features in details
            </div>

            <div class="plan-table">
              <v-app id="inspire">
                <v-data-table
                  v-model="selected"
                  :headers="headers"
                  :items="tableData"
                  :single-expand="singleExpand"
                  :expanded.sync="expanded"
                  :single-select="singleSelect"
                  :hide-default-footer="true"
                  sort-by="id"
                  select-enable
                  class="elevation-1"
                >

                <template v-slot:item.pro="{item}">
                  <div v-if="item.pro == 'supported'">
                    <i class="ni ni-check-bold tc-24876E"></i>
                  </div>
                  <div v-else-if="item.pro == 'not_supported'">
                    <i class="fa fa-times tc-24876E"></i>
                  </div>
                  <div v-else>
                    {{ item.pro }}
                  </div>
                </template>

                <template v-slot:item.team="{item}">
                  <div v-if="item.team == 'supported'">
                    <i class="ni ni-check-bold tc-24876E"></i>
                  </div>
                  <div v-else-if="item.team == 'not_supported'">
                    <i class="fa fa-times tc-24876E"></i>
                  </div>
                  <div v-else>
                    {{ item.team }}
                  </div>
                </template>

                <template v-slot:item.enterprise="{item}">
                  <div v-if="item.enterprise == 'supported'">
                    <i class="ni ni-check-bold tc-24876E"></i>
                  </div>
                  <div v-else-if="item.enterprise == 'not_supported'">
                    <i class="fa fa-times tc-24876E"></i>
                  </div>
                  <div v-else>
                    {{ item.enterprise }}
                  </div>
                </template>
                
                </v-data-table>
              </v-app>
            </div>
                      
          </div>
        </div>
      </div>
      
    </div>
  </div>
</template>

<script>
import LeftPanel from "@/layout/LeftPanel";
import SearchBar from "@/views/components/SearchBar";
import ProfileHeader from "@/layout/ProfileHeader";
import PageHeadingCard from "@/views/components/PageHeadingCard";

export default {
  components: {
    LeftPanel,
    SearchBar,
    ProfileHeader,
    PageHeadingCard,
  },
  data() {
    return {
      activeMenuName: "",
      selectedMonthlyTab: true, // Default selected tab
      tabs: [
        { text: 'Monthly', isSelected: true },
        { text: 'Yearly', isSelected: false }
      ],
      plans: {
        pro: {
          title: 'Pro',
          subtitle: 'Pro for personal uses',
          price: "$399",
          priceUnit: '/ mo',
          monthlySubtitle: 'Free forever',
          features: ['30 days history', 'Up to 1000 messages/mo', 'Pro support'],
          buttonType: 'primary',
          buttonText: 'Current plan',
          image: 'profile-image'
        },
        team: {
          title: 'Team',
          subtitle: 'More advanced for team',
          price: "$599",
          priceUnit: '/ mo',
          monthlySubtitle: 'Per month, per team members',
          features: ['90 days history', 'Unlimited messages', 'Enterprise-level support'],
          buttonType: 'primary',
          buttonText: 'Upgrade',
          image: 'profile-image'
        },
        enterprise: {
          title: 'Enterprise',
          subtitle: 'For large team and business',
          price: 'Contact Sales',
          priceUnit: '',
          monthlySubtitle: 'Per month, per team members',
          features: ['Customizable plan', 'Advanced team management', 'Enterprise-level support'],
          buttonType: 'btn-theme-FFD75E',
          buttonText: 'Upgrade with support team',
          image: 'profile-image'
        }
      },
      singleSelect: false,
      selected: [],
      expanded: [],
      singleExpand: false,
      headers: [
        // Table headers configuration
        { text: "Core features", value: "features", sortable: false },
        { text: "Pro", value: "pro", sortable: false },
        { text: "Team", value: "team", sortable: false },
        { text: "Enterprise", value: "enterprise", sortable: true },
      ],
      tableData: [
        {
          features:"Rates & Quotes",
          pro:"",
          team:"",
          enterprise:""
        },
        {
          features:"Quote History",
          pro:"supported",
          team:"supported",
          enterprise:"supported"
        },
        {
          features:"Feature 3",
          pro:"supported",
          team:"supported",
          enterprise:"supported"
        },
        {
          features:"Feature 4",
          pro:"not_supported",
          team:"upon request",
          enterprise:"supported"
        },
        {
          features:"Feature 5",
          pro:"not_supported",
          team:"not_supported",
          enterprise:"supported"
        },
        {
          features:"API integrations (view list)",
          pro:"not_supported",
          team:"supported",
          enterprise:"supported"
        },
        {
          features:"API calls per week",
          pro:"not_supported",
          team:"500",
          enterprise:"Unlimited"
        },
      ]
    };
  },
  methods: {
    changeTab(isSelected) {
      this.selectedMonthlyTab = isSelected;
    }
  }
};
</script>

<style></style>
