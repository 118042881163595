<template>
  <div>
    <div class="choices-dropdown-label">{{label}}</div>
    <select ref="select">
      <slot></slot>
    </select>
  </div>
</template>
<script>
import Choices from "choices.js";
import "choices.js/public/assets/styles/choices.min.css";

export default {
  name: "selects",
  props: [
    "options", 
    "value",
    "label"
  ],
  mounted: function() {
    this.choicesInstance = new Choices(this.$refs.select,{
      shouldSort:false
    });
    this.$refs.select.addEventListener("addItem", this.handleSelectChange);
    this.setChoices();
  },
  methods: {
    handleSelectChange(e) {
      this.$emit("input", e.target.innerText);
    },
    setChoices() {
      this.choicesInstance.setChoices(this.options, "id", "text", true);
      // Set the selected value based on the v-model
      if (this.value) {
        this.choicesInstance.setChoiceByValue(this.value);
      }
    },
    resetDropdown() {
      this.choicesInstance.setValue(["Select"])
    }
  },
  updated() {
    this.setChoices();
  },
  destroyed: function() {
    this.choicesInstance.destroy();
  },
};
</script>
<style></style>
