<template>
  <div class="wrapper">
    <div class="body-container row">
      <left-panel :activeMenuName="activeMenuName"></left-panel>
      <div class="col-lg-10 right-panel-scroll plr-30 mt-4">
        <div class="row right-panel-header">
          <div class="col-md-4">
            <search-bar
            />
          </div>
          <div class="col-md-4">
          </div>
          <div class="col-md-4">
            <profile-header/>
          </div>
        </div>

        <div class="card card-green br-16 page-heading-card">
          <div class="card-body pt-0">
            <div class="row align-items-center">
              <div class="col-md-6 order-2 order-md-1">
                <div class="page-heading-card-title">
                  Teammates
                </div>
                <p class="page-heading-card-subtitle">
                </p>
                <div class="d-flex m-text-center">
                  <base-button
                    class="btn-theme-083C2F mr-2"
                    @click="showModal"
                  >
                    Invite teammate
                  </base-button>

                  <copy-invite-link-button />
                </div>
              </div>
              <div class="col-md-6 order-1 order-md-2">
                <img
                  class="page-heading-img"
                  src="../assets/img/teammates.png"
                >
              </div>
            </div>
          </div>
        </div>

        <div class="mt-5 mb-5 section-b">
          <div class="row mb-4">
            <div class="col-md-4 headline-30 mb-3">
              All Teammates
            </div>
            <div class="col-md-4"></div>
            <div class="col-md-4 headline-30">
              <img class="t-search-icon" src="../assets/img/search.png">
              <vue-input
                alternative
                placeholder="Search All Teammates"
                type="text"
                class="search-all-teammates"
                v-model="searchTeammates"
                @input="(event) => filterTeammates(event)"
              ></vue-input>
            </div>
          </div>


          <custom-table
            :headers="headers"
            :tableData="tableData"
            sortByKey="name"
            :viewAllRecord="true"
            :isTeammates="true"
          />

        </div>
      </div>

      <invite-user
        ref="inviteUserModal"
        @update-teammates="getAllTeammateUsers"
      />
    </div>
  </div>
</template>

<script>
import LeftPanel from "../layout/LeftPanel";
import SearchBar from "@/views/components/SearchBar";
import ProfileHeader from "@/layout/ProfileHeader";
import CopyInviteLinkButton from "@/views/components/CopyInviteLinkButton";
import CustomTable from "./components/Tables/CustomTable.vue";
import InviteUser from "../views/InviteUser.vue";
import { API, masterAPI } from "@/api/API";
import {mapGetters} from 'vuex';

export default {
  components: {
    LeftPanel,
    SearchBar,
    ProfileHeader,
    CustomTable,
    InviteUser,
    CopyInviteLinkButton,
  },
  data: function() {
    return {
      activeMenuName: "Teammates",
      dbTeammatesData: [],
      searchTeammates: "",
      headers: [
        { text: "Select", value: "select",sortable: false },
        { text: "Name", value: "name" },
        { text: "Email", value: "email" },
        { text: "Role", value: "type" },
        { text: "Quotes Created", value: "quotesCreated" },
        { text: "Actions", value: "action", sortable: false, width: "150px"},
      ],
      tableData: [],
    };
  },
  computed: {
    ...mapGetters([
      'userId',
    ])
  },
  methods: {
    showEditTeammates(id) {
      console.log(id);
    },
    viewAllQuotes(item) {
      this.$router.push({
        name: "viewallquotesbyuser",
        params: { id: item.userId },
      });
    },
    async getAllTeammateUsers() {
      this.tableData = [];
      this.dbTeammatesData = [];

      let response = await masterAPI(
        API.API_ENDPOINT.allTeammatesUsers,
        API.API_METHOD.get,
        undefined,
        undefined
      );

      if (response.status == 200) {
        let allUser = response.data;
        allUser = allUser.map(user => {
          if (user.invitedUser) {
            return { ...user, name: "Invitation Sent" };
          }
          return user;
        });
        
        this.tableData = allUser;
        this.dbTeammatesData = allUser;
      }
    },
    showModal() {
      this.$refs.inviteUserModal.openModal();
    },
    filterTeammates: function(value) {
      this.tableData = this.dbTeammatesData.filter(item => {
        if(item.name.toLowerCase().replace(/\s/g,'').includes(value.toLowerCase().replace(/\s/g,''))){
          return item;
        }
      });
    },
  },
  created() {
    this.getAllTeammateUsers();
  },
};
</script>

<style></style>
