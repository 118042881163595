<template>
  <div class="wrapper">
    <div class="body-container row">
      <left-panel :activeMenuName="activeMenuName"></left-panel>
      <div class="col-lg-10 right-panel-scroll plr-30 mt-4">
        <div class="row right-panel-header">
          <div class="col-md-4">
            <search-bar
            />
          </div>
          <div class="col-md-4">
          </div>
          <div class="col-md-4">
            <profile-header/>
          </div>
        </div>

        <page-heading-card
          title="Clients"
          subTitle=""
          buttonName="Add client"
          imageName="client.png"
          addRecordScreenName="newclient"
        />

        <div class="mt-5 mb-5 section-b">
          <div class="headline-30 mb-4">
            All Clients
          </div>
          <div class="row">
            <div class="col-md-4">
              <div class="autocomplete-search-div">
                <img class="autocomplete-search-icon" src="../../assets/img/search.png">
                <vue-basic-autocomplete
                  trackby="name"
                  input-class="form-control autocomplete-search-input"
                  placeholder="Search Clients"
                  :clear-btn="false"
                  none-find="Client not found"
                  :options="tableData"
                  v-model="searchClient"
                  @selected="(event) => editClient(event.id)"
                />
              </div>
            </div>
            <div class="col-md-4">
            </div>
            <div class="col-md-3">
              <choices-single
                :options="[]"
              >
                <option value="0">Recent clients</option>
              </choices-single>
            </div>
            <div
              v-if="showDelete"
              class="col-md-1 pl-0"
            >
              <base-button
                type="primary"
                class="btn-theme-EFEBEB action-delete"
                @click="confirmClientModal = true"
              >
                <img src="../../assets/img/home/delete.png"/>
              </base-button>
            </div>
          </div>

          <custom-table
            :headers="headers"
            :tableData="tableData"
            sortByKey="name"
            @edit-item="editClient"
            @selected-items="selectedItems"
            :viewAllRecord="true"
            :isTeammates="false"
          />

        </div>
      </div>
      <modal
        :show.sync="confirmClientModal"
        modal-classes="modal-dialog-centered confrim-quote-modal"
      >
        <h5
          slot="header"
          class="modal-title confrim-quote-header"
          id="modal-title-default"
        >
          Delete
        </h5>

        <div>
          Are you sure you want to delete <b>{{ selectedItemName }}</b>. This action is
          not reversible. All clients deleted will no longer be available in
          Pallet until they are added again
        </div>

        <template slot="footer">
          <base-button
            class="btn-theme-083C2F"
            @click="deleteClient"
          >
            Confirm
          </base-button>
          <base-button
            class="btn-theme-EFEBEB"
            @click="confirmClientModal = false"
            >Cancel
          </base-button>
        </template>
      </modal>
    </div>
  </div>
</template>

<script>
import LeftPanel from "@/layout/LeftPanel";
import SearchBar from "@/views/components/SearchBar";
import ProfileHeader from "@/layout/ProfileHeader";
import PageHeadingCard from "@/views/components/PageHeadingCard";
import ChoicesSingle from "@/components/SingleSelect";
import CustomTable from "@/views/components/Tables/CustomTable.vue";
import Modal from "@/components/Modal.vue";
import { API, masterAPI } from "@/api/API";

export default {
  components: {
    LeftPanel,
    SearchBar,
    ProfileHeader,
    PageHeadingCard,
    ChoicesSingle,
    CustomTable,
    Modal,
  },
  data() {
    return {
      activeMenuName: "Client",
      tableData: [],
      headers: [
        { text: "Select", value: "select", sortable: false },
        { text: "Client name", value: "name" },
        { text: "Last updated", value: "updatedAt" },
        { text: "Min. margin", value: "margin.value" },
        { text: "Actions", value: "action", sortable: false, width: "150px"},
      ],
      showDelete: false,
      confirmClientModal: false,
      selectedItemName: '',
      checkedRecord: [],
      searchClient: "",
    };
  },
  methods: {
    /**
     * Redirects the user to the edit client page with the specified record ID.
     * @param {string} recordId - The ID of the client record to be edited.
     */
    editClient(recordId) {
      this.$router.push({
        name: 'editClient',
        params: { id: recordId },
      })
    },
    /**
     * Fetches all clients and sorts them by modified date.
     * @returns {Promise<void>}
     */
    async getAllClient() {
      const response = await masterAPI(API.API_ENDPOINT.client, API.API_METHOD.get);
      if (response.status === 200) {
        this.tableData = response.data;
      }
    },
    /**
     * Updates component state based on selected items
     * @param {Array} selectedRecords - Array of selected records
    */
    selectedItems(selectedRecords) {
      this.showDelete = selectedRecords.length > 0;

      if (this.showDelete) {
        this.selectedItemName = selectedRecords.map(({ name }) => name).join(", ");
        this.checkedRecord = selectedRecords;
      }
    },
     /**
     * Deletes the selected clients.
     * @async
    */
    async deleteClient() {
       // Retrieve the selected clients
      const selectedClient = this.checkedRecord;
      const deleteRequests = selectedClient.map(({ id }) => {
        return masterAPI(API.API_ENDPOINT.client, API.API_METHOD.delete, id);
      });
      const responses = await Promise.all(deleteRequests);
      if (responses.length === selectedClient.length) {
        await this.getAllClient();
        this.confirmClientModal = false;
        this.$toast.success("Client delete successful", { timeout: 1000 });
         // Hide the delete button
        this.showDelete = false;
      }
    },
  },

  created() {
    // Initialization: Retrieve client data when the component is created
    this.getAllClient();
  },
};
</script>

<style scoped>
.back-to-client {
  margin-bottom: 15px;
}
.recent-client-table {
  margin-top: 7%;
  margin-bottom: 3%;
}

.view-client-btn {
  font-size: 13px !important;
}

.view-client {
  text-align: right !important;
}
</style>
