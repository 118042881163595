<template>
  <div class="row">
    <div class="form-group has-label col-md-3">
      <base-input
        type="text"
        class="input-v1"
        label="Client Name"
        rules="required"
        name="Client Name"
        placeholder="Apollo Sheet Metal"
        v-model="clientName"
      >
      </base-input>
    </div>
    <div class="form-group has-label col-md-3">
      <base-input
        type="text"
        class="input-v1"
        label="Date Added"
        v-model="dateAdded"
        disabled="true"
      >
      </base-input>
    </div>
    <div class="form-group has-label col-md-3 input-v1">
      <label> Min Margin in {{ marginDollar ? "$" : "%" }}</label>
      <ValidationProvider
        rules="required"
        name="Margin"
        v-slot="{ errors, valid, invalid, validated }"
        v-if="marginDollar"
      >
        <input
          type="number"
          step="0.01"
          min="50"
          :class="[
            { 'is-valid': valid && validated },
            { 'is-invalid': invalid && validated },
            'form-control',
          ]"
          :placeholder="marginDollar ? '$00.00' : '00.00%'"
          v-model="margin"
        />
        <span class="validate-error">{{ errors[0] }}</span>
      </ValidationProvider>
      <ValidationProvider
        rules="required"
        name="Margin"
        v-slot="{ errors, valid, invalid, validated }"
        v-else
      >
        <input
          type="number"
          step="0.01"
          :class="[
            { 'is-valid': valid && validated },
            { 'is-invalid': invalid && validated },
            'form-control',
          ]"
          :placeholder="marginDollar ? '$00.00' : '00.00%'"
          v-model="margin"
        />
        <span class="validate-error">{{ errors[0] }}</span>
      </ValidationProvider>
      <div class="input-group-append margin-input-cal margin-client input-v1">
        <span class="input-group-text">
          <span class="margin-toggle-arrow" v-on:click="marginToggle">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M16.1656 5.81608L5.65059 5.81608" stroke="#130F26" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12.75 2.41699C12.75 2.41699 16.1667 4.22449 16.1667 5.81533C16.1667 7.40533 12.75 9.21366 12.75 9.21366" stroke="#130F26" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M3.81043 14.0885L14.3254 14.0885" stroke="#130F26" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M7.22633 17.4881C7.22633 17.4881 3.80967 15.6797 3.80967 14.0897C3.80967 12.4997 7.22633 10.6914 7.22633 10.6914" stroke="#130F26" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </span>
          <div class="circle-margin">
            <span v-if="marginDollar" class="margin-percentage-dollar">
              %
            </span>
            <span v-else class="margin-percentage-dollar">
              $
            </span>
          </div>
        </span>
      </div>
    </div>
    <div class="form-group col-md-3">
      <label class="mb-0">{{ currencyTypeLabelText }}</label>
      <ValidationProvider
        rules="required"
        name="Currency Type"
        v-slot="{ errors }"
      >
        <choices-single
          id="currencyType"
          :options="currencyTypeList"
          v-model="selectedCurrencyType"
        >
          <option value="0">Select</option>
        </choices-single>
        <span class="validate-error">{{ errors[0] }}</span>
      </ValidationProvider>
    </div>
  </div>
</template>

<script>
import ChoicesSingle from "@/components/SingleSelect";
import BaseInput from "@/components/BaseInput";
import { currencyTypeItems } from "@/helpers/utility";

export default {
  // List of imported components
  components: {
    ChoicesSingle,
    BaseInput,
  },
  props: {
    // Component props
    clientName: String,
    dateAdded: String,
    marginDollar: Boolean,
    margin: String,
    selectedCurrencyType: String,
  },
  data() {
    return {
      // Component's data properties
      currencyTypeLabelText: "Currency Type",
      currencyTypeList: [
        { id: 1, text: currencyTypeItems.USD },
        { id: 2, text: currencyTypeItems.CDN },
      ],
    };
  },
  watch: {
    clientName() {
      this.updateProps();
    },
    margin() {
      this.updateProps();
    },
    selectedCurrencyType() {
      this.updateProps();
    },
    marginDollar() {
      this.updateProps();
    }
  },
  methods: {
    marginToggle() {
      // Toggle the value of marginDollar flag
      this.marginDollar = !this.marginDollar;
    },
    /**
     * Updates the props in the parent component by emitting an event.
     * Emits an 'update:props' event with the updated prop values.
     */
    updateProps() {
      this.$emit("update:props", {
        clientName: this.clientName,
        margin: this.margin,
        selectedCurrencyType: this.selectedCurrencyType,
        marginDollar: this.marginDollar,
      });
    },
  },
};
</script>
