<template>
  <div class="col-md-12 mb-3">
    <div 
      v-if="pickupDeliveryDate.addPickupDeliveryDateLabel"
    >
      <a 
        class="add-pickup-delivery-link"
        @click="openPickupDeliveryDate"
      >
       Add Pickup & Delivery times +
      </a>
    </div>
    <div v-else class="row">
      <div class="col-md-6">
        <div class="date-picker-label">Pickup Date</div>
        <date-picker 
          placeholder="Select date" 
          class="date-picker" 
          format="MMM DD, YYYY"
          v-model="pickupDeliveryDate.pickupDate" 
        ></date-picker>
      </div>
      <div class="col-md-6">
        <div class="date-picker-label">Delivery Date</div>
        <date-picker 
          placeholder="Select date" 
          class="date-picker" 
          format="MMM DD, YYYY"
          v-model="pickupDeliveryDate.deliveryDate" 
        ></date-picker>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  components: {
    DatePicker
  },
  data: function() {
    return {
      
    };
  },
  props: {
    pickupDeliveryDate: {
      type: Object,
    },
  },
  methods: {
    openPickupDeliveryDate() {
      this.pickupDeliveryDate.addPickupDeliveryDateLabel = false;
    }
  },
  created() {
    
  },
};
</script>
<style>

</style>
