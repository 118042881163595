<template>
  <div class="autocomplete-search-div">
    <img class="autocomplete-search-icon" src="../../assets/img/search.png">
    <vue-basic-autocomplete
      trackby="name"
      input-class="form-control autocomplete-search-input"
      placeholder="Search Quote, Carrier, User"
      :clear-btn="false"
      none-find="Carrier not found"
      :options="[]"
    />
<!--    <base-button class="btn">-->
<!--      -->
<!--    </base-button>-->
  </div>
</template>

<script>
export default {

};
</script>

<style scoped>
</style>
