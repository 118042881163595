<template>
  <div>
    <div class="card card-green br-16 page-heading-card">
      <div class="card-body pt-0">
        <div class="row align-items-center">
          <div class="col-md-6 order-2 order-md-1">
            <div class="page-heading-card-title">
              {{ title }}
            </div>
            <p class="page-heading-card-subtitle">
              {{ subTitle }}
            </p>
            <base-button 
              v-if="buttonName"
              class="btn-theme-083C2F"
              @click="goNewRecord"
            >
              {{ buttonName }}
            </base-button>
          </div>
          <div class="col-md-6 order-1 order-md-2">
            <img 
              class="page-heading-img" 
              :src="require(`../../assets/img/${imageName}`)"
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    subTitle: String,
    buttonName: String,
    imageName: String,
    addRecordScreenName: String,
  },
  methods: {
    goNewRecord() {
      this.$router.push({ name: this.addRecordScreenName })
    }
  }
};
</script>

<style scoped>
</style>
