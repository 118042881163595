<template>
  <ul
    role="tablist"
    class="custom-tabs nav nav-pills-info nav-pills btn-group"
  >
    <li
      data-toggle="tab"
      role="tablist"
      aria-expanded="true"
      class="nav-item active"
    >
      <a
        data-toggle="tab"
        role="tablist"
        class="nav-link"
        :aria-expanded="selectedTab == lbsLabel ? 'true' : 'false'"
        :class="selectedTab == lbsLabel ? 'active' : ''"
        @click.prevent="tabHandler(lbsLabel)"
      >
        <div>{{lbsLabel}}/{{ inchesLabel }}</div>
      </a>
    </li>
    <li
      data-toggle="tab"
      role="tablist"
      aria-expanded="true"
      class="nav-item active"
    >
      <a
        data-toggle="tab"
        role="tablist"
        class="nav-link "
        :aria-expanded="selectedTab == kgsLabel ? 'true' : 'false'"
        :class="selectedTab == kgsLabel ? 'active' : ''"
        @click.prevent="tabHandler(kgsLabel)"
      >
        <div>{{kgsLabel}}/{{cmsLabel}}</div>
      </a>
    </li>
  </ul>
</template>

<script>
import {
  convertInchToCentimeter,
  convertCentimeterToInch,
  convertLbsToKgs,
  convertKgsToLbs,
  checkEmptyValue,
} from "@/helpers/utility";

export default {
  components: {},
  data: function() {
    return {
      // Component's data properties
      lbsLabel: 'lbs',
      kgsLabel: 'kgs',
      inchesLabel: 'inches',
      cmsLabel: 'cms',
    };
  },
  props: {
    // Component props
    selectedTab: {
      type: String, 
    },
    addShipmentItem: {
      type: Array, 
    },
  },
  methods: {
    /**
     * Handles the tab change event.
     * @param {String} activeTab - The newly selected tab.
     */
     tabHandler(activeTab) {
      if (activeTab != this.selectedTab) {
        let additionalPieceList = this.addShipmentItem;
        additionalPieceList.map((item) => {
          if (activeTab == this.kgsLabel) {
            // Length
            let convertedLength = convertInchToCentimeter(item.originalLength);
            item.originalLength = convertedLength;
            item.length = checkEmptyValue(convertedLength);
            // Width
            let convertedWidth = convertInchToCentimeter(item.originalWidth);
            item.originalWidth = convertedWidth;
            item.width = checkEmptyValue(convertedWidth);
            // Height
            let convertedHeight = convertInchToCentimeter(item.originalHeight);
            item.originalHeight = convertedHeight;
            item.height = checkEmptyValue(convertedHeight);
            // Weight
            let convertedWeight = convertLbsToKgs(item.originalWeight);
            item.originalWeight = convertedWeight;
            item.weight = checkEmptyValue(convertedWeight);
          } else {
            // Length
            let convertedLength = convertCentimeterToInch(item.originalLength);
            item.originalLength = convertedLength;
            item.length = checkEmptyValue(convertedLength);
            // Width
            let convertedWidth = convertCentimeterToInch(item.originalWidth);
            item.originalWidth = convertedWidth;
            item.width = checkEmptyValue(convertedWidth);
            // Height
            let convertedHeight = convertCentimeterToInch(item.originalHeight);
            item.originalHeight = convertedHeight;
            item.height = checkEmptyValue(convertedHeight);
            // Weight
            let convertedWeight = convertKgsToLbs(item.originalWeight);
            item.originalWeight = convertedWeight;
            item.weight = checkEmptyValue(convertedWeight);
          }
        });
        this.$emit("change-tab", additionalPieceList, activeTab);
      }
    },
  },
  created() {},
};
</script>
<style></style>
