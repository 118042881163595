<template>
<div>
  <div class="carrier-contact-table">
    <div class="mb-3 mt-3">
      <span class="v-data-table-title"> Carrier Contacts </span>
    </div>
    <div>
      <div
        v-for="(item, index) in contactData"
        :key="index"
        class="row mb-2"
      >
        <div class="col-md-2">
          <base-input
            type="text"
            class="input-v1"
            label="Contact Name"
            name="Contact Name"
            rules="required"
            v-model="item.name"
          >
          </base-input>
        </div>
        <div class="col-md-3">
          <base-input
            type="email"
            class="input-v1"
            label="Contact Email"
            name="Contact Email"
            rules="required"
            v-model="item.email"
          >
          </base-input>
        </div>
        <div class="col-md-2 ipad-pl">
          <base-input
            type="text"
            class="input-v1"
            label="Contact Number"
            name="Contact Number"
            placeholder="(234) 567-8910"
            rules="phoneNumber"
            v-model="item.phoneNumber"
            @input="acceptNumber(index, item.phoneNumber)"
          >
          </base-input>
        </div>
        <div class="col-md-2">
          <base-input
            type="text"
            class="input-v1"
            label="Valid From"
          >
            <flat-picker
              :config="{ dateFormat: 'M J, Y' }"
              class="form-control datepicker"
              v-model="item.startDate"
            >
            </flat-picker>
          </base-input>
        </div>
        <div class="col-md-2">
          <base-input
            type="text"
            class="input-v1"
            label="Valid To"
          >
            <flat-picker
              :config="{ dateFormat: 'M J, Y' }"
              class="form-control datepicker"
              v-model="item.endDate"
            >
            </flat-picker>
          </base-input>
        </div>
        <div class="col-md-1 ipad-pl">
          <base-button
            type="primary"
            class="btn-theme-EFEBEB action-round"
            @click="removeContact(index)"
          >
            <img src="../../assets/img/home/trash.png"/>
          </base-button>
        </div>
      </div>
    </div>
  </div>
  <base-button
    type="primary"
    class="btn-theme-24876E mt-2"
    v-on:click="addContact"
  >
    <img src="../../assets/img/home/plus-round.png"/>
    Add addtional contact
  </base-button>
</div>
</template>
<script>

import BaseInput from "@/components/BaseInput";
import BaseButton from "@/components/BaseButton";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

export default {
  components: {
    BaseInput,
    BaseButton,
    flatPicker
  },
  props: {
    contactData: Array,
  },
  data() {
    return {
      
    };
  },
  methods: {
    /**
     * Adds a new contact to the contactData array.
     */
    addContact() {
      let index = this.contactData.length;
      let contactObj = {
        name: "",
        email: "",
        phoneNumber: "",
        startDate: "",
        endDate: "",
        isEdit: true,
        id: index + 1,
        alreadySave: false,
      };
      this.contactData.push(contactObj);
    },

    /**
     * Accepts a phone number and formats it based on a specific pattern.
     * @param {number} i - The index of the contact in the contactData array.
     * @param {string} phone - The phone number to be formatted.
     */
    acceptNumber(i, phone) {
      // Apply formatting to the phone number based on a specific pattern
      const formatNumber = phone
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.contactData[i].phoneNumber = !formatNumber[2]
        ? formatNumber[1]
        : `(${formatNumber[1]}) ${formatNumber[2]}${
            formatNumber[3] ? "-" + formatNumber[3] : ""
          }`;
    },

    /**
     * Removes a contact from the contactData array at the specified index.
     * @param {number} index - The index of the contact to be removed.
     */
    removeContact(index) {
      // Check if there is at least one contact
      if (this.contactData.length > 1) {
        this.contactData.splice(index, 1);
      } else {
        this.$toast.error("At least one contact is required");
      }
    },
  },
};
</script>
