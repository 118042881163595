<template>
  <div>
    <div 
      v-for="(card, index) in cardData"
      :key="index"
      class="card card-white d-record-card"
    >
      <div class="card-body">
        <div class="row">
          <div class="col-6 col-md-8">
            <div>
              <img :src="require(`../../assets/img/home/${card.iconName}`)" class="img-fluid" /> &nbsp;&nbsp;
              <span class="font-16">{{ card.title }}</span>
            </div>
            <h1 class="mt-2">{{ card.count }}</h1>
            <div class="card-line"></div>
          </div>
          <div class="col-md-4 d-flex align-items-center justify-content-center">
            <div>
              <i class="ni ni-bold-right"></i>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card card-white m-record-card">
      <div class="card-body">
        <div class="row">
          <div 
            v-for="(card, index) in cardData"
            :key="index"
            :class="['col-6', { 'record-card-border-right': index % 2 === 0 }]"
          >
            <div>
              <img :src="require(`../../assets/img/home/${card.iconName}`)" class="img-fluid" /> &nbsp;&nbsp;
              <span class="font-16">{{ card.title }}</span>
            </div>
            <h1 class="mt-2">{{ card.count }}</h1>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    cardData: Array,
  }
};
</script>

<style scoped>
</style>
