<template>
  <div class="wrapper">
    <div class="body-container row add-edit-carrier">
      <loader :isLoader="isLoader"></loader>
      <left-panel :activeMenuName="activeMenuName"></left-panel>
      <div class="col-lg-10 right-panel-scroll plr-30 mt-4">
        <div class="row right-panel-header">
          <div class="col-md-4">
            <search-bar
            />
          </div>
          <div class="col-md-4">
          </div>
          <div class="col-md-4">
            <profile-header/>
          </div>
        </div>

        <page-heading-card
          title="Add Carrier"
          subTitle=""
          buttonName="See all saved carrier"
          imageName="carriers.png"
          addRecordScreenName="carriers"
        />

        <div class="mt-5 mb-4 section-b">
          <ValidationObserver v-slot="{ validate }" ref="formValidator">
            <form
              @submit.prevent="validate().then(createNewCarriers)"
              class="needs-validation"
              id="create_client"
            >
              <div class="row mb-4">
                <div class="col-md-6 headline-30">
                  Add New Carrier
                </div>
                <div class="col-md-6">
                  <div class="save-carriers">
                    <base-button
                      nativeType="submit"
                      type="primary"
                      class="btn-theme-24876E"
                      :disabled="saveBtnDisabled"
                    >
                      Save
                    </base-button>
                  </div>
                </div>
              </div>

              <div
                v-for="(item, index) in carriersList"
                :key="index"
                class="row"
              >
                <div class="form-group has-label col-md-5">
                  <base-input
                    type="text"
                    name="Carrier Name"
                    label="Carrier Name"
                    class="input-v1"
                    rules="required"
                    v-model="item.carrierName"
                  ></base-input>
                </div>
                <div class="form-group has-label col-md-6">
                  <base-input
                    type="text"
                    name="Carrier Api Key"
                    label="Carrier API"
                    class="input-v1"
                    v-model="item.tariffApiKey"
                  ></base-input>
                </div>
                <div class="col-md-1 delete-carriers text-right ipad-pl">
                  <base-button
                    type="primary"
                    class="btn-theme-EFEBEB action-delete"
                    @click="removeCarrier(index)"
                  >
                    <img src="../assets/img/home/trash.png"/>
                  </base-button>
                </div>
              </div>
              
              <base-button
                class="btn-theme-24876E mr-2"
                v-on:click="addCarriersRow"
              >
                <img src="../assets/img/home/plus-round.png"/>
                Add more carrier
              </base-button>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LeftPanel from "../layout/LeftPanel";
import Loader from "../layout/Loader";
import SearchBar from "@/views/components/SearchBar";
import ProfileHeader from "@/layout/ProfileHeader";
import PageHeadingCard from "@/views/components/PageHeadingCard";
import { API, masterAPI } from "@/api/API";

export default {
  bodyClass: "carriers",
  components: {
    LeftPanel,
    Loader,
    SearchBar,
    ProfileHeader,
    PageHeadingCard,
  },
  data: function() {
    return {
      carriersList: [
        {
          carrierName: "",
          tariffApiKey: ""
        }
      ],
      activeMenuName: "Carriers",
      saveBtnDisabled: false,
      isLoader: false
    };
  },
  methods: {
    addCarriersRow: function() {
      let carriersObj = {
        carrierName: "",
        tariffApiKey: ""
      };
      this.carriersList.push(carriersObj);
    },
    createNewCarriers: async function() {
      this.$refs.formValidator.validate().then(async (isSuccess) => {
        if (isSuccess) {
          this.saveBtnDisabled = true;
          this.isLoader = true;
          let carriers = this.carriersList;
          for (let i = 0; i < carriers.length; i++) {
            let bodyPayload = JSON.stringify({
              name: carriers[i].carrierName,
              tariffApiKey: carriers[i].tariffApiKey
            });

            let response = await masterAPI(
              API.API_ENDPOINT.carrier,
              API.API_METHOD.post,
              undefined,
              bodyPayload
            );

            if (response.status === 200) {
              this.isLoader = false;
              this.$toast.success("Carriers create successful", {
                timeout: 1000
              });
              this.$router.push({ path: "/carriers", name: "carriers" }).catch(() => {});
            }
          }
        }
      });
    },
    removeCarrier(carrierIndex) {
      if (this.carriersList.length > 1) {
        this.carriersList.splice(carrierIndex, 1);
      } else {
        this.$toast.error("Atleast one carrier require");
      }
    },
  },
  created() {
    let vm = this;
    let carriersCount = localStorage.getItem("carriersCount");
    if (carriersCount != null) {
      for (let i = 1; i < carriersCount; i++) {
        let carriersObj = {
          carrierName: "",
          tariffApiKey: ""
        };
        vm.carriersList.push(carriersObj);
      }
      localStorage.removeItem("carriersCount");
    }
  }
};
</script>

<style>
.file-btn {
    cursor: pointer;
}
</style>
