<template>
  <modal :show.sync="confirmModal" modal-classes="modal-dialog-centered confrim-quote-modal">
    <div>
      It looks like you have some unsaved changes. If you leave before
      saving, your changes will be lost. Press Cancel to return to the
      page or continue to proceed without saving
    </div>
    <template slot="footer">
      <base-button
        type="secondary"
        class="ml-auto"
        @click="cancel"
        >Cancel
      </base-button>
      <base-button
        type="primary"
        class="pallet-theme-btn"
        @click="continueRedirect"
      >
        Continue
      </base-button>
    </template>
  </modal>
</template>
<script>
import Modal from "@/components/Modal.vue";
import BaseButton from "@/components/BaseButton";

export default {
  components: {
    Modal,
    BaseButton,
  },
  props: {
    confirmModal: Boolean,
  },
  methods: {
    /**
     * Handles the continue action and redirects the user to the previous page using Vue Router's back() method.
     */
    continueRedirect() {
      this.$router.back();
    },
    /**
     * Emits the 'cancel' event to notify the parent component about the cancellation.
     */
    cancel() {
      this.$emit('cancel');
    },
  }
}
</script>