<template>
  <div class="wrapper">
    <div class="body-container row">
      <loader :isLoader="showLoader"></loader>
      <left-panel :activeMenuName="activeMenuName"></left-panel>
      <div class="col-lg-10 right-panel-scroll plr-30 mt-4">
        <div class="row right-panel-header">
          <div class="col-md-4">
            <search-bar
            />
          </div>
          <div class="col-md-4">
          </div>
          <div class="col-md-4">
            <profile-header/>
          </div>
        </div>

        <page-heading-card
          title="View & Compare Quotes"
          subTitle=""
          buttonName="See all saved Quotes"
          imageName="create-quote.png"
          addRecordScreenName="quotes"
        />

        <div class="mt-5 mb-4 section-b">


        <div class="row">
          <div class="padding-right col-md-8">
            <shipment-detail-filters
              :filterData="mergeQuoteList(bestQuotes, otherQuotes)"
              :showCarrierDropdown="showCarrierDropdown"
              :showBuyRateDropdown="showBuyRateDropdown"
              :showMarginDropdown="showMarginDropdown"
              :isSearchQuote="true"
              :dbFilterData="mergeQuoteList(bestOldQuotes, otherOldQuotes)"
              @search-quote="setFilteredQuotes"
              @filter-quote="setFilteredQuotes"
            />

            <div class="top-bottom-table" v-if="ratesLength > 15">
              <quotes-result-table
                tableTitle="Pinned Quotes"
                tableName="pin-quotes"
                :tableData="pinQuotes"
                :selectedTab="pinSelectedTab"
                @handle-margin="handleMargin"
                @quote-selection="selectQuote"
                @pin-unpin-quote="removePinQuoteHandler"
              />
            </div>

            <div class="top-bottom-table">
              <quotes-result-table
                tableTitle="Best Quotes"
                tableName="best-quotes"
                :tableData="bestQuotes"
                :selectedTab="bestSelectedTab"
                @handle-margin="handleMargin"
                @quote-selection="selectQuote"
                @pin-unpin-quote="pinQuoteHandler"
              />
            </div>
            <div class="lane-result-m">
              <div v-if="bestLaneLoader" class="lane-result-loader"></div>
              <p v-if="bestLaneLoader" class="loading-text">&nbsp;Loading more data...</p>
            </div>

            <div v-if="missingReasons.length > 0" class="mb-1 mt-2">
              <a class="carrier-reasoning-link" @click="showModal">
                Is a carrier missing for this lane you think should be here?
                Click here to find out why it is not appearing.
              </a>
            </div>

            <div class="mb-3">
              <quotes-result-table
                tableTitle="Other Quote"
                tableName="other-quotes"
                :tableData="otherQuotes"
                :selectedTab="otherSelectedTab"
                @handle-margin="handleMargin"
                @quote-selection="selectQuote"
                @pin-unpin-quote="pinQuoteHandler"
                :isLoader="otherLaneLoader"
              />
            </div>
            <div class="lane-result-m">
              <div v-if="otherLaneLoader" class="lane-result-loader"></div>
              <p v-if="otherLaneLoader" class="loading-text">&nbsp;Loading more data...</p>
            </div>
          </div>

          <div
            class="col-md-4 shipment-details-desktop"
          >
            <shipment-detail-summary
              :selectedQuote="selectedQuote"
              @show-confirm-modal="showConfirmModal"
              @quote-detail="quoteDetail"
              @save-new-quote-data="newQuoteData"
              @update-quote="updateQuote"
              :disableSaveQuoteBtn="disableSaveQuoteBtn"
              @show-loader="enableLoader"
            />
          </div>
          <div class="shipment-details-mobile">
            <button
              class="navbar-toggler navbar-dark shipment-toggle-btn pallet-theme-btn"
              type="button"
              v-b-toggle.sidebar-shipment
            >
              Show Shipment Summary
            </button>

            <b-sidebar
              id="sidebar-shipment"
              :right="true"
              bg-variant="white"
              backdrop
              shadow
            >
              <shipment-detail-summary
                :selectedQuote="selectedQuote"
                @show-confirm-modal="showConfirmModal"
                @quote-detail="quoteDetail"
                @save-new-quote-data="newQuoteData"
                :disableSaveQuoteBtn="disableSaveQuoteBtn"
              />
            </b-sidebar>
          </div>
        </div>
        </div>
      </div>
      <modal
        :show.sync="confirmQuoteModal"
        modal-classes="modal-dialog-centered confrim-quote-modal"
      >
        <h5
          slot="header"
          class="modal-title confrim-quote-header"
          id="modal-title-default"
        >
          Quote successfully saved
        </h5>

        <div>
          <div class="additional-option">Additional options</div>
          <base-radio
            v-if="hideSendToClientOption"
            name="sendToClient"
            class="mb-3"
            v-model="additionalOptions"
            @input="(event) => selectAdditionalOptions()"
          >Send to Client
          </base-radio
          >
          <base-radio
            name="sendToTMS"
            class="mb-3"
            v-model="additionalOptions"
            @input="(event) => selectAdditionalOptions()"
          >Send to TMS
          </base-radio
          >
        </div>

        <template slot="footer">
          <base-button
            v-if="enableSendBtn"
            type="primary"
            class="pallet-theme-btn"
            @click="confirmQuote"
          >
            Send
          </base-button>
          <base-button
            v-else
            type="primary"
            class="pallet-theme-btn"
            @click="showQuoteHomeScreen"
          >
            Continue
          </base-button>
          <base-button
            type="secondary"
            class="ml-auto"
            @click="showQuoteHomeScreen"
          >Close
          </base-button>
        </template>
      </modal>
      <carrier-reasoning
        ref="carrierReasoningModal"
        :missingReasons="missingReasons"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LeftPanel from "@/layout/LeftPanel";
import Loader from "@/layout/Loader";
import SearchBar from "@/views/components/SearchBar";
import ProfileHeader from "@/layout/ProfileHeader";
import PageHeadingCard from "@/views/components/PageHeadingCard";
import ShipmentDetailFilters from "@/views/quotes/ShipmentDetailFilters.vue";
import QuotesResultTable from "@/views/quotes/QuotesResultTable.vue";
import CarrierReasoning from "@/views/quotes/CarrierReasoning.vue";
import ShipmentDetailSummary from "@/views/quotes/ShipmentDetailSummary.vue";
import { BSidebar } from "bootstrap-vue/esm/components/sidebar/sidebar";
import Modal from "@/components/Modal.vue";
import { checkEmptyValue } from "@/helpers/utility";
import { API, masterAPI } from "@/api/API";

export default {
  components: {
    LeftPanel,
    Loader,
    SearchBar,
    ProfileHeader,
    PageHeadingCard,
    ShipmentDetailFilters,
    QuotesResultTable,
    BSidebar,
    Modal,
    ShipmentDetailSummary,
    CarrierReasoning
  },
  data: function() {
    return {
      // Component's data properties
      activeMenuName: "Quotes",
      confirmQuoteModal: false,
      additionalOptions: "",
      enableSendBtn: false,
      showLoader: false,
      selected: [],
      bestQuotes: [],
      bestOldQuotes: [],
      otherQuotes: [],
      otherOldQuotes: [],
      pinQuotes: [],
      accessorialsCheckArray: [],
      mainMargin: "",
      pinSelectedTab: false,
      bestSelectedTab: false,
      otherSelectedTab: false,
      isMarginDollar: false,
      searchTabList: [
        {
          tabIndex: 0,
          active: true
        }
      ],
      activeTabIndex: 0,
      newQuotePayload: {},
      disableSaveQuoteBtn: true,
      selectedQuote: [],
      quoteIds: [],
      sendToClientEmail: "",
      ratesLength: 0,
      showCarrierDropdown: true,
      showBuyRateDropdown: true,
      showMarginDropdown: true,
      hideSendToClientOption: false,
      missingReasons: [],
      bestLaneLoader: false,
      otherLaneLoader: false
    };
  },
  computed: {
    ...mapGetters(["userIsBroker"]),
  },
  methods: {
    /**
     * Method to handle new quote data
     * @param {Object} item - New quote item
     */
    newQuoteData (item) {
      this.enableSendBtn = false;
      this.quoteIds = [];
      this.showLoader = true;
      try {
        let saveQuoteDetail = this.$session.get("save_quote_details");
        let quoteDetail = JSON.parse(saveQuoteDetail);
        this.selectedQuote.forEach(async (quoteData) => {
          item.details.rates = [quoteData.rates];
          item.details.origin = quoteDetail.response.origin;
          item.details.destination = quoteDetail.response.destination;
          let res = await masterAPI(
            API.API_ENDPOINT.quote,
            API.API_METHOD.post,
            undefined,
            item
          );

          this.quoteIds.push(res.data.id);
        });
      } catch (error) {
        console.log(error);
        this.$toast.error("Something went wrong", {
          timeout: 1000
        });
        this.hideScreenLoader();
      } finally {
        this.hideScreenLoader();
        this.$toast.success("Quote create successful", {
          timeout: 1000
        });

        this.selectedQuote.map((item) => {
          item.select = false;
        });
        this.disableSaveQuoteBtn = true;
        let vm = this;
        setTimeout(function() {
          vm.confirmQuoteModal = true;
        }, 310);

        if (this.userIsBroker) {
          this.sendToClientEmail = item.details.contact.email;
          this.getIndividualClient(item.clientId);
        }
      }
    },
    /**
     * Updates the quote data by fetching the latest details.
    */
    updateQuote () {
      // Call the method to retrieve the quote request by ID
      this.getQuoteRequestById();

      // let saveQuoteDetail = this.$session.get("save_quote_details");
      // this.getRates(saveQuoteDetail);

      // const jsonQuote = JSON.parse(saveQuoteDetail);
      // const that = this;
      // const quoteRequest = jsonQuote.response.quoteRequest;
      // if (quoteRequest) {
      //   if (jsonQuote.response.rates.length < 3) this.bestLaneLoader = true;
      //   this.otherLaneLoader = true;
      //   setTimeout(async () => {
      //     let response = await masterAPI(
      //       `${API.API_ENDPOINT.getQuoteRequestById}/${quoteRequest.id}`,
      //       API.API_METHOD.get
      //     );
      //     if (response.status == 200) {
      //       let quoteDetail = jsonQuote;
      //       quoteDetail["response"] = response.data;
      //       that.$session.set(
      //         "save_quote_details",
      //         JSON.stringify(quoteDetail)
      //       );
      //       that.getRates(JSON.stringify(quoteDetail));
      //       this.bestLaneLoader = false;
      //       this.otherLaneLoader = false;
      //     }
      //   }, 10000);
      // } else {
      //   this.bestLaneLoader = false;
      // }
    },
    /**
     * Method to get individual client
     * @param {number} id - Client ID
     */
    async getIndividualClient (id) {
      let response = await masterAPI(
        API.API_ENDPOINT.client,
        API.API_METHOD.get,
        id,
        undefined
      );

      if (response.status == 200) {
        let clientData = response.data;

        let bodyPayload = JSON.stringify({
          name: clientData.name,
          margin: clientData.margin,
          contacts: clientData.contacts
        });

        await masterAPI(
          API.API_ENDPOINT.client,
          API.API_METHOD.put,
          id,
          bodyPayload
        );
      }
    },
    /**
     * Method to confirm a quote
     */
    async confirmQuote () {
      if (this.additionalOptions == "sendToClient") {
        this.showLoader = true;
        try {
          this.quoteIds.forEach(async (quoteId) => {
            let endPint =
              API.API_ENDPOINT.quote +
              "/" +
              quoteId +
              API.API_ENDPOINT.shareQuote;

            let shareQuotePayload = JSON.stringify({
              email: this.sendToClientEmail,
              method: "email"
            });

            await masterAPI(
              endPint,
              API.API_METHOD.post,
              undefined,
              shareQuotePayload
            );
          });
        } catch (error) {
          console.log(error);
          this.hideScreenLoader();
          this.$toast.error("Something went wrong", {
            timeout: 1000
          });
        } finally {
          let vm = this;
          setTimeout(function() {
            vm.hideScreenLoader();
            vm.$toast.success("Send to client successful", {
              timeout: 1000
            });
            vm.showQuoteHomeScreen();
          }, 1000);
        }
      }

      if (this.additionalOptions == "sendToTMS") {
        this.showLoader = true;
        try {
          this.quoteIds.forEach(async (quoteId) => {
            let endPint =
              API.API_ENDPOINT.quote +
              "/" +
              quoteId +
              API.API_ENDPOINT.sendQuoteToTms;

            await masterAPI(endPint, API.API_METHOD.post, undefined, undefined);
          });
        } catch (error) {
          console.log(error);
          this.hideScreenLoader();
          this.$toast.error("Something went wrong", {
            timeout: 1000
          });
        } finally {
          let vm = this;
          setTimeout(function() {
            vm.hideScreenLoader();
            vm.$toast.success("Send to TMS successful", {
              timeout: 1000
            });
            vm.showQuoteHomeScreen();
          }, 1000);
        }
      }
    },
    /**
     * Method to select additional options
     */
    selectAdditionalOptions () {
      this.enableSendBtn = true;
    },
    /**
     * Method to hide screen loader
     */
    hideScreenLoader () {
      let vm = this;
      setTimeout(function() {
        vm.showLoader = false;
      }, 300);
    },
    /**
     * Method to show confirm modal
     */
    showConfirmModal () {
      this.confirmQuoteModal = true;
    },
    /**
     * Method to fetch quote details
     * @param {Object} item - Quote item
     */
    quoteDetail (item) {
      this.getRates(item);
    },
    /**
     * Method to enable loader
     * @param {boolean} value - Loader boolean value true or false
     */
    enableLoader (value) {
      this.showLoader = value;
    },
    /**
     * Method to handle pin quote
     * @param {Object} selectedItem - Selected quote item
     */
    pinQuoteHandler (selectedItem) {
      if (this.ratesLength > 15) {
        if (selectedItem.isBestQuotes) {
          let selectedIndex = this.bestQuotes.map((item) => item.id).indexOf(selectedItem.id);
          this.bestQuotes.splice(selectedIndex, 1);
          this.bestOldQuotes.splice(selectedIndex, 1);
        } else {
          let selectedIndex = this.otherQuotes.map((item) => item.id).indexOf(selectedItem.id);
          this.otherQuotes.splice(selectedIndex, 1);
        }

        let currentMargin = selectedItem.rates.marginCost;
        if (this.pinSelectedTab) {
          selectedItem.margin = currentMargin.dollarValue;
        } else {
          selectedItem.margin = currentMargin.percentageValue;
        }
        selectedItem.select = false;
        this.pinQuotes.push(selectedItem);
        this.selectQuote();
      }
    },
    /**
     * Method to remove pin quote
     * @param {Object} selectedItem - Selected quote item
     */
    removePinQuoteHandler (selectedItem) {
      let selectedIndex = this.pinQuotes.map((item) => item.id).indexOf(selectedItem.id);
      this.pinQuotes.splice(selectedIndex, 1);

      if (selectedItem.isBestQuotes) {
        let currentMargin = selectedItem.rates.marginCost;

        if (this.bestSelectedTab) {
          selectedItem.margin = currentMargin.dollarValue;
        } else {
          selectedItem.margin = currentMargin.percentageValue;
        }
        selectedItem.select = false;
        this.bestQuotes.push(selectedItem);
        this.bestOldQuotes.push(selectedItem);
        this.selectQuote();
      } else {
        let currentMargin = selectedItem.rates.marginCost;

        if (this.otherSelectedTab) {
          selectedItem.margin = currentMargin.dollarValue;
        } else {
          selectedItem.margin = currentMargin.percentageValue;
        }
        selectedItem.select = false;
        this.selectQuote();
        this.otherQuotes.push(selectedItem);
      }
    },
    /**
     * Method to select a quote
     */
    selectQuote () {
      let mergeArray = [
        ...this.bestQuotes,
        ...this.pinQuotes,
        ...this.otherQuotes
      ];
      let vm = this;
      setTimeout(function() {
        let selectedQuote = mergeArray.map((item) => {
          if (item.select) {
            return item;
          }
        }).filter(function(element) {
          return element !== undefined;
        });

        if (selectedQuote.length > 0) {
          vm.selectedQuote = selectedQuote;
          vm.disableSaveQuoteBtn = false;
        } else {
          vm.selectedQuote = [];
          vm.disableSaveQuoteBtn = true;
        }
      }, 100);
    },
    /**
     * Method to get rates for a quote
     * @param {Object} saveQuoteDetail - Quote details
     */
    getRates (saveQuoteDetail) {
      this.bestQuotes = [];
      this.bestOldQuotes = [];
      this.pinQuotes = [];
      this.otherQuotes = [];
      this.otherOldQuotes = [];
      this.missingReasons = [];
      this.resetFilterQuoteData();

      if (saveQuoteDetail) {
        let quoteDetail = JSON.parse(saveQuoteDetail);
        let rate = quoteDetail.response.rates;
        this.ratesLength = rate.length;
        this.isMarginDollar = quoteDetail.marginDollar;

        for (let i = 0; i < rate.length; i++) {
          let currentMargin = rate[i].marginCost;

          let margin =
            currentMargin.type == "fixed"
              ? currentMargin.dollarValue
              : currentMargin.percentageValue;

          let fuelSurcharge = rate[i].additionalsCost.additionals;
          let accessorialsCost = rate[i].accessorialsCost;

          let totalCost = rate[i].totalCost;
          let carrierName = rate[i].lane.carrier.name;
          let carrierRate = rate[i].totalCostBeforeMargin;
          let publicRate = rate[i].public || false;

          let rateObj = {
            name: i,
            carrier: carrierName,
            serviceLevel: "---",
            transitTime: "---",
            carrierRate: carrierRate,
            quotedRate: totalCost ? checkEmptyValue(totalCost) : totalCost,
            baseCost: rate[i].baseCost,
            margin: margin,
            id: i,
            isBestQuotes: i < 3 ? true : false,
            rates: rate[i],
            currentMargin: currentMargin,
            fuelSurcharge: fuelSurcharge,
            accessorialsCost: accessorialsCost,
            subTotal: rate[i].totalCostBeforeMargin,
            total: totalCost ? checkEmptyValue(totalCost) : totalCost,
            select: false,
            baseRate: rate[i].baseRate,
            currencyType: quoteDetail.clientName ? quoteDetail.clientName.currencyType : "",
            carrierName: carrierName,
            buyRate: carrierRate,
            publicRate
          };

          if (i < 3) {
            this.bestQuotes.push(rateObj);
            this.bestOldQuotes.push(rateObj);
          } else {
            this.otherQuotes.push(rateObj);
            this.otherOldQuotes.push(rateObj);
          }

          this.mainMargin = margin;
          this.pinSelectedTab = quoteDetail.marginDollar;
          this.bestSelectedTab = quoteDetail.marginDollar;
          this.otherSelectedTab = quoteDetail.marginDollar;

          if (!carrierName) {
            this.showCarrierDropdown = false;
          }

          if (!carrierRate) {
            this.showBuyRateDropdown = false;
          }

          if (!margin) {
            if (margin != 0) {
              this.showMarginDropdown = false;
            }
          }
        }

        let mergeArray = [
          ...quoteDetail.pickupAccessorials,
          ...quoteDetail.deliveryAccessorials,
          ...quoteDetail.otherAccessorials
        ];
        this.accessorialsCheckArray = mergeArray;

        if (quoteDetail.response.missingReasons) {
          this.missingReasons = quoteDetail.response.missingReasons;
        }
      }
    },
    /**
     * Updates the margin values for the quote records based on the selected options.
     * @param {boolean} isDollar - Indicates whether the margin should be in dollar value.
     * @param {string} isTable - Specifies the table type ('best-quotes', 'pin-quotes', or 'other-quotes').
     */
    handleMargin (isDollar, isTable) {
      let quoteRecord = [];
      if (isTable == "best-quotes") {
        quoteRecord = this.bestQuotes;
        if (isDollar) {
          this.bestSelectedTab = true;
        } else {
          this.bestSelectedTab = false;
        }
      } else if (isTable == "pin-quotes") {
        quoteRecord = this.pinQuotes;
        if (isDollar) {
          this.pinSelectedTab = true;
        } else {
          this.pinSelectedTab = false;
        }
      } else if (isTable == "other-quotes") {
        quoteRecord = this.otherQuotes;
        if (isDollar) {
          this.otherSelectedTab = true;
        } else {
          this.otherSelectedTab = false;
        }
      }

      for (let i = 0; i < quoteRecord.length; i++) {
        let currentMargin = quoteRecord[i].rates.marginCost;
        let margin = isDollar
          ? currentMargin.dollarValue
          : currentMargin.percentageValue;
        quoteRecord[i].margin = margin;
      }
    },
    /**
     * Resets the filter quote data by clearing the selected options.
     */
    resetFilterQuoteData () {
      this.selectedCarrier = null;
      this.selectedServiceLevel = null;
      this.selectedTransitTime = null;
      this.selectedBuyRate = null;
      this.selectedMargin = null;
    },
    /**
     * Adds a new search tab to the search tab list.
     */
    addSearchTab () {
      let tabList = this.searchTabList;
      let tabIndex = 0;
      let lastIndex = tabList[tabList.length - 1];
      if (lastIndex) {
        tabIndex = tabList[tabList.length - 1].tabIndex;
      }
      this.deactivateTabs();
      let addSearchTabObj = {
        tabIndex: lastIndex ? tabIndex + 1 : 0,
        active: true
      };
      this.activeTabIndex = lastIndex ? tabIndex + 1 : 0;
      this.searchTabList.push(addSearchTabObj);
    },
    /**
     * Removes the search tab at the specified index from the search tab list.
     * @param {number} index - The index of the search tab to be removed.
     */
    removeTab (index) {
      this.searchTabList.splice(index, 1);
      let lastIndex = index - 1;
      this.activeTabIndex = lastIndex;
      if (this.searchTabList[lastIndex]) {
        this.searchTabList[lastIndex].active = true;
      }
    },
    /**
     * Activates the specified tab and deactivates all other tabs.
     * @param {Object} tab - The tab object to be activated.
     */
    activateTab (tab) {
      this.deactivateTabs();
      tab.active = true;
      this.activeTabIndex = tab.tabIndex;
    },
    /**
     * Deactivates all tabs in the search tab list.
     */
    deactivateTabs () {
      this.searchTabList.forEach((tab) => {
        tab.active = false;
      });
    },
    /**
     * Shows the quote home screen by removing the saved quote details and navigating to the "quotes" route.
     */
    showQuoteHomeScreen () {
      this.$session.remove("save_quote_details");
      this.confirmQuoteModal = false;
      this.$router.push({ name: "quotes" });
    },
    /**
     * Shows the carrier reasoning modal.
     */
    showModal () {
      this.$refs.carrierReasoningModal.openModal();
    },
    /**
     * Retrieves a quote request by its ID and updates the component accordingly.
    */
    getQuoteRequestById() {
      let saveQuoteDetail = this.$session.get("save_quote_details");
      this.getRates(saveQuoteDetail);

      // Disable polling API by commenting out the following below code.
      const jsonQuote = JSON.parse(saveQuoteDetail);
      this.otherLaneLoader = false;
      this.bestLaneLoader = false;
      const that = this;
      const quoteRequest = jsonQuote.response.quoteRequest;

      // Check if quote request data exists
      if (quoteRequest) {
        // Show loaders while waiting for response
        if (jsonQuote.response.rates.length < 3) this.bestLaneLoader = true;
        this.otherLaneLoader = true;

        setTimeout(async () => {
          // Fetch quote request by ID
          let response = await masterAPI(
            `${API.API_ENDPOINT.getQuoteRequestById}/${quoteRequest.id}`,
            API.API_METHOD.get
          );

          // Hide loaders after receiving response
          this.bestLaneLoader = false;
          this.otherLaneLoader = false;

          if (response.status == 200) {
            let quoteDetail = jsonQuote;
            quoteDetail["response"] = response.data;
            that.$session.set(
              "save_quote_details",
              JSON.stringify(quoteDetail)
            );
            that.getRates(JSON.stringify(quoteDetail));
          }
        }, 20000);
      } else {
        this.bestLaneLoader = false;
      }
    },
    /**
     * Combines two arrays of quote lists into one.
     *
     * @param {Array} bestQuoteList - Array containing the best quotes.
     * @param {Array} otherQuoteList - Array containing other quotes.
     * @returns {Array} - Merged array of all quotes.
    */
    mergeQuoteList(bestQuoteList, otherQuoteList) {
      return [...bestQuoteList, ...otherQuoteList];
    },
    /**
     * Filters the provided quote list into best quotes and other quotes.
     *
     * @param {Array} quoteList - Array of quote objects to be filtered.
     */
    setFilteredQuotes(quoteList) {
      this.bestQuotes = quoteList.filter(quote => quote.isBestQuotes);
      this.otherQuotes = quoteList.filter(quote => !quote.isBestQuotes);
    }
  },
  created() {
    this.getQuoteRequestById();
  }
};
</script>

<style>
.create-new-quote-page {
    margin-top: 10px;
    margin-bottom: 20px;
}

.new-quote-card .card-body {
    padding: 10px;
}

.new-quote-card .form-group {
    margin-bottom: 0rem;
}

.quote-card-header-title {
    font-weight: 700;
    font-size: 18px;
    line-height: 10px;
    color: #000000;
}

.accessorials-title {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 27px;
    color: #6F767E;
}

.accessorials-title-blank {
    margin-bottom: 2.6rem !important;
}

.get-rate {
    text-align: right;
    margin-bottom: 15px;
}

.get-rate-btn {
    background-color: #f0c03d !important;
    border: 1px solid #f0c03d !important;
    border-radius: 4px !important;
}

.add-additional-piece {
    background-color: #004225 !important;
    border: 1px solid #004225 !important;
    border-radius: 4px !important;
    color: #fff !important;
}

.add-Accessorials-piece {
    padding: 1px 6px !important;
    background-color: #004225 !important;
    border: 1px solid #004225 !important;
    border-radius: 4px !important;
    color: #fff !important;
}

.add-item-btn {
    padding: 1px 6px !important;
    background-color: #004225 !important;
    border: 1px solid #004225 !important;
    border-radius: 4px !important;
    color: #fff !important;
    font-size: 10px !important;
    height: 24px;
}

.shipment-detail-footer .fa {
    padding-left: 7px;
}

.next-btn {
    text-align: right;
    margin-bottom: 15px;
    margin-top: 15px;
}

.next-btn button {
    background-color: #f0c03d !important;
    border: 1px solid #f0c03d !important;
    border-radius: 4px !important;
}

.text-right {
    text-align: right;
}

.shipment-filter-view-quotes {
    margin-right: 0px !important;
    margin-left: 0px !important;
}

.shipment-filter-view-quotes .choices {
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 10px;
}

.adjust-shipment-details {
    font-weight: 600;
    font-size: 16px;
    color: #000000;
    margin-bottom: 10px;
    margin-top: 15px;
}

.pin-quote-tab {
    margin-bottom: 21px;
}

.pin-quote-tab .nav {
    padding-left: 0px !important;
    padding-right: 15px !important;
}

.pin-quote-tab .nav.btn-group li {
    flex: 1 1 auto;
    padding: 0;
    text-align: center;
}

.pin-quote-tab .nav.btn-group li a {
    margin: 0;
    box-shadow: none;
    border: 1px solid transparent;
}

.pin-quote-tab .nav.btn-group li:first-child a {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.pin-quote-tab .nav.btn-group li:last-child a {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.tab-space {
    padding: 0px !important;
}

.pin-quote-tab
.nav-pills.nav-pills:not(.flex-column)
.nav-item:not(:last-child)
.nav-link {
    margin-bottom: 0px !important;
}

.pin-quote-tab .nav {
    padding-left: 0px !important;
    padding-right: 15px !important;
}

.top-bottom-table {
    margin-top: 23px;
    margin-bottom: 23px;
}

.padding-right {
    padding-right: 0px !important;
}

.margin-top-bottom {
    margin-bottom: 20px;
    margin-top: 20px;
}

.accessorials-dropdown .nav-link::after {
    display: none !important;
}

.accessorials-dropdown-list {
    padding-left: 15px;
}

.accessorials-done-btn {
    text-align: center;
    margin-bottom: 15px;
    margin-top: 15px;
}

.closebtn {
    padding-left: 10px;
    font-weight: bold;
    float: right;
    font-size: 15px;
    cursor: pointer;
}

.chip {
    display: inline-block;
    padding-top: 0px;
    padding-right: 13px;
    padding-bottom: 0px;
    padding-left: 19px;
    height: 25px;
    line-height: 25px;
    border-radius: 25px;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    margin-left: 4px;
    color: white;
    margin-bottom: 5px;
}

.item-toggle {
    cursor: pointer;
    margin-right: 15px;
}

.dimensions-title {
    margin-bottom: 13px !important;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
}

.delete-item-icon {
    cursor: pointer;
    margin-left: 10px;
}

.shipment-details-summary-title {
    font-size: 17px;
    font-weight: 500;
    line-height: 16px;
    text-align: left;
    color: #222222;
}

.calculate-view .fa-times {
    font-size: 10px;
    margin-left: 5px;
    margin-right: 0px;
}

.last-height {
    margin-left: -5px;
}

.accessorials-dropdown .dropdown.show .dropdown-menu.show {
    animation: none !important;
}

.pin-quote-input .form-control {
    height: calc(1em + 1rem + 2px) !important;
    border-color: #004225 !important;
    border: 1px solid #004225;
}

.nav-pills.nav-pills-info .nav-item .nav-link {
    color: #004225 !important;
}

.nav-pills .nav-link {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

.update-btn-text {
    padding: 0.625rem 0.7rem !important;
}

.shipment-calculation-text {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #000000;
}

.lwh-text {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.6);
}

.length-text {
    position: relative;
    top: 15px;
    right: 27px;
}

.width-text {
    position: relative;
    top: 15px;
    right: 30px;
}

.height-text {
    position: relative;
    top: 15px;
    right: 16px;
}

.shipment-filter-space {
    padding-right: 0px !important;
}

.disable-shadow {
    background: rgba(217, 217, 217, 0.5);
    opacity: 0.3;
}

.additional-option {
    margin-bottom: 15px;
    color: #000000;
}

.confrim-quote-header {
    text-align: center;
    width: 100%;
    color: #000000;
}

.confrim-quote-modal .close {
    display: none;
}

.confrim-quote-modal .modal-footer {
    text-align: center;
    display: block;
}

.add-new-tab {
    cursor: pointer;
    position: absolute;
    right: 20px;
    z-index: 100;
}

.add-new-tab p {
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    color: #004225;
}

.custom-search-tab .nav-link {
    background-color: white !important;
    border: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: 1px solid #000000 !important;
    border-right: 1px solid #000000 !important;
    border-bottom: 0px !important;
    border-radius: 0px !important;
    border-top-left-radius: 8px !important;
    box-shadow: none !important;
}

.quote-client-tab {
    margin-top: 3%;
}

.active-tab span {
    color: #004225 !important;
    font-weight: 700;
}

.in-active-tab span {
    color: #000000 !important;
}

.close-search-tab-icon {
    position: relative;
    top: -22px;
    left: 18px;
}

.other-option-table {
    margin-top: 23px;
    margin-bottom: 23px;
}

.custom-search-tab {
    margin-bottom: 21px;
    padding-left: 0px !important;
}

.custom-search-tab ul {
    padding-left: 0px;
}

.custom-search-tab li {
    margin-bottom: 15px;
    cursor: pointer;
}
</style>
