<template>
  <div>
    <canvas id="myChart"></canvas>
  </div>
</template>

<script>
import Chart from 'chart.js';

export default {
  mounted() {
    this.renderChart();
  },
  methods: {
    renderChart() {
      const ctx = document.getElementById('myChart').getContext('2d');
      new Chart(ctx, {
        type: 'doughnut',
        data: {
          labels: ['Inactive', 'Under review', 'Saved', "Accepted"],
          datasets: [{
            label: '# of Votes',
            data: [20,10, 12, 7],
            backgroundColor: [
              'rgba(228, 228, 228, 1)',
              'rgba(255, 117, 76, 1)',
              'rgba(36, 135, 110, 1)',
              'rgba(255, 215, 94, 1)',
            ],
            borderColor: [
              'rgba(228, 228, 228, 1)',
              'rgba(255, 117, 76, 1)',
              'rgba(36, 135, 110, 1)',
              'rgba(255, 215, 94, 1)',
            ],
            borderWidth: 1
          }]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false // Hide the legend
          }
        }
      });
    }
  }
};
</script>

<style scoped>
/* Add any custom styles here */
</style>
