<template>
<div>
  <div class="carrier-contact-table mt-5">
    <div class="row">
      <div class="col-md-4">
        <span class="v-data-table-title">
          {{ carrierName }} Tariffs
        </span>
      </div>
      <div class="col-md-6">
        <div class="autocomplete-search-div">
          <img class="autocomplete-search-icon" src="../../assets/img/search.png">
          <vue-basic-autocomplete
            trackby="name"
            input-class="form-control autocomplete-search-input"
            placeholder="Search your Carriers, Tariff sheets, and all Lanes"
            :clear-btn="false"
            none-find="Item not found"
            :options="tarrifData"
            v-model="searchTarrif"
            @selected="(event) => selectedTarrifItem(event)"
          />
        </div>
      </div>
      <div class="col-md-2 tariff-dropdown">
        <choices-single 
          :options="filterList" 
        >
          <option value="0">Select</option>
        </choices-single>
      </div>
    </div>
    
    <div
      v-for="(item, index) in tarrifData"
      :key="index"
      class="row mb-2 mt-3"
    >
      <div class="col-md-3">
        <base-input
          type="text"
          class="input-v1"
          label="Tariff name"
          name="Tariff Name"
          rules="required"
          v-model="item.tariffName"
        >
        </base-input>
      </div>
      <div class="col-md-2">
        <base-input
          type="text"
          class="input-v1"
          label="Last update"
        >
          <flat-picker
            :config="{ dateFormat: 'M J, Y' }"
            class="form-control datepicker"
            v-model="item.lastUpdated"
          >
          </flat-picker>
        </base-input>
      </div>
      <div class="col-md-2 form-group has-label tariff-active">
        <label>Tariff active</label>
        <choices-single
          class="quantity-select-dropdown"
          :options="filterList"
          v-model="selectedStatusTariff"
        >
          <option value="0">Select</option>
        </choices-single>
      </div>
      <div class="col-md-2">
        <base-input
          type="text"
          class="input-v1"
          label="Valid from"
        >
          <flat-picker
            :config="{ dateFormat: 'M J, Y' }"
            class="form-control datepicker"
            v-model="item.startDate"
          >
          </flat-picker>
        </base-input>
      </div>
      <div class="col-md-2">
        <base-input
          type="text"
          class="input-v1"
          label="Valid To"
        >
          <flat-picker
            :config="{ dateFormat: 'M J, Y' }"
            class="form-control datepicker"
            v-model="item.endDate"
          >
          </flat-picker>
        </base-input>
      </div>
      <div class="col-md-1 ipad-pl">
        <base-button
          type="primary"
          class="btn-theme-EFEBEB action-round"
          @click="removeTariff(index)"
        >
          <img src="../../assets/img/home/trash.png"/>
        </base-button>
      </div>
    </div>
  </div>

  <base-button
    type="primary"
    class="btn-theme-24876E mb-1"
    v-on:click="addTariff"
  >
    <img src="../../assets/img/home/plus-round.png"/>
    Add additional tariff
  </base-button>

</div>
</template>
<script>

import BaseInput from "@/components/BaseInput";
import BaseButton from "@/components/BaseButton";
import ChoicesSingle from "@/components/SingleSelect";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

export default {
  components: {
    BaseInput,
    BaseButton,
    ChoicesSingle,
    flatPicker
  },
  props: {
    carrierName: String,
    tarrifData: Array,
  },
  data() {
    return {
      searchTarrif: "",
      filterList: [
        {
          id: 1,
          text: "Active",
        },
        {
          id: 2,
          text: "InActive",
        },
        {
          id: 3,
          text: "Expired",
        },
      ],
      selectedStatusTariff: "",
    };
  },
  methods: {
    selectedTarrifItem: function(item) {
      console.log(item);
    },
    addTariff: function() {
      let index = this.tarrifData.length;
      let tarrifObj = {
        select: false,
        tariffName: "",
        lastUpdated: "",
        tariffActive: "",
        startDate: "",
        endDate: "",
        isEdit: true,
        id: index + 1,
      };
      this.tarrifData.push(tarrifObj);
    },
    removeTariff: async function(index) {
      this.tarrifData.splice(index, 1);
    },
  },
};
</script>
