<template>
  <div>
    <loader :isLoader="showLoader"></loader>
    <modal
      :show.sync="inviteModal"
      modal-classes="modal-dialog-centered invite-modal"
    >
      <div>
        <div class="row mr-0">
          <div class="col-10 col-md-10">
            <p class="title">Invite by Email</p>
          </div>
          <div class="col-2 col-md-2">
            <button
              type="button"
              class="invite-close"
              @click="inviteModal = false"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span :aria-hidden="true">×</span>
            </button>
          </div>
        </div>
        
        <div class="invite-box">
          
          <div class="form-group has-label mb-0">
            <label>Email address</label>
            <div class="email-input-container">
              <span 
                v-for="(email, index) in emailList" :key="index" 
                class="email-pill"
              >
                <span>{{ email }}</span>
                <button @click="removeEmail(index)">&times;</button>
              </span>
              
              <input 
                type="text"
                v-model="emailInput" 
                @keydown.enter.prevent="addEmail"
                @keydown.space.prevent="addEmail"
                @keydown="handleKeyDown"
              >
            </div>
          </div>
          
          <span class="validate-error" v-if="invalidEmail">{{ emailErrorMsg }}</span>
          <div class="form-group has-label mb-0 mt-3">
            <label>Your message</label>
            <textarea
              class="form-control"
              placeholder="Add a message... (Optional but recommended) "
              rows="5"
              max-rows="5"
              v-model="message"
            ></textarea>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-9 col-md-10">
            <base-button
              type="primary"
              class="btn-theme-083C2F  btn-width-100"
              :disabled="disabledInviteBtn"
              v-on:click="inviteUsers"
            >
              Send Invitation
              <img src="../assets/img/home/send.svg" />
            </base-button>
          </div>
          <div class="col-3 col-md-2">
            <copy-invite-link-button/>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import Loader from "../layout/Loader";
import Modal from "@/components/Modal.vue";
import * as EmailValidator from 'email-validator';
import { API, masterAPI } from "@/api/API";
import { userTypes } from '@/helpers/utility';
import CopyInviteLinkButton from "@/views/components/CopyInviteLinkButton";

export default {
  components: {
    Loader,
    Modal,
    CopyInviteLinkButton
  },
  props: {
    show: {
      type: Boolean,
    },
  },
  data: function() {
    return {
      inviteModal: false,
      emailList: [],
      emailInput: '',
      message: "",
      disabledInviteBtn: true,
      emailErrorMsg:"",
      invalidEmail: false,
      usersList: [],
      showLoader: false,
    };
  },
  computed: {
    ...mapGetters([
      'userDetail',
      'allTeammatesUsers',
    ])
  },
  methods: {
    /**
     * Invite users by sending an invitation email.
    */
    async inviteUsers () {
      this.invalidEmail = false;
      this.emailErrorMsg = "";

      if (this.emailList.length === 0) {
        // Check if the email list is empty
        this.emailErrorMsg = "Please enter at least one email address";
        this.invalidEmail = true;
        return; 
      }

      this.disabledInviteBtn = true;
      this.showLoader = true;

      let bodyPayload = JSON.stringify({ 
        emails: this.emailList,
        organizationName: this.getAdminOrganizationName(),
        message: this.message,
      });

      let response = await masterAPI(
        API.API_ENDPOINT.inviteUser,
        API.API_METHOD.post,
        undefined,
        bodyPayload
      );

      this.disabledInviteBtn = false;
      this.showLoader = false;
      
      if (response.status == 200) {
        this.$toast.success("Invitation sent successfully", {
          timeout: 1000,
        });
        this.$emit("update-teammates");
        this.inviteModal = false;
        this.resetInviteForm();
      } else {
        let error = response.data.message.replaceAll('"', "");
        let existingEmails = response.data.details.existingEmails.join(', ');                
        let errorMessage = error + ' ' + existingEmails;
        this.$toast.error(errorMessage);        
      }
    },
    /**
     * Retrieves the organization name of the admin user.
     * @returns {string} The organization name of the admin user.
    */
    getAdminOrganizationName () {
      let adminUser = this.allTeammatesUsers.map((item) => {
        if (item.type == userTypes.ADMIN) {
          return item;
        }
      }).filter(function (element) {
        return element !== undefined;
      });
      return adminUser[0].organizationName;
    },
    /**
     * Opens the invite modal and resets the invite form.
    */
    openModal() {
      this.resetInviteForm();
      this.inviteModal = true;
    },
    /**
     * Resets the invite form to its initial state.
    */
    resetInviteForm() {
      this.message = "";
      this.disabledInviteBtn = true;
      this.emailErrorMsg = "";
      this.invalidEmail = false;
      this.emailInput = '';
      this.emailList = [];
    },
    /**
     * Handles the keydown event to add email addresses when a comma is pressed.
     * @param {Event} event - The keydown event.
    */
    handleKeyDown(event) {
      if (event.key === ',' || event.keyCode === 188) {
        this.addEmail();
        event.preventDefault();
      }
    },
    /**
     * Adds an email address to the email list after validation.
    */
    addEmail() {
      const emailAddress = this.emailInput.trim();
      this.invalidEmail = false;

      // Basic email format validation
      if (!emailAddress || !EmailValidator.validate(emailAddress)) {
        this.emailErrorMsg = "Please enter a valid email address";
        this.invalidEmail = true;
        return;
      }

      // Email domain validation
      let currentEmailDomain = this.userDetail.email.split('@')[1].trim();
      if (!emailAddress.includes(currentEmailDomain)) {
        this.emailErrorMsg = "Please ensure that you only invite members with same email domain.";
        this.invalidEmail = true;
        return;
      }

      // Check if the email is already in the list
      if (this.emailList.includes(emailAddress)) {
        this.emailErrorMsg = "Email is already in the list";
        this.invalidEmail = true;
        return;
      } else if (this.checkExistsEmail(emailAddress)) {
        this.emailErrorMsg = "This email has already been taken";
        this.invalidEmail = true;
        return;
      } else {
        this.emailList.push(emailAddress);
        this.disabledInviteBtn = false;
      }

      // Reset the email input field
      this.emailInput = '';
    },
    /**
     * Removes an email address from the email list.
     * @param {number} index - The index of the email to be removed.
    */
    removeEmail(index) {
      this.emailList.splice(index, 1);
    },
    /**
     * Fetch all users from the API and update the user list.
    */
    async getAllUsers () {
      this.users = [];
      let response = await masterAPI(
        API.API_ENDPOINT.allUsers,
        API.API_METHOD.get,
        undefined,
        undefined
      );
      if (response.status == 200) {
        this.usersList = response.data;
      }
    },
    /**
     * Checks if an email address already exists in the users list.
     * @param {string} email - The email address to check.
     * @returns {boolean} True if the email exists, otherwise false.
    */
    checkExistsEmail(email) {
      let existsEmail = this.usersList.map((item) => {
        if (item.email == email) {
          return item;
        }
      }).filter(function (element) {
        return element !== undefined;
      });
      return existsEmail.length > 0 ? true : false;
    }
  },
  created() {
    this.getAllUsers();
  },
};
</script>
<style></style>
