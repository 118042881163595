<template>
  <div class="wrapper">
    <div class="body-container row">
      <loader :isLoader="showLoader"></loader>
      <left-panel :activeMenuName="activeMenuName"></left-panel>
      <div class="col-lg-10 right-panel-scroll plr-30 mt-4">
        <div class="row right-panel-header">
          <div class="col-md-4">
            <search-bar
            />
          </div>
          <div class="col-md-4">
          </div>
          <div class="col-md-4">
            <profile-header/>
          </div>
        </div>

        <page-heading-card
          title="Clients"
          subTitle=""
          buttonName="Add client"
          imageName="client.png"
          addRecordScreenName="newclient"
        />

        <div class="mt-5 mb-5 section-b">
          <ValidationObserver v-slot="{ validate }" ref="formValidator">
          <form
            @submit.prevent="validate().then(updateClient)"
            class="needs-validation"
            id="create_client"
          >
            <div class="row align-items-center mb-4">
              <div class="col-md-10 headline-30">
                {{ clientName }}
              </div>
              <div class="col-md-2 text-right justify-content-end">
                <div class="edit-icon">
                  <base-button
                    nativeType="submit"
                    type="primary"
                    class="btn-theme-24876E"
                  >
                    Save
                  </base-button>
                </div>
              </div>
            </div>

            <div class="client-scrollbar">
              <add-edit-client-form
                :clientName="clientName"
                :dateAdded="dateAdded"
                :marginDollar="marginDollar"
                :margin="margin"
                :selectedCurrencyType="selectedCurrencyType"
                @update:props="handleUpdatedProps"
              />

              <client-contact-form
                :selected="selected"
                :contactData="contactData"
              />
            </div>

          </form>
        </ValidationObserver>
        </div>

        <div class="mt-5 mb-5 section-b">
          <div class="headline-30">
            Quotes for {{clientName}}
          </div>

          <div class="row d-flex align-items-center justify-content-center">
            <div class="col-md-3 mt-5">
              <search-quote
                :searchData="dbClientQuoteList"
                @search-quotes="searchQuotes"
              />
            </div>

            <div class="col-md-9">
              <shipment-detail-filters
                :filterData="clientQuoteList"
                :showCarrierDropdown="showCarrierDropdown"
                :showBuyRateDropdown="showBuyRateDropdown"
                :showMarginDropdown="showMarginDropdown"
                :isSearchQuote="false"
              />
            </div>
          </div>

          <quotes-table
            tableName=""
            :pagination="false"
            :tableData="clientQuoteList"
          />
        </div>
      </div>

      <confirm-modal
        :confirmModal="confirmModal"
        @cancel="handleCancel"
      ></confirm-modal>
    </div>
  </div>
</template>

<script>
import LeftPanel from "@/layout/LeftPanel";
import Loader from "@/layout/Loader";
import SearchBar from "@/views/components/SearchBar";
import ProfileHeader from "@/layout/ProfileHeader";
import PageHeadingCard from "@/views/components/PageHeadingCard";
import ClientContactForm from "./ClientContactForm.vue";
import SearchQuote from "@/views/quotes/SearchQuote.vue";
import ShipmentDetailFilters from "@/views/quotes/ShipmentDetailFilters.vue";
import ConfirmModal from "@/views/common/ConfirmModal.vue";
import QuotesTable from "@/views/quotes/QuotesTable.vue";
import moment from "moment";
import { API, masterAPI } from "@/api/API";
import { mapGetters } from "vuex";
import {
  firstCharCapitalize,
  checkEmptyValue,
  getQuoteNumber,
  getCreatedQuoteUserName,
} from "@/helpers/utility";
import { accessorials } from "@/helpers/accessorials";
import AddEditClientForm from "./AddEditClientForm.vue";

export default {
  bodyClass: "carriers",
  components: {
    LeftPanel,
    Loader,
    SearchBar,
    ProfileHeader,
    PageHeadingCard,
    QuotesTable,
    SearchQuote,
    ShipmentDetailFilters,
    ClientContactForm,
    ConfirmModal,
    AddEditClientForm,
  },
  props: {},
  data() {
    return {
      activeMenuName: "Client",
      clientName: "",
      dateAdded: "",
      margin: "",
      marginDollar: false,
      selected: [],
      contactData: [],
      dbClientName: "",
      dbMargin: "",
      dbContactData: [],
      confirmModal: false,
      clientQuoteList: [],
      dbClientQuoteList: [],
      individualClient: {},
      showLoader: false,
      selectedCurrencyType: null,
      selectedDbCurrencyType: null,
      searchQuote: "",
      showCarrierDropdown: true,
      showBuyRateDropdown: true,
      showMarginDropdown: true,
    };
  },
  computed: {
    clientId() {
      return +this.$route.params.id;
    },
    ...mapGetters(["allTeammatesUsers"]),
  },
  methods: {
    /**
     * Fetches individual client data from the API and updates the component state with the response
     */
    async getIndividualClient() {
      let response = await masterAPI(
        API.API_ENDPOINT.client,
        API.API_METHOD.get,
        this.clientId,
        undefined
      );

      if (response.status == 200) {
        // Update client name and date added
        this.clientName = response.data.name;
        this.dateAdded = moment(response.data.createdAt).format("MMM Do, YYYY");

        // Update currency type
        let currencyType = response.data.currencyType;
        this.selectedCurrencyType = currencyType;
        this.selectedDbCurrencyType = currencyType;

        // Update margin data
        let marginData = response.data.margin;
        if (marginData) {
          this.margin = marginData.value;
          if (marginData.type == "fixed") {
            this.marginDollar = true;
          } else {
            this.marginDollar = false;
          }
        }

        // Update contact data
        setTimeout(() => {
          document.querySelector(
            "#currencyType .choices__item"
          ).innerText = currencyType;
        }, 30);

        let contact = response.data.contacts;
        contact.map((item, index) => {
          let contactDetail = {
            select: false,
            name: item.name,
            email: item.email,
            phoneNumber: item.phoneNumber,
            note: item.note,
            isEdit: true,
            id: index,
            alreadySave: true,
          };
          this.contactData.push(contactDetail);
        });

        // Update database values
        this.dbClientName = this.clientName;
        this.dbMargin = this.margin;
        this.dbContactData = contact;
        this.individualClient = response.data;
      }

      this.getIndividualQuote();
    },

    /**
     * Updates the client information.
     */
    async updateClient() {
      // Perform form validation
      const isFormValid = await this.$refs.formValidator.validate();
      if (isFormValid) {
        let contacts = [];

        // Extract contact data from the map and add to the contacts array
        this.contactData.map((item) => {
          contacts.push({
            email: item.email,
            name: item.name,
            phoneNumber: item.phoneNumber,
            note: item.note,
          });
        });

        // Prepare the payload for the request
        let bodyPayload = JSON.stringify({
          name: this.clientName,
          margin: {
            type: this.marginDollar ? "fixed" : "percentage",
            value: this.margin,
          },
          currencyType: this.selectedCurrencyType,
          contacts: contacts,
        });

        // Send the update request to the server
        let response = await masterAPI(
          API.API_ENDPOINT.client,
          API.API_METHOD.put,
          this.clientId,
          bodyPayload
        );
        this.showLoader = false;
        if (response.status == 200) {
          // Update the isEdit property of each contact item
          this.contactData.forEach((item) => {
            item.isEdit = true;
          });
          // Show success message
          this.$toast.success("Client update successful", {
            timeout: 1000,
          });
        }
      }
    },

    /**
     * Checks if any data has been changed on the current page.
     * If changes have been made, prompts the user to confirm before redirecting.
     * Otherwise, redirects immediately.
     */
    checkChangeData() {
      // Check if any of the relevant properties have changed
      const isClientNameChanged = this.dbClientName !== this.clientName;
      const isMarginChanged = this.dbMargin !== this.margin;
      const isContactDataLengthChanged =
        this.dbContactData.length !== this.contactData.length;
      if (
        isClientNameChanged ||
        isMarginChanged ||
        isContactDataLengthChanged
      ) {
        // Prompt the user to confirm if any of the relevant properties have changed
        this.confirmModal = true;
      } else if (this.dbContactData.length === this.contactData.length) {
        // Compare the contactData arrays and filter out unchanged items
        const changeItem = this.dbContactData
          .map((item, index) => {
            const contactItem = this.contactData[index];
            if (
              item.name !== contactItem.name ||
              item.email !== contactItem.email ||
              item.phoneNumber !== contactItem.phoneNumber ||
              item.note !== contactItem.note
            ) {
              return item;
            }
          })
          .filter(Boolean);

        // Prompt the user to confirm if any items have changed
        if (changeItem.length > 0) {
          this.confirmModal = true;
        } else {
          // Redirect immediately if no changes have been made
          this.continueRedirect();
        }
      } else {
        // Redirect immediately if no changes have been made
        this.continueRedirect();
      }
    },

    continueRedirect() {
      // Proceeds with redirection to the client list page.
      this.$router.back();
    },

    /**
     * Retrieves individual client's quote information and constructs the clientQuoteList array.
     */
    async getIndividualQuote() {
      // Clear the clientQuoteList array before making a new request
      this.clientQuoteList = [];
      this.dbClientQuoteList = [];
      // Construct the API endpoint URL with the clientId parameter
      const endPoint = `${API.API_ENDPOINT.quote}?clientId=${this.clientId}`;
      // Make an API call to retrieve quotes for the client
      const response = await masterAPI(endPoint, API.API_METHOD.get);

      if (response.status === 200) {
        // Destructure the response data to get the quoteList array
        const { data: quoteList } = response;

        quoteList.forEach((item) => {
          const createdAt = moment(item.createdAt).format("MMM Do, YYYY");
          // Get the rates object from the quote details, if it exists
          let quoteDetail = item.details;
          let rates = quoteDetail.rates[0];

          let carrierName = rates?.lane.carrier.name;
          let buyRate = rates?.totalCostBeforeMargin;
          let margin = item.details.margin.value;

          const data = {
            select: false,
            quotenumber: getQuoteNumber(item.id),
            clientName: this.clientName,
            origin: rates?.lane.origin,
            destination: rates?.lane.destination,
            createdBy: getCreatedQuoteUserName(
              this.allTeammatesUsers,
              item.userId
            ),
            createdAt,
            shipmendetails: `Volumetric Weight: ${item.details.totalVolumetricWeight}`,
            status: item.quoteStatus,
            createTemplate: "Create",
            edit: item.id,
            details: item.details,
            createTemplateBtn: true,
            buyRate: buyRate,
            margin: margin,
            carrierName: carrierName,
            rates: rates,
            total: checkEmptyValue(rates?.totalCost),
            excelCost: rates?.excelCost || "Not available",
            baseRate: rates?.baseRate,
            quoteItem: item,
            currencyType: this.individualClient.currencyType,
            clientId: this.clientId,
          };

          this.clientQuoteList.push(data);
          this.dbClientQuoteList.push(data);

          if (!carrierName) {
            this.showCarrierDropdown = false;
          }

          if (!buyRate) {
            this.showBuyRateDropdown = false;
          }

          if (!margin) {
            if (margin != 0) {
              this.showMarginDropdown = false;
            }
          }
        });
      }
    },

    /**
     * Removes saved quote details from session, constructs and saves new quote details to session, and navigates to quote result page.
     * @param {object} item - Quote item to edit.
     */
    showQuoteResult(item) {
      // Remove previous quote details from session.
      this.$session.remove("save_quote_details");

      // Extract accessorials detail from quote item rates.
      let accessorialsDetail = accessorials(
        item.rates.accessorialsCost.accessorials
      );

      // Construct new quote detail object.
      let quoteDetail = {
        clientName: this.individualClient,
        quoteContact: [item.details.contact.name],
        margin: item.details.margin.value,
        origin: firstCharCapitalize(item.origin),
        destination: firstCharCapitalize(item.destination),
        addShipmentItem: item.details.items,
        selectedTab: item.details.metric.weight,
        marginDollar: item.details.margin.type == "fixed" ? true : false,
        response: item.details,
        pickupAccessorials: accessorialsDetail.pickupAccessorialItem,
        deliveryAccessorials: accessorialsDetail.deliveryAccessorialItem,
        otherAccessorials: accessorialsDetail.othersAccessorialItem,
        nonLableAccessorials: [],
      };

      // Save new quote details to session.
      this.$session.set("save_quote_details", JSON.stringify(quoteDetail));

      // Navigate to quote result page.
      this.$router.push({ path: "/quote-result", name: "quote-result" });
    },

    /**
     * Sets the confirmModal property to false.
     */
    handleCancel() {
      this.confirmModal = false;
    },
    /**
     * Handles the updated props received from the child component.
     * Updates the corresponding data properties in the parent component.
     * @param {Object} updatedProps - The updated props object.
     */
    handleUpdatedProps(updatedProps) {
      this.clientName = updatedProps.clientName;
      this.margin = updatedProps.margin;
      this.selectedCurrencyType = updatedProps.selectedCurrencyType;
      this.marginDollar = updatedProps.marginDollar;
    },
    /**
     * Updates the list of quotes with the provided item.
     * @param {Array} item - The new list of quotes.
    */
    searchQuotes(item) {
      this.clientQuoteList = item;
    },
  },

  /**
   * Calls methods to retrieve individual client and quote information on component mount.
   */
  mounted() {
    this.getIndividualClient();
  },
};
</script>

<style>
.back-btn {
  color: #004225;
  cursor: pointer;
}

.edit-icon {
  cursor: pointer;
}

.edit-client-form {
  margin-left: 10%;
  margin-top: 5%;
  margin-right: 10%;
}

.edit-form-input .form-control {
  padding-left: 20px !important;
  box-shadow: 0px 1px 3px rgba(50, 50, 93, 0.5), 0px 1px 0px rgba(0, 0, 0, 0.1) !important;
}
</style>
