<template>
  <div>
    <loader :isLoader="showLoader"></loader>
    <div class="row mb-2">
      <div class="col-md-6">
        <span class="v-data-table-title"> {{ tableName }} </span>
      </div>
      <div class="col-md-6 text-right">
        <base-button
          v-if="isCheckedScanIds()"
          class="btn-theme-24876E"
          @click="fillDimensions"
        >
          Create Quote
        </base-button>
      </div>
    </div>
    <div>
      <v-app id="inspire">
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="tableData"
          :single-expand="singleExpand"
          :expanded.sync="expanded"
          :single-select="singleSelect"
          :hide-default-footer="true"
          item-key="cargoID"
          sort-by="id"
          select-enable
          class="elevation-1"
          :items-per-page="tableData.length"
        >

          <template v-slot:[`header.select`]="{ }">
            <div class="custom-control custom-checkbox">
              <input
                id="selectAll"
                type="checkbox"
                class="custom-control-input"
                v-model="selectAll"
                @click="multipleSelectScanItem"
              />
              <label for="selectAll" class="custom-control-label"></label>
            </div>
          </template>

          <template v-slot:item.select="{ item }">
            <div class="checkbox-pin">
              <div class="custom-control custom-checkbox">
                <input
                  :id="item.cargoID"
                  type="checkbox"
                  v-model="item.select"
                  class="custom-control-input"
                  @click="singleSelectScanItem(item)"
                />
                <label :for="item.cargoID" class="custom-control-label"></label>
              </div>
            </div>
          </template>
        </v-data-table>
      </v-app>
    </div>
  </div>
</template>

<script>
import Loader from "@/layout/Loader";
import { API, masterAPI } from "@/api/API";

export default {
  name: "light-table",
  // List of imported components
  components: {
    Loader
  },
  props: {
    // Component props
    tableName: {
      type: String,
    },
    tableData: {
      type: Array,
    },
  },
  data() {
    return {
      // Component's data properties
      singleSelect: false,
      selected: [],
      expanded: [],
      singleExpand: false,
      headers: [
        // Table headers configuration
        { text: "Select", value: "select", sortable: false },
        { text: "Cargo ID", value: "cargoID", sortable: false },
        { text: "Bar Code", value: "barcode", sortable: false },
        { text: "Scan Date", value: "scanDate", sortable: true }
      ],
      selectAll: false,
      selectedScanItem: [],
      showLoader: false
    };
  },
  methods: {
    /**
     * Function to toggle the selection of a single scan item in the table.
     * @param {Object} selectedItem - The item selected/deselected by the user.
    */
    singleSelectScanItem(selectedItem) {
      const selectedIndex = this.tableData.findIndex(item => item.cargoID === selectedItem.cargoID);
      this.tableData[selectedIndex].select = !selectedItem.select;
      this.selectedScanItem = this.tableData.filter(item => item.select);
    },
    /**
     * Function to toggle the selection of all scan items in the table.
    */
    multipleSelectScanItem() {
      this.selectAll = !this.selectAll;

      this.tableData.forEach(item => {
        item.select = this.selectAll;
      });

      let checkboxElement = document.querySelectorAll('.single-checkbox');

      checkboxElement.forEach(item => {
        item.checked = this.selectAll;
      });

      this.selectedScanItem = this.tableData.filter(item => item.select);
    },
    /**
     * Function to check if any scan items are selected.
     * @returns {boolean} - True if at least one scan item is selected, otherwise false.
    */
    isCheckedScanIds() {
      return this.selectedScanItem.filter(item => item.select).length > 0;
    },
    /**
     * Function to fill dimensions for selected items and navigate to a new route.
    */
    async fillDimensions() {
      this.showLoader = true;

      // Extract cargo IDs of selected scan items
      const scanIds = this.selectedScanItem.map(item => item.cargoID);

      let payload = {
        "scanIds": scanIds
      }

      let response = await masterAPI(
        API.API_ENDPOINT.fillDimensions,
        API.API_METHOD.post,
        undefined,
        JSON.stringify(payload)
      );

      this.showLoader = true;

      if (response.status === 200) {
        const dimensions = response.data;

        // Store dimensions in session storage
        this.$session.set("dimensions-items", JSON.stringify(dimensions));

        // Navigate to new route
        this.$router.push({ name: "newquote" });
      } else {
        this.$toast.error(response.data.message);
      }
    }
  },
};
</script>

<style></style>
