<template>
  <div class="side-bar-menu">
    <!-- Logo -->
    <div class="pallet-icon">
      <img class="mb-3 mt-3" src="../assets/img/pallet-logo.svg" />
    </div>    

    <!-- Dynamic menu items -->
    <ul class="menu-list mb-4">
      <li v-for="(menuItem, index) in menuItems" :key="index">
        <span v-if="menuItem.type === 'menu'">
          <a 
            v-if="menuItem.name !== 'Client' || userIsBroker"
            @click.prevent="updateScreen(menuItem.name.toLowerCase())" 
            :class="{ 
              'btn-success active-btn': activeMenuName === menuItem.name, 
              'btn-white': activeMenuName !== menuItem.name 
            }"
            class="btn"
          >
            <img :src="getMenuIcon(menuItem)" />&nbsp;
            {{ menuItem.label }}
          </a>
        </span>
        <div v-else class="mt-4 mb-4 my-work-space">
          {{ menuItem.label }}
        </div>
      </li>
    </ul>

    <!-- Upgrade section -->
    <div class="card side-bar-card">
      <div class="card-body">
        <div class="align-items-center">
          <img src="../assets/img/side-panel/upgrade.png" class="img-fluid upgrade-card-img" />
        </div>
        <div class="align-items-center">
          <base-button 
            class="btn-theme-224034 upgrade-btn"
            @click="upgradeNow"
          >
            Upgrade Now
          </base-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { API, masterAPI } from "@/api/API";

export default {
  name: "left-panel",
  props: {
    activeMenuName: {
      type: String,
    },
  },
  data() {
    return {
      menuItems: [
        { type: 'menu', name: "Home", label: "Overview", icon: "overview-active.svg" },
        { type: 'menu', name: "Quotes", label: "Quotes", icon: "quote-active.svg" },
        { type: 'menu', name: "Carriers", label: "Carriers", icon: "carriers-active.svg" },
        { type: 'menu', name: "Teammates", label: "Teammates", icon: "teammates-active.svg" },
        { type: 'menu', name: "Client", label: "Client", icon: "client-active.svg" },
        { type: 'section', label: "My Workspaces" }, 
        { type: 'menu', name: "AccountSetting", label: "Settings", icon: "settings-active.svg" }, 
        { type: 'menu', name: "Billing", label: "Billing", icon: "billing-active.svg" }, 
        { type: 'menu', name: "logout", label: "Logout", icon: "logout.svg" }
      ],
    };
  },
  computed: {
    ...mapGetters(["userIsBroker"]),
  },
  methods: {
    updateScreen: function(screenName) {
      if (screenName == "logout") {
        this.logout();
      } else {
        this.$session.remove("save_quote_details");
        this.$router.push({ path: "/"+ screenName, name: screenName }).catch(() => {});
        this.$emit('handle-menu-click',true);
      }
    },
    // Define a method to get the appropriate icon based on active state
    getMenuIcon(menuItem) {
      return this.activeMenuName === menuItem.name ? 
             require(`../assets/img/side-panel/${menuItem.icon}`) : 
             require(`../assets/img/side-panel/${menuItem.icon.replace('-active.svg', '.svg')}`);
    },
    async logout() {
      let response = await masterAPI(
        API.API_ENDPOINT.logout,
        API.API_METHOD.post,
        undefined,
        undefined
      );

      if (response.status == 200) {
        this.$toast.success("Logout successful", { timeout: 100 });
        localStorage.removeItem("id_token");
        localStorage.removeItem("p_key");
        this.$router.push({ path: "/", name: "register" }).catch(() => {});
      }
    },
    upgradeNow() {
      this.$router.push({ name: 'planupgrade' }).catch(() => {});
    }
  }
};
</script>

<style></style>
