<template>
  <div>
    <modal
      :show.sync="quotePreviewModal"
      modal-classes="modal-dialog-centered modal-lg quote-preview-modal"
    >
      <div>
        <div class="row mr-0">
          <div class="col-md-10">
            <p class="quote-preview-header">
              Quick quote details preview
            </p>
          </div>
          <div class="col-md-2">
            <button
              type="button"
              class="qoute-preview-close"
              @click="quotePreviewModal = false"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span :aria-hidden="true">×</span>
            </button>
          </div>
        </div>
              
        <div class="row mt-4 mb-5">
          <div class="col-md-3 quote-preview-title">
            Status
          </div>
          <div class="col-md-3">
            <div v-if="quoteDetail.status == quoteStatusList.DECLINED">
              <span class="badge mr-2 badge-danger badge-pill">
                Rejected by Client
              </span>
            </div>
            <div v-if="quoteDetail.status == quoteStatusList.ACCEPTED">
              <span class="badge mr-2 badge-success badge-pill">
                Accepted by Client
              </span>
            </div>
            <div v-if="quoteDetail.status == quoteStatusList.SAVED">
              <span class="badge mr-2 badge-default badge-pill">
                Saved Not sent
              </span>
            </div>
            <div v-if="quoteDetail.status == quoteStatusList.UNDER_REVIEW">
              <span class="badge mr-2 badge-primary badge-pill">
                Under Review by Client
              </span>
            </div>
            <div v-if="quoteDetail.status == quoteStatusList.SENT">
              <span class="badge mr-2 badge-info badge-pill sent-pill-btn">
                Sent
              </span>
            </div>
          </div>
        </div>

        <div class="quote-preview-scroll">
          <div class="row">
            <div class="col-md-3 quote-preview-title">
              Quote Number
            </div>
            <div class="col-md-3 quote-preview-value">
              {{ quoteDetail.quotenumber }}
            </div>
            <div class="col-md-3 quote-preview-title">
              Carrier Name
            </div>
            <div class="col-md-3 quote-preview-value">
              {{ quoteDetail.carrierName }}
            </div>
          </div>
          <div class="quote-preview-divider"></div>
          <div class="row">
            <div class="col-md-3 quote-preview-title">
              Origin
            </div>
            <div class="col-md-3 quote-preview-value">
              {{ quoteDetail.origin.input || quoteDetail.origin }}
            </div>
            <div class="col-md-3 quote-preview-title">
              Destination
            </div>
            <div class="col-md-3 quote-preview-value">
              {{ quoteDetail.destination.input || quoteDetail.destination }}
            </div>
          </div>

          <div class="quote-preview-divider"></div>

          <div class="row">
            <div class="col-md-3 quote-preview-title">
              Created by
            </div>
            <div class="col-md-3 quote-preview-value">
              {{ quoteDetail.createdBy }}
            </div>
            <div class="col-md-3 quote-preview-title">
              Created on
            </div>
            <div class="col-md-3 quote-preview-value">
              {{ quoteDetail.createdAt }}
            </div>
          </div>

          <div class="quote-preview-divider"></div>

          <div class="row">
            <div class="col-md-3 quote-preview-title">
              Shipment Details
            </div>
            <div class="col-md-9">
              <span class="quote-preview-value">
                {{ quoteDetail.shipmendetails }}
              </span>
              
              <template>
                <i class="fa fa-info-circle info-icon" aria-hidden="true">
                  <vue-easy-tooltip
                    backgroundColor="#FFFFFF"
                    position="top"
                    :withArrow="true"
                    classes="shipment-item-tooltip"
                  >
                    <div class="row">
                      <div class="col-md-3 quantity-header">Quantity</div>
                      <div class="col-md-6">Dimensions</div>
                      <div class="col-md-3">Weight</div>
                    </div>
                    <div
                      class="row tooltip-body"
                      v-for="(shipmentItem, index) in quoteDetail.details.items"
                      :key="index"
                    >
                      <div class="col-md-3 quantity-header">
                        {{ shipmentItem.quantity }}
                      </div>
                      <div class="col-md-6">
                        <span class="calculate-view">
                          <span>
                            {{ shipmentItem.length }}
                            <i class="fa fa-times" aria-hidden="true"></i>
                            <span class="lwh-text length-text">L</span>
                          </span>

                          <span>
                            {{ shipmentItem.width }}
                            <i class="fa fa-times" aria-hidden="true"></i>
                            <span class="lwh-text width-text">W</span>
                          </span>
                          <span class="last-height">
                            {{ shipmentItem.height }}
                            <span class="lwh-text height-text">H</span></span
                          >
                        </span>
                      </div>
                      <div class="col-md-3">
                        {{
                          shipmentItem.weight + " " + quoteDetail.details.metric.weight
                        }}
                      </div>
                    </div>
                  </vue-easy-tooltip>
                </i>
              </template>
              <div class="quote-preview-value mt-2">
                Weight: {{ quoteDetail.details.totalWeight }}
                {{ quoteDetail.details.metric.weight }}
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-4">
          <base-button
            class="btn-theme-24876E btn-width-100"
            @click="showQuoteResult"
          >
            Edit Quote
          </base-button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import Modal from "@/components/Modal.vue";
import VueEasyTooltip from "vue-easy-tooltip";
import { quoteStatus } from "@/helpers/utility";

export default {
  components: {
    Modal,
    VueEasyTooltip
  },
  props: {
    show: {
      type: Boolean,
    },
  },
  data: function() {
    return {
      quotePreviewModal: false,
      quoteDetail:"",
      quoteStatusList: quoteStatus,
    };
  },
  methods: {
    openModal(item) {
      this.quoteDetail = item;
      this.quotePreviewModal = true;
    },
    showQuoteResult() {
      this.$emit("show-quote-result", this.quoteDetail);
    }
  },
};
</script>
<style></style>
