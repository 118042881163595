var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('SlideYUpTransition',{attrs:{"duration":_vm.animationDuration}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}],staticClass:"modal fade",class:[
        { 'show d-block': _vm.show },
        { 'd-none': !_vm.show },
        { 'modal-mini': _vm.type === 'mini' },
      ],attrs:{"tabindex":"-1","role":"dialog","aria-hidden":!_vm.show}},[_c('div',{staticClass:"modal-dialog modal-dialog-centered",class:[{ 'modal-notice': _vm.type === 'notice' }, _vm.modalClasses]},[_c('div',{staticClass:"modal-content",class:[
            _vm.gradient ? `bg-gradient-${_vm.gradient}` : '',
            _vm.modalContentClasses,
          ]},[(_vm.$slots.header)?_c('div',{staticClass:"modal-header",class:[_vm.headerClasses]},[_vm._t("header"),_vm._t("close-button",function(){return [(_vm.showClose)?_c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"},on:{"click":_vm.closeModal}},[_c('span',{attrs:{"aria-hidden":!_vm.show}},[_vm._v("×")])]):_vm._e()]})],2):_vm._e(),_c('div',{staticClass:"modal-body",class:_vm.bodyClasses},[_vm._t("default")],2),(_vm.$slots.footer)?_c('div',{staticClass:"modal-footer",class:_vm.footerClasses},[_vm._t("footer")],2):_vm._e()])])])]),(_vm.isModalBackdrop)?_c('div',{staticClass:"modal-backdrop fade show"}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }