<template>
  <div class="wrapper">
    <div class="page-header register-header">
      <loader :isLoader="showLoader"></loader>
      <div class="container">
        <div class="col-lg-5 col-md-8 mx-auto">
          <div class="card bg-white shadow border-0">
            <div class="card-header">
              <img class="card-img" src="img/ill/bg5-1.svg" alt="Card image" />
              <h4 class="card-title text-center"> {{ showChangePassword ? "Change Password" : "Reset Password"}}</h4>
            </div>
            <!-- Form for changing password -->
            <div v-if="showChangePassword">
              <div class="card-body px-lg-5 py-lg-5">
                <ValidationObserver v-slot="{ validate }" ref="formValidator">
                  <form
                    @submit.prevent="validate().then(resetPasswordUsingCode)"
                    class="needs-validation"
                    id="add-qo"
                  >
                  <base-input
                    type="text"
                    label="Reset Code"
                    class="input-v1"
                    name="Reset Code"
                    rules="required"
                    v-model="code"
                  >
                  </base-input>
                  <base-input
                    type="password"
                    label="New Password"
                    class="input-v1"
                    name="New Password"
                    rules="required"
                    v-model="newPassword"
                  >
                  </base-input>
                  <base-input
                    type="password"
                    label="Confirm Password"
                    class="input-v1"
                    name="Confirm Password"
                    rules="required"
                    v-model="confirmPassword"
                  >
                  </base-input>

                  <div class="text-center">
                    <button type="submit" class="btn mt-3 btn-success text-center">
                      Change Password
                    </button>
                  </div>
                  </form>
                </ValidationObserver>
              </div>
            </div>
            <div v-else class="card-body px-lg-5 py-lg-5">
              <!-- Form for resetting password -->
              <div class="text-center text-muted mb-4">
                <small>Enter email address to reset password</small>
              </div>
              <ValidationObserver v-slot="{ validate }" ref="formValidator">
                <form
                  @submit.prevent="validate().then(sendResetPasswordEmail)"
                  class="needs-validation"
                  id="add-qo"
                >

                <base-input
                  type="email"
                  name="Email"
                  class="input-v1"
                  rules="required"
                  v-model="email"
                >
                </base-input>
                
                <div class="text-center">
                  <button type="submit" class="btn mt-3 btn-success text-center">
                    Send
                  </button>
                </div>
                </form>
              </ValidationObserver>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { API, masterAPI } from "@/api/API";
import Loader from "@/layout/Loader";
export default {
  bodyClass: "reset-page",
  components: {
    Loader,
  },
  data() {
    return {
      email: '',
      showChangePassword: false,
      code: "",
      newPassword: "",
      confirmPassword: "",
      showLoader: false,
    };
  },
  methods: {
    /**
     * Sends a reset password email to the user.
    */
    async sendResetPasswordEmail() {
      const isFormValid = await this.$refs.formValidator.validate();
      if (isFormValid) {
        this.showLoader = true;

        let bodyPayload = JSON.stringify({
          email: this.email
        });

        let response = await masterAPI(
          API.API_ENDPOINT.sendResetPasswordEmail,
          API.API_METHOD.post,
          undefined,
          bodyPayload
        );
        this.showLoader = false;
        if(response.status == 200) {
          this.$toast.success("Success! A reset has been sent to the email you've provided. Use the same to set your new password");
          this.showChangePassword = true;
        } else {
          this.$toast.error(response.data.message.replaceAll('"', ""));
        }
      }
    },
    /**
     * Resets the password using the provided code.
    */
    async resetPasswordUsingCode() {
      const isFormValid = await this.$refs.formValidator.validate();
      if (isFormValid) {

        if (this.newPassword != this.confirmPassword) {
          this.$toast.error("Your new password and confirmation password do not match.");
        } else {
          this.showLoader = true;

          let bodyPayload = {
            email: this.email,
            code: this.code,
            newPassword: this.newPassword,
          };

          let response = await masterAPI(
            API.API_ENDPOINT.resetPasswordUsingCode,
            API.API_METHOD.post,
            undefined,
            JSON.stringify(bodyPayload)
          );
          
          this.showLoader = false;

          if (response.status == 200) {
            this.$toast.success("Password updated successfully");
            setTimeout(() => {
              this.$router.push({ path: "/", name: "register" }).catch(() => {});
            },500);
          } else {
            this.$toast.error(response.data.message.replaceAll('"', ""));
          }
        }
      }
    }
  }
};
</script>

<style></style>
