<template>
  <div class="wrapper">
    <div class="page-header register-header">
      <div
        class="container"
        v-bind:class="{ 'right-panel-active': isActive }"
        id="container"
      >
        <div class="form-container sign-up-container">
          <form action="javascript:">
            <img
              class="m-signin-signup-img"
              src="../assets/welcome.png"
              style="display: none;"
            />
            <h2>Create New Account</h2>
<!--            <span class="text-default mb-4">-->
<!--              Use your email for registration-->
<!--            </span>-->
            <div class="form-group mb-0">
              <vue-input
                alternative
                placeholder="Your Name"
                addonLeftIcon="ni ni-circle-08"
                v-model="registerUserName"
              ></vue-input>
            </div>
            <div class="form-group mb-0">
              <vue-input
                alternative
                placeholder="Organization Name"
                addonLeftIcon="fa fa-users"
                v-model="organizationName"
                :readonly="disableOrgName"
              ></vue-input>
            </div>

            <div class="form-group mb-0">
              <vue-input
                alternative
                placeholder="Your email"
                addonLeftIcon="ni ni-email-83"
                v-model="registerUserEmail"
                :readonly="disableEmail"
              ></vue-input>
            </div>
            <div class="form-group mb-0">
              <vue-input
                alternative
                placeholder="Password"
                type="password"
                addonLeftIcon="ni ni-lock-circle-open"
                v-model="registerUserPassword"
              ></vue-input>
            </div>

            <div class="mb-3">
              <label class="organization-type-text mb-2">Organization type</label>
              <div class="d-flex">
                <base-radio 
                  name="shipper" 
                  class="mr-3 user-mode-radio" 
                  v-model="userMode"
                  :disabled="disabledUserMode"
                >
                  Shipper
                </base-radio>
                <base-radio 
                  name="broker" 
                  class="user-mode-radio"
                  v-model="userMode"
                  :disabled="disabledUserMode"
                >
                  Broker/3PL
                </base-radio>
              </div>
            </div>

            <base-button
              type="success"
              v-on:click="signUp"
              v-on:keyup.enter="signUp"
            >
              Sign Up
            </base-button>

            <div class="m-signin-signup-link">
              Already have account?
              <a @click="isActive = false">Sign in</a>
            </div>

          </form>
        </div>
        <div class="form-container sign-in-container">
          <form @submit.prevent="signIn">
            <img
              class="m-signin-signup-img"
              src="../assets/welcome.png"
              style="display: none;"
            />
            <h2>Sign in to pallet</h2>
<!--            <span class="text-default mb-4">Using your account</span>-->
            <div class="form-group mb-0">
              <vue-input
                alternative
                placeholder="Your email"
                addonLeftIcon="ni ni-email-83"
                v-model="signInEmail"
              ></vue-input>
            </div>
            <div class="form-group mb-0">
              <vue-input
                alternative
                placeholder="Password"
                type="password"
                addonLeftIcon="ni ni-lock-circle-open"
                v-model="signInPassword"
              ></vue-input>
            </div>
            <button type="submit" class="btn btn-success">
              Continue
            </button>
            <router-link to="/reset" class="forget-password mt-4">
              Forgot your password?
            </router-link>

            <div class="m-signin-signup-link mt-5">
              Create account.
              <a @click="isActive = true">Sign up</a>
            </div>
          </form>
        </div>
        <div class="overlay-container">
          <div class="overlay">
            <div class="overlay-panel overlay-left">
              <img src="../assets/welcome.png" />
              <h3>Welcome</h3>
<!--              <p>-->
<!--                To keep connected with us please login with your personal info-->
<!--              </p>-->
              <base-button type="success" class="bw-100" @click="isActive = false">
                Sign in
              </base-button>
            </div>
            <div class="overlay-panel overlay-right">
              <img src="../assets/welcome.png"/>
              <h3>Welcome</h3>
              <p>
                You're only a few steps away from creating the best quotes, easier than ever before.
              </p>
              <base-button type="success" class="bw-100" @click="isActive = true">
                Sign up
              </base-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { API, masterAPI } from "@/api/API";
import { setPassword } from "@/helpers/utility";
import { mapActions } from "vuex";

export default {
  bodyClass: "register-page",
  data () {
    return {
      isActive: false,
      signInEmail: "",
      signInPassword: "",
      registerUserName: "",
      organizationName: "",
      registerUserEmail: "",
      registerUserPassword: "",
      signInError: "",
      disableOrgName: false,
      disableEmail: false,
      tariffInvited: false,
      tariffUserEmail: "",
      userMode: "",
      disabledUserMode: false,
    };
  },
  computed: {
    ...mapActions(["getProfileData"])
  },
  methods: {
    signIn: async function() {
      let bodyPayload = JSON.stringify({
        email: this.signInEmail,
        password: this.signInPassword
      });

      let response = await masterAPI(
        API.API_ENDPOINT.login,
        API.API_METHOD.post,
        undefined,
        bodyPayload
      );

      if (response.status === 200) {
        this.$toast.success("Login Successful", { timeout: 100 });
        localStorage.setItem("id_token", response.data.id_token);
        await this.getProfile();
        setPassword(this.signInPassword);
      } else {
        let error = response.data.message;
        this.$toast.error(error.replaceAll("\"", ""));
      }
    },
    signUp: async function() {
      let bodyPayload = JSON.stringify({
        email: this.registerUserEmail,
        firstName: this.registerUserName,
        organizationName: this.organizationName,
        password: this.registerUserPassword,
        tariffInvited: this.tariffInvited,
        tariffUserEmail: this.tariffUserEmail,
        userMode: this.userMode
      });

      let response = await masterAPI(
        API.API_ENDPOINT.signup,
        API.API_METHOD.post,
        undefined,
        bodyPayload
      );

      if (response.status === 200) {
        this.$toast.success("Registration successful", { timeout: 100 });
        localStorage.setItem("id_token", response.data.id_token);
        await this.getProfile();
        setPassword(this.registerUserPassword);
      } else {
        let error = this.processErrorMessage(response.data.message);
        this.$toast.error(error);
      }
    },
    processErrorMessage(message) {
      message = message.replaceAll("\"userMode\"", "\"organizationType\"");
      return message.replaceAll("\"", "");
    },
    getProfile: async function() {
      let vm = this;
      await this.$store.dispatch("getProfileData");
      setTimeout(function() {
        vm.$router.push({ path: "/home", name: "home" }).catch(() => {});
      }, 500);
    },
    // Extract and decode Base64 parameter from the URL
    getDecodedParam(url, paramName) {
        let paramValue = url.split(paramName + "=")[1].split("/")[0].trim();
        return Buffer.from(paramValue, "base64").toString("ascii");
    },
  },
  created () {
    if (location.href.includes("companyName")) {
      this.isActive = true;
      if (location.href.includes("email")) {

        let url = location.href.split("#/")[1];
       
        // Extract and decode email
        this.registerUserEmail = this.getDecodedParam(url, "email");
        
        // Extract and decode companyName
        this.organizationName = this.getDecodedParam(url, "companyName");
        
        // Extract and decode organizationType
        this.userMode = this.getDecodedParam(url, "organizationType");

        this.disableEmail = true;
        this.disableOrgName = true;
        this.disabledUserMode = true;
      } else {
        this.disableOrgName = true;
        let url = location.href.split("#/")[1];
        
        // Extract and decode companyName
        this.organizationName = this.getDecodedParam(url, "companyName");
        
        // Extract and decode organizationType
        this.userMode = this.getDecodedParam(url, "organizationType");
      }
    } else if (location.href.includes("invite")) {
      this.isActive = true;
      this.tariffInvited = true;
      this.disableEmail = true;
      let email = location.href.split("email=")[1].trim();
      let decodedEmail = Buffer.from(email, "base64").toString("ascii");
      this.registerUserEmail = decodedEmail.replace("#/", "");
      let encodedTariffUserEmail = location.href.split("tariffUserEmail=")[1].trim();
      this.tariffUserEmail = Buffer.from(
        encodedTariffUserEmail.split("/")[0].trim(),
        "base64"
      ).toString("ascii");
    }
  }
};
</script>

<style></style>
