<template>
  <div>
    <modal
      :show.sync="carrierReasoningModal"
      modal-classes="modal-dialog-centered modal-lg"
    >
      <slot name="close-button">
        <button
          type="button"
          class="carrier-reasoning-close"
          @click="carrierReasoningModal = false"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span :aria-hidden="true">×</span>
        </button>
      </slot>

      <div>
        <div class="table-responsive mb-0 mt-4 missing-reasons-table">
          <table class="table align-items-center">
            <thead>
              <tr>
                <th scope="col" class="table-header-title">Carrier Name</th>
                <th scope="col" class="table-header-title">Reasoning</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in missingReasonsList" :key="index">
                <td>
                  <span>{{
                    item.carrierName
                  }}</span>
                </td>
                <td>
                  <span>{{ item.reason }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import Modal from "@/components/Modal.vue";
import { Table, TableColumn } from "element-ui";

export default {
  components: {
    Modal,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data: function() {
    return {
      carrierReasoningModal: false, // Boolean to control the visibility of the modal
      missingReasonsList: [], // Array to store the grouped missing reasons
      reasonsColor: [
        "rgba(183, 2, 2, 0.58)", // Color for the first reason
        "rgba(35, 61, 210, 0.49)", // Color for the second reason
      ]
    };
  },
  props: {
    show: {
      type: Boolean, // Prop to control the visibility of the component
    },
    missingReasons: {
      type: Array, // Prop to receive the array of missing reasons
    },
  },
  methods: {
    /**
     * Opens the modal and sets the missing reasons.
     */
    openModal() {
      this.setMissingReasons();
      this.carrierReasoningModal = true;
    },
    /**
     * Groups the missing reasons and assigns background colors.
     */
    setMissingReasons() {
      this.missingReasonsList = [];
      let index = 0;
      const groupedMissingReasons = Object.values(this.missingReasons.reduce((result, item) => {
        const key = `${item.carrierName}:${item.reason}`;
        if (!result[key]) {
          result[key] = {
            carrierName: item.carrierName,
            reason: item.reason,
            backgroundColor: this.reasonsColor[index],
          };
          index ++;
          if (index > 1) {
            index = 0;
          }
        }
        return result;
      }, {})).sort((a, b) => a.carrierName.localeCompare(b.carrierName));

      this.missingReasonsList = groupedMissingReasons;
    }
  },
  created() {
    this.setMissingReasons();
  },
};
</script>
<style></style>
