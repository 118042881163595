<template>
  <div>
    <v-app
      id="inspire"
      :class="viewAllRecord ? 'view-all-carriers-details' : ''"
    >
      <v-data-table
        :headers="headers"
        :items="tableData"
        :hide-default-footer="true"
        item-key="name"
        :sort-by="sortByKey"
        select-enable
        class="elevation-1"
        :items-per-page="!viewAllRecord ? 5 : tableData.length"
        :mobile-breakpoint="0"
      >
        <template v-slot:[`header.select`]="{ }">
          <div class="custom-control custom-checkbox">
            <input
              id="selectAll"
              type="checkbox"
              class="custom-control-input"
              v-model="selectAll"
              @click="selectAllItems"
            />
            <label for="selectAll" class="custom-control-label"></label>
          </div>
        </template>

        <template v-slot:item.select="{ item }">
          <div class="checkbox-pin">
            <div class="custom-control custom-checkbox">
              <input
                :id="item.id"
                type="checkbox"
                v-model="item.select"
                class="custom-control-input single-checkbox"
                @click="selectedRow(item)"
              />
              <label :for="item.id" class="custom-control-label"></label>
            </div>
          </div>
        </template>

        <template v-slot:item.userImg="{}">
          <img src="../../../assets/img/home/avatar.png" alt="Image" class="avatar-lg" />
        </template>

        <template v-slot:item.type="{item}">
          <div class="user-type-badge" :style="{ backgroundColor: item.type === 'admin' ? '#FF813B' : '#009F701A' }">
            {{ item.type }}
          </div>
        </template>

        <template v-slot:item.quotesCreated="{item}">
          <span
            class="view-all-quote-link"
            @click="viewAllQuotes(item)"
          >
            view all quotes
          </span>
        </template>

        <!-- Slot for formatted date -->
        <template v-slot:item.updatedAt="{item}">
          <date-format
            :date="item.updatedAt"
          />
        </template>

        <!-- Slot for actions -->
        <template v-slot:item.action="{item}">
          <div v-if="isTeammates">
            <base-button
              type="primary"
              class="btn-theme-EFEBEB action-left"
            >
              <img src="../../../assets/img/home/edit.png"/>
            </base-button>
            <base-button
              type="primary"
              class="btn-theme-EFEBEB action-right"
            >
              <img src="../../../assets/img/home/trash.png"/>
            </base-button>
          </div>
          <div v-else>
            <base-button
              type="primary"
              class="btn-theme-EFEBEB action-left"
            >
              <img src="../../../assets/img/home/duplicate.png"/>
            </base-button>
            <base-button
              type="primary"
              class="btn-theme-EFEBEB action-right"
              @click="showEdit(item.id)"
            >
              <img src="../../../assets/img/home/edit.png"/>
            </base-button>
          </div>
        </template>
      </v-data-table>
    </v-app>
  </div>
</template>

<script>
import DateFormat from "@/views/components/DateFormat.vue";

export default {
  name: "light-table",
  components: {
    DateFormat
  },
  // Declare props
  props: {
    headers: Array,
    tableData: Array,
    sortByKey: String,
    viewAllRecord: Boolean,
    isTeammates: Boolean,
  },
  data() {
    return {
      selectAll: false,
    };
  },
  methods: {
    /**
     * Emit event to edit item
     * @param {string} recordId - Id of the record to edit
     */
    showEdit(recordId) {
      this.$emit("edit-item", recordId);
    },
    /**
     * Handle selection of row
     * @param {object} selectedItem - Selected item
     */
    selectedRow(selectedItem) {
      const selectedIndex = this.tableData.findIndex(item => item.id === selectedItem.id);
      this.tableData[selectedIndex].select = !selectedItem.select;
      const selectedItems = this.tableData.filter(item => item.select);
      this.$emit("selected-items", selectedItems);
    },
    selectAllItems() {
      this.selectAll = !this.selectAll;
      this.tableData.forEach(item => {
        item.select = this.selectAll;
      });

      let checkboxElement = document.querySelectorAll('.single-checkbox');

      checkboxElement.forEach(item => {
        item.checked = this.selectAll;
      });

      const selectedItems = this.tableData.filter(item => item.select);
      this.$emit("selected-items", selectedItems);
    },
    viewAllQuotes(item) {
      this.$router.push({
        name: "viewallquotesbyuser",
        params: { id: item.id },
      });
    },
  },
};
</script>

<style></style>
