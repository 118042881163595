<template>
  <div>
    <div>
      <div v-if="viewCarriers" class="back-to-carriers">
        <router-link to="/carriers">
          <span class="back-btn">Back</span>
        </router-link>
      </div>
      <div class="row align-items-center">
        <div class="ml">
          <span class="v-data-table-title">
            {{ viewCarriers ? "All Carriers" : "Most Recent Carriers" }}</span
          >
        </div>
        <div class="col-md-5" v-if="viewCarriers">
          <div class="view-autocomplete-search-div">
            <i class="ni ni-zoom-split-in view-autocomplete-search-icon"></i>
            <vue-basic-autocomplete
              trackby="carrierName"
              input-class="form-control view-autocomplete-search-input"
              placeholder="Search your Carriers, Tariff sheets, and all Lanes"
              :clear-btn="false"
              none-find="Carrier not found"
              :options="carrierData"
              v-model="searchCarrier"
              @selected="(event) => selectedCarrier(event)"
            />
          </div>
        </div>
      </div>

      <div class="tools float-right">
        <base-dropdown menuClasses="dropdown-menu-right">
          <div
            slot="title"
            class="nav-link dropdown-toggle text-primary"
            data-toggle="dropdown"
          >
            <i class="ni ni-settings-gear-65"></i>
          </div>
        </base-dropdown>
      </div>
    </div>
    <div>
      <v-app
        id="inspire"
        :class="viewCarriers ? 'view-all-carriers-details' : ''"
      >
        <!-- sort-desc="true" -->
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="carrierData"
          :single-select="singleSelect"
          :hide-default-footer="true"
          item-key="name"
          sort-by="carrierName"
          select-enable
          class="elevation-1"
          :items-per-page="!viewCarriers ? 5 : carrierData.length"
          :mobile-breakpoint="0"
        >
          <template v-slot:header.edit="{}">
            <div
              v-if="showDelete"
              class="delete--carriers-icon"
              @click="confirmCarrierModal = true"
            >
              <i class="fa fa-trash"></i>
            </div>
            <span>
              EDIT
            </span>
          </template>
          <template v-slot:item.select="{ item }">
            <div class="checkbox-pin">
              <div class="custom-control custom-checkbox">
                <input
                  :id="item.id"
                  type="checkbox"
                  v-model="item.select"
                  class="custom-control-input"
                  @click="selectedRow(item)"
                />
                <label :for="item.id" class="custom-control-label"></label>
              </div>
            </div>
          </template>
          <template v-slot:item.carrierName="{ item }">
            <div class="carrier-name" @click="showEditCarrier(item.id)">
              {{ item.carrierName }}
            </div>
          </template>
          <template v-slot:item.edit="{ item }">
            <div class="d-flex">
              <div class="edit-carriers-icon" @click="showEditCarrier(item.id)">
                <svg
                  width="16"
                  height="15"
                  viewBox="0 0 25 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.7467 8.02667L15.9733 9.25333L3.89333 21.3333H2.66667V20.1067L14.7467 8.02667ZM19.5467 0C19.2133 0 18.8667 0.133333 18.6133 0.386667L16.1733 2.82667L21.1733 7.82667L23.6133 5.38667C24.1333 4.86667 24.1333 4.02667 23.6133 3.50667L20.4933 0.386667C20.2267 0.12 19.8933 0 19.5467 0ZM14.7467 4.25333L0 19V24H5L19.7467 9.25333L14.7467 4.25333Z"
                    fill="#004225"
                  />
                </svg>
              </div>
            </div>
          </template>
        </v-data-table>
      </v-app>
    </div>
    <modal
      :show.sync="confirmCarrierModal"
      modal-classes="modal-dialog-centered confrim-quote-modal"
    >
      <h5
        slot="header"
        class="modal-title confrim-quote-header"
        id="modal-title-default"
      >
        Delete
      </h5>

      <div>
        Are you sure you want to delete <b>{{selectedItemName}}</b>. This action is
        not reversible. All carriers deleted will no longer be available in
        Pallet until they are added again
      </div>

      <template slot="footer">
        <base-button
          type="primary"
          class="pallet-theme-btn"
          @click="deleteCarrier"
        >
          Confirm
        </base-button>
        <base-button
          type="secondary"
          class="ml-auto"
          @click="confirmCarrierModal = false"
          >Cancel
        </base-button>
      </template>
    </modal>
  </div>
</template>

<script>
import BaseDropdown from "@/components/BaseDropdown";
import { API, masterAPI } from "@/api/API";
import moment from "moment";
import Modal from "@/components/Modal.vue";

export default {
  name: "light-table",
  components: {
    BaseDropdown,
    Modal,
  },
  props: {
    viewCarriers: {
      type: Boolean,
    },
  },
  data() {
    return {
      singleSelect: false,
      selected: [],
      headers: [
        { text: "SELECT", value: "select" },
        { text: "CARRIER NAME", value: "carrierName" },
        { text: "LAST UPDATED", value: "lastUpdated" },
        { text: "CONTRACT EXPIRY", value: "contractExpiry" },
        { text: "CONTACT", value: "contact", class: "custom-th-class" },
        {
          text: "VIEW TARIFF SHEETS",
          value: "viewTarieeSheet",
          class: "custom-th-class",
        },
        { text: "EDIT", value: "edit", class: "custom-th-class" ,sortable: false},
      ],
      carrierData: [],
      searchCarrier: "",
      confirmCarrierModal: false,
      showDelete:false,
      selectedItemName:'',
      selectedItems:[],
    };
  },
  methods: {
    getAllCarrier: async function() {
      this.carrierData = [];
      let response = await masterAPI(
        API.API_ENDPOINT.carrier,
        API.API_METHOD.get,
        undefined,
        undefined
      );

      if (response.status == 200) {
        let listCarrier = response.data;
        for (let i = 0; i < listCarrier.length; i++) {
          let updatedAt = listCarrier[i].updatedAt;
          let data = {
            select: false,
            carrierName: listCarrier[i].name,
            lastUpdated: updatedAt
              ? moment(updatedAt).format("MMM Do, YYYY")
              : "",
            contractExpiry: "",
            contact: "",
            viewTarieeSheet: "",
            edit: "",
            id: listCarrier[i].id,
            userId: listCarrier[i].userId,
          };
          this.carrierData.push(data);
        }
      }
    },
    deleteCarrier: async function() {
      let selectedCarrier = this.selectedItems;
      let apiCallIndex = 0;

      for (let i = 0; i < selectedCarrier.length; i++) {
        let response = await masterAPI(
          API.API_ENDPOINT.carrier,
          API.API_METHOD.delete,
          selectedCarrier[i].id,
          undefined
        );

        if (response.status == 200) {
          apiCallIndex = apiCallIndex + 1;
        }
      }
      if (apiCallIndex == selectedCarrier.length) {
        await this.getAllCarrier();
        this.confirmCarrierModal = false;
        this.$toast.success("Carrier delete successful", {
          timeout: 1000,
        });
        this.showDelete = false;
      }
    },
    showEditCarrier: function(carriersId) {
      this.$router.push({
        name: "editCarriers",
        params: { id: carriersId },
      });
    },
    selectedCarrier: function(item) {
      this.showEditCarrier(item.id);
    },
    selectedRow: function(selectedItem) {
      let selectedIndex = this.carrierData.map(item => item.id).indexOf(selectedItem.id);
      this.carrierData[selectedIndex].select = !selectedItem.select;
      let selectedName = [];
      let checkData = this.carrierData.map((item) => {
          if (item.select) {
              selectedName.push(item.carrierName);
              return item;
          }
      }).filter(function( element ) {
          return element !== undefined;
      });
      
      if (checkData.length > 0) {
        this.showDelete = true;
        this.selectedItemName = selectedName.join(', ');
      } else {
        this.showDelete = false;
      }
      this.selectedItems = checkData;
    }
  },
  created() {
    this.getAllCarrier();
  },
};
</script>

<style>
.back-to-carriers {
  margin-bottom: 15px;
}

.edit-carriers-icon {
  cursor: pointer;
}

.delete--carriers-icon i {
  font-size: 18px;
  margin-bottom: 13px;
  margin-top: -3px;
  cursor: pointer;
}

.fa-trash {
  color: #004225;
}

.carrier-name {
  cursor: pointer;
}
</style>
