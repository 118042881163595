<template>
<div class="col-lg-2 left-bar">
    <div class="sidebar-large-view">
        <sidebar-menu :activeMenuName="activeMenuName" @handle-menu-click="handleMenuClick"></sidebar-menu>
    </div>

    <div class="sidebar-mobile-view">
        <button class="navbar-toggler navbar-dark sidebar-toggler-button" type="button" v-b-toggle.sidebar-backdrop>
            <i class="fa fa-bars" aria-hidden="true"></i>
        </button>
        <div class="m-search-bar">
          <search-bar/>
        </div>
        

        <b-sidebar id="sidebar-backdrop" bg-variant="white" backdrop shadow>
            <div class="px-3 py-2">
                <sidebar-menu :activeMenuName="activeMenuName" @handle-menu-click="handleMenuClick"></sidebar-menu>
            </div>
        </b-sidebar>
    </div>

</div>
</template>

<script>
import Vue from "vue";
import SidebarMenu from "../layout/SidebarMenu";
import SearchBar from "@/views/components/SearchBar";
import {
    BSidebar
} from 'bootstrap-vue/esm/components/sidebar/sidebar';
import {
    VBToggle
} from "bootstrap-vue/esm/directives/toggle/toggle";
import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.directive('b-toggle', VBToggle)

export default {
    name: "left-panel",
    components: {
        SidebarMenu,
        SearchBar,
        BSidebar
    },
    props: {
        activeMenuName: {
            type: String,
        },
    },
    methods:{
        handleMenuClick: function() {
            this.$emit('menu-click',true);
        }
    }
};
</script>

<style></style>
