<template>
  <collapse :multiple-active="false" class="carrier-collapse">
    <div class="card">
      <div
        role="tab"
        class="card-header"
        :aria-expanded="isOpen"
        @click.prevent="toggleCollapse"
      >
        <h5 class="mb-0 btn btn-link w-100 text-primary text-left">
          {{ title }}
          <i v-if="isOpen" class="ni ni-bold-up float-right pt-1"></i>
          <i v-else class="ni ni-bold-down float-right pt-1"></i>
        </h5>
      </div>
      <collapse-transition>
        <div v-show="isOpen" role="tabpanel" class="collapsed">
          <slot></slot>
        </div>
      </collapse-transition>
    </div>
  </collapse>
</template>

<script>

import Collapse from "@/components/Collapse/Collapse";
import { CollapseTransition } from "vue2-transitions";

export default {
  components: {
    Collapse,
    CollapseTransition,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    value: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    isOpen: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    toggleCollapse() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>

<style></style>
