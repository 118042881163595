<template>
  <div class="wrapper">
    <div class="body-container row">
      <loader :isLoader="showLoader"></loader>
      <left-panel :activeMenuName="activeMenuName"></left-panel>
      <div class="col-lg-10 right-panel-scroll plr-30 mt-4">
        <div class="row right-panel-header">
          <div class="col-md-4">
            <search-bar
            />
          </div>
          <div class="col-md-4">
          </div>
          <div class="col-md-4">
            <profile-header/>
          </div>
        </div>

        <page-heading-card
          title="Clients"
          subTitle=""
          buttonName="Add client"
          imageName="client.png"
          addRecordScreenName="newclient"
        />
        <div class="mt-5 mb-5 section-b">
          <ValidationObserver v-slot="{ validate }" ref="formValidator">
          <form
            @submit.prevent="validate().then(createClient)"
            class="needs-validation"
            id="create_client"
          >
            <div class="row align-items-center mb-4">
              <div class="col-md-10 headline-30">
                {{ clientName }}
              </div>
              <div class="col-md-2 text-right justify-content-end">
                <div class="edit-icon">
                  <base-button
                    nativeType="submit"
                    type="primary"
                    class="btn-theme-24876E"
                  >
                    Save
                  </base-button>
                </div>
              </div>
            </div>

            <div class="client-scrollbar">
              <add-edit-client-form
                :clientName="clientName"
                :dateAdded="dateAdded"
                :marginDollar="marginDollar"
                :margin="margin"
                :selectedCurrencyType="selectedCurrencyType"
                @update:props="handleUpdatedProps"
              />

              <client-contact-form
                :selected="selected"
                :contactData="contactData"
              />
            </div>
          </form>
        </ValidationObserver>
        </div>
      </div>

      <confirm-modal
        :confirmModal="confirmModal"
        @cancel="handleCancel"
      ></confirm-modal>
    </div>
  </div>
</template>

<script>
import LeftPanel from "@/layout/LeftPanel";
import Loader from "@/layout/Loader";
import moment from "moment";
import { API, masterAPI } from "@/api/API";
import SearchBar from "@/views/components/SearchBar";
import ProfileHeader from "@/layout/ProfileHeader";
import PageHeadingCard from "@/views/components/PageHeadingCard";
import ClientContactForm from "./ClientContactForm.vue";
import AddEditClientForm from "./AddEditClientForm.vue";
import ConfirmModal from "@/views/common/ConfirmModal.vue";

export default {
  bodyClass: "carriers",
  // List of imported components
  components: {
    LeftPanel,
    Loader,
    SearchBar,
    ProfileHeader,
    PageHeadingCard,
    ClientContactForm,
    AddEditClientForm,
    ConfirmModal,
  },
  props: {},
  data() {
    const initialContactData = {
      name: "",
      email: "",
      phoneNumber: "",
      note: "",
      remove: true,
      isEdit: true,
    };
    const today = moment(new Date()).format("MMM Do, YYYY");
    // Component's data properties
    return {
      activeMenuName: "Client",
      clientName: "",
      dateAdded: today,
      margin: "",
      marginDollar: false,
      selected: [],
      contactData: [initialContactData],
      dbClientName: "",
      dbMargin: "",
      dbContactData: [initialContactData],
      confirmModal: false,
      showLoader: false,
      currencyTypeLabelText: "Currency Type",
      selectedCurrencyType: null,
    };
  },
  methods: {
    /**
     * Creates a new client.
     * @async
     */
    async createClient() {
      // Perform form validation
      const isFormValid = await this.$refs.formValidator.validate();
      if (isFormValid) {
        this.showLoader = true;

        // Map contact data to create an array of contact objects
        const contacts = this.contactData.map((item) => ({
          email: item.email,
          name: item.name,
          phoneNumber: item.phoneNumber,
          note: item.note,
        }));

        const bodyPayload = JSON.stringify({
          name: this.clientName,
          margin: {
            type: this.marginDollar ? "fixed" : "percentage",
            value: this.margin,
          },
          currencyType: this.selectedCurrencyType,
          contacts: contacts,
        });
        const response = await masterAPI(
          API.API_ENDPOINT.client,
          API.API_METHOD.post,
          undefined,
          bodyPayload
        );

        this.showLoader = false;
        // Handle the response
        // If the response is success then show a success message & navigate to client list
        if (response.status == 200) {
          this.$toast.success("Client create successful", {
            timeout: 1000,
          });
          this.$router.push({ path: "/client", name: "client" });
        }
      }
    },

    /**
     * Checks if there are any changes in client name, margin, or contact data.
     * If changes are detected, it prompts for confirmation.
     * If no changes or only contact data changes are detected, it proceeds with redirection.
     */
    checkSaveChangeData() {
      const isClientNameChanged = this.dbClientName !== this.clientName;
      const isMarginChanged = this.dbMargin !== this.margin;
      const isContactDataLengthChanged =
        this.dbContactData.length !== this.contactData.length;

      if (
        isClientNameChanged ||
        isMarginChanged ||
        isContactDataLengthChanged
      ) {
        // There are changes in client name, margin, or contact data
        this.confirmModal = true;
      } else {
        // No changes in client name, margin, or contact data
        const hasChangedItems = this.dbContactData.some((item, index) => {
          const contactItem = this.contactData[index];
          return (
            item.name !== contactItem.name ||
            item.email !== contactItem.email ||
            item.phoneNumber !== contactItem.phoneNumber ||
            item.note !== contactItem.note
          );
        });

        if (hasChangedItems) {
          // There are changes in contact data
          this.confirmModal = true;
        } else {
          // No changes in contact data
          this.continueRedirect();
        }
      }
    },
    continueRedirect() {
      // Proceeds with redirection to the client list page.
      this.$router.push({ path: "/client", name: "client" });
    },

    /**
     * Handles the updated props received from the child component.
     * Updates the corresponding data properties in the parent component.
     * @param {Object} updatedProps - The updated props object.
     */
    handleUpdatedProps(updatedProps) {
      this.clientName = updatedProps.clientName;
      this.margin = updatedProps.margin;
      this.selectedCurrencyType = updatedProps.selectedCurrencyType;
      this.marginDollar = updatedProps.marginDollar;
    },

    /**
     * Sets the confirmModal property to false.
     */
    handleCancel() {
      this.confirmModal = false;
    },
  },
};
</script>

<style>
.back-btn {
  color: #004225;
}

.edit-icon {
  cursor: pointer;
}

.edit-client-form {
  margin-left: 10%;
  margin-top: 5%;
  margin-right: 10%;
}

.edit-form-input .form-control {
  padding-left: 20px !important;
  box-shadow: 0px 1px 3px rgba(50, 50, 93, 0.5), 0px 1px 0px rgba(0, 0, 0, 0.1) !important;
}
</style>
