<template>
  <div>
    <CopyToClipboard :text="inviteLink" @copy="copyLink">
      <base-button
        type="primary"
        class="btn-theme-083C2F"
      >
        <img src="../../assets/img/home/file.png" />
      </base-button>
    </CopyToClipboard>
  </div>
</template>

<script>

import {mapGetters} from 'vuex';
import CopyToClipboard from 'vue-copy-to-clipboard';
import { Config } from "@/config/config";
import { userTypes } from '@/helpers/utility';

export default {
  components: {
    CopyToClipboard
  },
  data: function() {
    return {
      inviteLink: "",
    };
  },
  computed: {
    ...mapGetters([
      'allTeammatesUsers',
    ])
  },
  methods: {
    copyLink(result) {
      console.log(result);
    },
    /**
     * Get organization detail of admin user
     * @returns {string} Organization detail
     */
    getAdminOrganizationName () {
      let organizationDetail;
      
      const isNotEmpty = Object.keys(this.allTeammatesUsers).length !== 0;

      if (this.allTeammatesUsers && isNotEmpty) {
        let adminUser = this.allTeammatesUsers.map((item) => {
          if (item.type == userTypes.ADMIN) {
            return item;
          }
        }).filter(function (element) {
          return element !== undefined;
        });

        organizationDetail = adminUser[0];
      }

      return organizationDetail;
    },
  },
  created() {
    // Generate invite link
    let organizationDetail =  this.getAdminOrganizationName();

    if (organizationDetail) {
      let companyName = Buffer.from(organizationDetail.organizationName).toString('base64');
      let organizationType = Buffer.from(organizationDetail.userMode).toString('base64');
      let genratedLink = Config.appUrl + "/#/" + "companyName=" + companyName + '/organizationType=' + organizationType;
      this.inviteLink = genratedLink;
    }
    
  }
};
</script>
<style></style>
