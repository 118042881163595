<template>
  <div>
    {{ dateFormat(date) }}
  </div>
</template>
<script>
import { setDateFormat } from "@/helpers/utility";

export default {
  props: {
    date: {
      type: String,
    },
  },
  methods: {
    dateFormat(date) {
      return setDateFormat(date);
    }
  }
};
</script>
<style></style>
