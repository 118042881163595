<template>
  <div class="wrapper">
    <div class="body-container row">
      <left-panel :activeMenuName="activeMenuName"></left-panel>
      <div class="col-md-10 right-panel-scroll">
        <div class="carriers-main-page">
          <most-recent-carriers-table :viewCarriers="true">
          </most-recent-carriers-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LeftPanel from "../layout/LeftPanel";
import MostRecentCarriersTable from "./components/Tables/MostRecentCarriersTable.vue";

export default {
  bodyClass: "carriers",
  components: {
    LeftPanel,
    MostRecentCarriersTable,
  },
  data: function() {
    return {
      activeMenuName: "Carriers",
    };
  },
  methods: {},
};
</script>

<style></style>
